import React from 'react'
import { FormattedMessage, useIntl } from "react-intl"
import { Dropdown, ButtonGroup, Button } from 'react-bootstrap'
import { BsBoxSeam, BsCircle, BsStar, BsDashLg, BsSquare, BsTextareaT, BsTriangle, BsArrowRight, BsArrowUpRight, BsTruck, BsArrowRepeat, BsGraphUpArrow, BsArrowsExpand, BsArrowBarUp, BsBrightnessLow, BsStars, BsWindows, BsCarFront, BsFlag, BsTriangleFill, BsArrowRightCircle, BsViewList, BsSlashLg, BsDistributeVertical, BsSignDoNotEnter, BsSignTurnRight, BsSignStopLights, BsSignpost, BsSignMergeRight, BsAirplane, BsWater, BsTransparency, BsCompass, BsWind, BsThermometerHalf, BsArrowDownLeft, BsArrowsVertical, BsAlignBottom, BsDropletHalf, BsSuitDiamond } from 'react-icons/bs'

import DEFAULT_CHART from '../../config/elements/default'
import GOM_CHART from '../../config/elements/gom'

const GomElementsMenu = ({ canvasRef }) => {
    const intl = useIntl()

    const handleAddElement = (elementType, chartElement) => {
        canvasRef.addElement(elementType, chartElement)
    }

    const handleAddTextbox = (text = "Tex box", type) => {
        canvasRef.addTextbox(text, type)
    }

    return (
        <Dropdown
            variant='secondary'
            as={ButtonGroup}
            drop='end'
            className='p-0'
        >
            <Dropdown.Toggle variant="light" size='md' title={intl.formatMessage({ id: "menuGomChartGraphicCard", defaultMessage: "Graphic charter" })}>
                <BsBoxSeam size={20} />
            </Dropdown.Toggle>

            <Dropdown.Menu className="rounded-3">
                <Dropdown.Header style={{
                    display: 'flex',
                    alignItems: 'center', // Alinea verticalmente
                    gap: '10px' // Espacio entre la imagen y el texto
                }}
                >
                    <img src="/aptb.ico" title='APTB GOM' width={20} /> <FormattedMessage id="menuGomChartGraphicCard" defaultMessage="Graphic Charter" />
                </Dropdown.Header>

                <Dropdown.ItemText>
                    <Dropdown drop="end" className="p-0">
                        <Dropdown.Toggle
                            as={Button}
                            variant="link"
                            className="text-decoration-none dropdown-item p-0 cursor-pointer"
                        >
                            <FormattedMessage id="menuGomChartActions" defaultMessage="Actions" />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Header>
                                <FormattedMessage id="menuGomChartActions" defaultMessage="Actions" />
                            </Dropdown.Header>
                            <Dropdown.Item onClick={() => handleAddElement('actionPlanning', GOM_CHART.action.planning)}>
                                <BsSlashLg size={20} className="me-2" /> <FormattedMessage id="menuGomChartActionPlanning" defaultMessage="Planning action" />
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleAddElement('actionLocated', GOM_CHART.action.located)}>
                                <BsArrowUpRight size={20} className="me-2" /> <FormattedMessage id="menuGomChartActionLocated" defaultMessage="Located action" />
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleAddElement('actionAnalysis', GOM_CHART.action.analysis)}>
                                <BsGraphUpArrow size={20} className="me-2" /> <FormattedMessage id="menuGomChartActionAnalysis" defaultMessage="Analysis action" />
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleAddElement('ActionSimultaneous', GOM_CHART.action.simultaneous)}>
                                <BsArrowsExpand size={20} className="me-2" /> <FormattedMessage id="menuGomChartActionSimultaneous" defaultMessage="Simultaneous action" />
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleAddElement('ActionDefensiveLine', GOM_CHART.action.defensiveLine)}>
                                <BsArrowBarUp size={20} className="me-2" /> <FormattedMessage id="menuGomChartActionDefensiveLine" defaultMessage="Defensive line action" />
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleAddElement('ActionDefensivePerimeter', GOM_CHART.action.defensivePerimeter)}>
                                <BsBrightnessLow size={20} className="me-2" /> <FormattedMessage id="menuGomChartActionDefensivePerimeter" defaultMessage="Defensive perimeter action" />
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleAddElement('ActionWheel', GOM_CHART.action.wheel)}>
                                <BsArrowRepeat size={20} className="me-2" /> <FormattedMessage id="menuGomChartActionWheel" defaultMessage="Wheel action" />
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleAddElement('ActionAerialOffensive', GOM_CHART.action.aerialOffensive)}>
                                <BsTransparency size={20} className="me-2" /> <FormattedMessage id="menuGomChartActionAerialOffensive" defaultMessage="Aerial offensive action" />
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Dropdown.ItemText>

                <Dropdown.ItemText>
                    <Dropdown drop="end" className="p-0">
                        <Dropdown.Toggle
                            as={Button}
                            variant="link"
                            className="text-decoration-none dropdown-item p-0 cursor-pointer"
                        >
                            <FormattedMessage id="menuGomChartGeospatial" defaultMessage="Geospatial and Meteorological" />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Header>
                                <FormattedMessage id="menuGomChartGeospatial" defaultMessage="Geospatial and Meteorological" />
                            </Dropdown.Header>
                            <Dropdown.Item onClick={() => handleAddElement('geoCompass', GOM_CHART.data.geo.compass)}>
                                <BsCompass size={20} className="me-2" /> <FormattedMessage id="menuGomChartGeoCompass" defaultMessage="Compass orientation" />
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleAddElement('meteoTemperature', GOM_CHART.data.meteo.temperature)}>
                                <BsThermometerHalf size={20} className="me-2" /> <FormattedMessage id="menuGomChartMeteoTemperature" defaultMessage="Temperature" />
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleAddElement('meteoWind', GOM_CHART.data.meteo.wind)}>
                                <BsWind size={20} className="me-2" /> <FormattedMessage id="menuGomChartMeteoWindSpeed" defaultMessage="Wind speed" />
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleAddElement('geoSlopeGradient', GOM_CHART.data.geo.slopeGradient)}>
                                <BsArrowDownLeft size={20} className="me-2" /> <FormattedMessage id="menuGomChartGeoSlopeGradient" defaultMessage="Slope gradient" />
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleAddElement('geoElevation', GOM_CHART.data.geo.elevation)}>
                                <BsArrowsVertical size={20} className="me-2" /> <FormattedMessage id="menuGomChartGeoElevation" defaultMessage="Elevation" />
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleAddTextbox('Level', 'geoLevel')}>
                                <BsAlignBottom size={20} className="me-2" /> <FormattedMessage id="menuGomChartGeoLevel" defaultMessage="Level" />
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Dropdown.ItemText>

                <Dropdown.ItemText>
                    <Dropdown drop="end" className="p-0">
                        <Dropdown.Toggle
                            as={Button}
                            variant="link"
                            className="text-decoration-none dropdown-item p-0 cursor-pointer"
                        >
                            <FormattedMessage id="menuGomChartResources" defaultMessage="Resources" />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Header>
                                <FormattedMessage id="menuGomChartResources" defaultMessage="Resources" />
                            </Dropdown.Header>
                            <Dropdown.Item onClick={() => handleAddElement('resourceCommandPost', GOM_CHART.resource.commandPost)}>
                                <BsFlag size={20} className="me-2" /> <FormattedMessage id="menuGomChartResourceCommandPost" defaultMessage="Command post" />
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleAddElement('resourceBrigade', GOM_CHART.resource.brigade)}>
                                <BsTruck size={20} className="me-2" /> | <FormattedMessage id="menuGomChartResourceBrigade" defaultMessage="Brigade" />
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleAddElement('resourceGroup', GOM_CHART.resource.group)}>
                                <BsTruck size={20} className="me-2" /> || <FormattedMessage id="menuGomChartResourceGroup" defaultMessage="Group" />
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleAddElement('resourceColumn', GOM_CHART.resource.column)}>
                                <BsTruck size={20} className="me-2" /> ||| <FormattedMessage id="menuGomChartResourceColumn" defaultMessage="Column" />
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleAddElement('resourceAircraft', GOM_CHART.resource.air)}>
                                <BsAirplane size={20} className="me-2" /> <FormattedMessage id="menuGomChartResourceAirAsset" defaultMessage="Air asset" />
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleAddElement('resourceOther', GOM_CHART.resource.other)}>
                                <BsCarFront size={20} className="me-2" /> <FormattedMessage id="menuGomChartResourceOther" defaultMessage="Other" />
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleAddElement('resourceTransitPoint', GOM_CHART.resource.transitPoint)}>
                                <BsArrowRightCircle size={20} className="me-2" /> <FormattedMessage id="menuGomChartResourceTransitPoint" defaultMessage="Transit point" />
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleAddElement('resourceLogistic', GOM_CHART.resource.logistic)}>
                                <BsViewList size={20} className="me-2" /> <FormattedMessage id="menuGomChartResourceLogistic" defaultMessage="Infrastructure & Logistic" />
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Dropdown.ItemText>

                <Dropdown.ItemText>
                    <Dropdown drop="end" className="p-0">
                        <Dropdown.Toggle
                            as={Button}
                            variant="link"
                            className="text-decoration-none dropdown-item p-0 cursor-pointer"
                        >
                            <FormattedMessage id="menuGomChartDefaultObjects" defaultMessage="Default Objects" />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Header>
                                <FormattedMessage id="menuGomChartDefaultObjects" defaultMessage="Default Objects" />
                            </Dropdown.Header>
                            <Dropdown.Item onClick={() => handleAddTextbox('Textbox', 'textbox')}>
                                <BsTextareaT size={20} className="me-2" /> <FormattedMessage id="menuGomChartDefaultTextbox" defaultMessage="Text box" />
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleAddElement('line', DEFAULT_CHART.DEFAULT_LINE)}>
                                <BsDashLg size={20} className="me-2" /> <FormattedMessage id="menuGomChartDefaultLine" defaultMessage="Line" />
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleAddElement('arrow', DEFAULT_CHART.DEFAULT_ARROW)}>
                                <BsArrowRight size={20} className="me-2" /> <FormattedMessage id="menuGomChartDefaultArrow" defaultMessage="Arrow" />
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleAddElement('triangle', DEFAULT_CHART.DEFAULT_TRIANGLE)}>
                                <BsTriangle size={20} className="me-2" /> <FormattedMessage id="menuGomChartDefaultTriangle" defaultMessage="Triangle" />
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleAddElement('rectangle', DEFAULT_CHART.DEFAULT_RECTANGLE)}>
                                <BsSquare size={20} className="me-2" /> <FormattedMessage id="menuGomChartDefaultRectangle" defaultMessage="Rectangle" />
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleAddElement('circle', DEFAULT_CHART.DEFAULT_CIRCLE)}>
                                <BsCircle size={20} className="me-2" /> <FormattedMessage id="menuGomChartDefaultCircle" defaultMessage="Circle" />
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleAddElement('ellipse', DEFAULT_CHART.DEFAULT_ELLIPSE)}>
                                <BsCircle size={20} className="me-2" /> <FormattedMessage id="menuGomChartDefaultEllipse" defaultMessage="Ellipse" />
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleAddElement('star', DEFAULT_CHART.DEFAULT_STAR)}>
                                <BsStar size={20} className="me-2" /> <FormattedMessage id="menuGomChartDefaultStar" defaultMessage="Star" />
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Dropdown.ItemText>

                <Dropdown.ItemText>
                    <Dropdown drop="end" className="p-0">
                        <Dropdown.Toggle
                            as={Button}
                            variant="link"
                            className="text-decoration-none dropdown-item p-0 cursor-pointer"
                        >
                            <FormattedMessage id="menuGomChartPoints" defaultMessage="Points" />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Header>
                                <FormattedMessage id="menuGomChartPoints" defaultMessage="Points" />
                            </Dropdown.Header>
                            <Dropdown.Item onClick={() => handleAddElement('pointSensitiveTarget', GOM_CHART.point.sensitiveTarget)}>
                                <BsTriangleFill size={20} className="me-2" style={{ transform: "rotate(180deg)" }} /> <FormattedMessage id="menuGomChartPointSensitiveTarget" defaultMessage="Sensitive target" />
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleAddElement('pointDangerSource', GOM_CHART.point.dangerSource)}>
                                <BsTriangleFill size={20} className="me-2" /> <FormattedMessage id="menuGomChartPointDangerSource" defaultMessage="Danger source" />
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleAddElement('pointWaterLimited', GOM_CHART.point.waterLimited)}>
                                <BsDropletHalf size={20} className="me-2" /> <FormattedMessage id="menuGomChartPointWaterLimited" defaultMessage="Water limited loading" />
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleAddElement('pointWaterUnlimited', GOM_CHART.point.waterUnlimited)}>
                                <BsWater size={20} className="me-2" /> <FormattedMessage id="menuGomChartPointWaterUnlimited" defaultMessage="Water unlimited loading" />
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleAddElement('pointWaterAerial', GOM_CHART.point.waterAerial)}>
                                <BsWater size={20} className="me-2" /> <FormattedMessage id="menuGomChartPointWaterAerial" defaultMessage="Water aerial loading" />
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Dropdown.ItemText>

                <Dropdown.ItemText>
                    <Dropdown drop="end" className="p-0">
                        <Dropdown.Toggle
                            as={Button}
                            variant="link"
                            className="text-decoration-none dropdown-item p-0 cursor-pointer"
                        >
                            <FormattedMessage id="menuGomChartSector" defaultMessage="Sector" />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Header>
                                <FormattedMessage id="menuGomChartSector" defaultMessage="Sector" />
                            </Dropdown.Header>
                            <Dropdown.Item onClick={() => handleAddElement('sectorFunctional', GOM_CHART.sector.functional)}>
                                <BsSuitDiamond size={20} className="me-2" /> <FormattedMessage id="menuGomChartSectorFunctional" defaultMessage="Functional sector" />
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Dropdown.ItemText>

                <Dropdown.ItemText>
                    <Dropdown drop="end" className="p-0">
                        <Dropdown.Toggle
                            as={Button}
                            variant="link"
                            className="text-decoration-none dropdown-item p-0 cursor-pointer"
                        >
                            <FormattedMessage id="menuGomChartIncidents" defaultMessage="Incidents" />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Header>
                                <FormattedMessage id="menuGomChartIncidents" defaultMessage="Incidents" />
                            </Dropdown.Header>
                            <Dropdown.Item onClick={() => handleAddElement('incidentFocus', GOM_CHART.incident.focus)}>
                                <BsStar size={20} className="me-2" /> <FormattedMessage id="menuGomChartIncidentFocus" defaultMessage="Focus" />
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleAddElement('incidentZone', GOM_CHART.incident.zone)}>
                                <BsStars size={20} className="me-2" /> <FormattedMessage id="menuGomChartIncidentZone" defaultMessage="Zone" />
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleAddElement('incidentCenter', GOM_CHART.incident.center)}>
                                <BsWindows size={20} className="me-2" /> <FormattedMessage id="menuGomChartIncidentCentro" defaultMessage="Center" />
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Dropdown.ItemText>

                <Dropdown.ItemText>
                    <Dropdown drop="end" className="p-0">
                        <Dropdown.Toggle
                            as={Button}
                            variant="link"
                            className="text-decoration-none dropdown-item p-0 cursor-pointer"
                        >
                            <FormattedMessage id="menuGomChartTraffic" defaultMessage="Traffic" />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Header>
                                <FormattedMessage id="menuGomChartTraffic" defaultMessage="Traffic" />
                            </Dropdown.Header>
                            <Dropdown.Item onClick={() => handleAddElement('trafficAccess', GOM_CHART.traffic.access)}>
                                <BsSignpost size={20} className="me-2" /> <FormattedMessage id="menuGomChartTrafficAccess" defaultMessage="Traffic access" />
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleAddElement('trafficBypass', GOM_CHART.traffic.bypass)}>
                                <BsDistributeVertical size={20} className="me-2" /> <FormattedMessage id="menuGomChartTrafficBypass" defaultMessage="Traffic bypass" />
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleAddElement('trafficDirection', GOM_CHART.traffic.direction)}>
                                <BsSignTurnRight size={20} className="me-2" /> <FormattedMessage id="menuGomChartTrafficDirection" defaultMessage="Traffic direction" />
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleAddElement('trafficEvolution', GOM_CHART.traffic.evolution)}>
                                <BsSignStopLights size={20} className="me-2" /> <FormattedMessage id="menuGomChartTrafficEvolution" defaultMessage="Traffic evolution" />
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleAddElement('trafficIndication', GOM_CHART.traffic.indication)}>
                                <BsSignMergeRight size={20} className="me-2" /> <FormattedMessage id="menuGomChartTrafficIndication" defaultMessage="Traffic indication" />
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleAddElement('trafficNoEntry', GOM_CHART.traffic.noEntry)}>
                                <BsSignDoNotEnter size={20} className="me-2" /> <FormattedMessage id="menuGomChartTrafficNoEntry" defaultMessage="Traffic no entry" />
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Dropdown.ItemText>

            </Dropdown.Menu>
        </Dropdown>
    )
}

export default GomElementsMenu
