import { TAG_CODES } from "../../tag.config"

const GOM_INCIDENT_FOCUS = {
    type: 'path',
    tagCode: TAG_CODES.INCIDENT,
    withText: true,
    defaultText: 'Focus',
    positionText: 'top', // top - right - center - bottom - left
    fieldsText: ["title"],
    endTextWidthStr: "",
    angle: 0,
    colorCodeId: 'fire',
    withFillColor: true,
    schema: {
        "type": 'object',
        "properties": {
            "title": {
                "title": "Text",
                "description": "Write a text",
                "type": "string",
            },
        },
        "required": []
    },
    pathData: `M 56 84 L 23.0832 101.304 l 6.2888 -36.652 L 2.7384 38.696 l 36.8032 -5.348 L 56 0 l 16.4584 33.348 l 36.8032 5.348 l -26.628 25.956 l 6.2888 36.652 Z`,
}

export default GOM_INCIDENT_FOCUS