import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { IntlProvider } from 'react-intl'
import translations_en from './gomcanvas/lang/en.json'
import translations_es from './gomcanvas/lang/es.json'
import translations_fr from './gomcanvas/lang/fr.json'

// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css'
import "bootstrap-icons/font/bootstrap-icons.css"

const translations = {
  en: translations_en,
  es: translations_es,
  fr: translations_fr,
}

const locale = navigator.language.split('-')[0]
const defaultLocale = 'en'
const selectedTranslations = translations[locale] || translations[defaultLocale]

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <IntlProvider locale={locale} messages={selectedTranslations} defaultLocale="en">
    <App />
  </IntlProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
