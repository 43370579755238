import { fabric } from 'fabric'

// zoomControls.js
class ZoomControls {
    constructor(canvas, gridManager) {
        if (!(canvas instanceof fabric.Canvas)) {
            throw new Error('Canvas debe ser una instancia de fabric.Canvas')
        }
        this.canvas = canvas        
        this.gridManager = gridManager
        this.zoom = 1
    }

    getZoom = () => this.canvas.getZoom()
    setZoom = (zoom) => {
        this.zoom = zoom
        this.canvas.setZoom(zoom)
        this.canvas.discardActiveObject()
        this.gridManager.setGrid(this.gridManager.grid)
    }
    zoomToPoint = (point, zoom) => {
        this.zoom = zoom
        this.canvas.zoomToPoint(point, zoom)
        this.canvas.discardActiveObject()
        this.gridManager.setGrid(this.gridManager.grid)
    }

    setZoomWheelActive = (callback = null) => {
        this.canvas.on('mouse:wheel', (event) => {
            event.e.preventDefault()
            event.e.stopPropagation()

            // Obtener el factor de zoom
            const delta = event.e.deltaY
            let zoom = this.getZoom()
            zoom *= 0.999 ** delta

            // Limitar el zoom
            zoom = Math.max(0.1, Math.min(20, zoom))
            if (event.e.altKey) { // Solo permitir zoom y panning si se mantiene presionada una tecla (por ejemplo, Alt)
                this.zoomToPoint(new fabric.Point(event.e.offsetX, event.e.offsetY), zoom)
            } else {
                this.setZoom(zoom)
            }
            if (typeof callback === 'function') {
                callback(zoom)
            }
        })
    }
}

export default ZoomControls