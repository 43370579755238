import { fabric } from 'fabric'
import { COLOR_CODES } from '../../config/color.config'

/**
 * Class for managing multi-segment line drawing on a fabric.js canvas.
 */
class DrawLineFabric {
    constructor(canvas) {
        if (!(canvas instanceof fabric.Canvas)) {
            throw new Error('Canvas debe ser una instancia de fabric.Canvas')
        }

        this.canvas = canvas
        this.points = []
        this.lines = []
        this.tempLine = null
    }

    /**
     * Handles the start of line drawing, triggered on mouse:down.
     * @param {Event} e - The mouse event from the canvas.
     */
    drawLineStart = (e) => {
        e.e.preventDefault()
        e.e.stopPropagation()

        this.canvas.on('mouse:move', this._updateTempLine)
        const pointer = this.canvas.getPointer(e)
        const { x, y } = pointer

        this.points.push({ x, y })

        if (this.points.length > 1) {
            this._addLineSegment()
        }
    }

    /**
     * Handles the end of line drawing, triggered on mouse:dblclick.
     */
    drawLineEnd = () => {
        this.canvas.off('mouse:move')
        if (this.tempLine) this.canvas.remove(this.tempLine)
        this.tempLine = null
        this._createGroup()
    }

    /**
     * Updates the temporary line following the pointer.
     * @param {fabric.Event} e - The mouse move event.
     */
    _updateTempLine = (e) => {
        if (this.points.length > 0) {
            const pointer = this.canvas.getPointer(e)
            const lastPoint = this.points[this.points.length - 1]

            if (this.tempLine) {
                this.canvas.remove(this.tempLine)
            }

            this.tempLine = new fabric.Line([lastPoint.x, lastPoint.y, pointer.x, pointer.y], {
                stroke: COLOR_CODES.COMMAND.color,
                strokeWidth: 2,
                strokeDashArray: [5, 5],
                selectable: false,
                evented: false,
                objectCaching: false,
            })

            this.canvas.add(this.tempLine)
        }
    }

    /**
     * Adds a permanent line segment to the canvas.
     */
    _addLineSegment = () => {
        if (this.points.length > 1) {
            const lastIndex = this.points.length - 1
            const line = new fabric.Line([
                this.points[lastIndex - 1].x, this.points[lastIndex - 1].y,
                this.points[lastIndex].x, this.points[lastIndex].y
            ], {
                stroke: COLOR_CODES.COMMAND.color,
                strokeWidth: 2,
                //selectable: false,
                //evented: false,
                objectCaching: false,
            })
            this.canvas.add(line)
            this.lines.push(line)
        }
    }

    _createGroup() {
        if (!this.lines || this.lines.length === 0) return

        const midX = (this.lines[0].x1 + this.lines[0].x2) / 2
        const midY = (this.lines[0].y1 + this.lines[0].y2) / 2

        const text = new fabric.Text('Action', {
            left: midX + 10, // Desplazado un poco para que no se superponga a la línea
            top: midY - 10,
            fontSize: 16,
            fill: 'black'
        })
        this.lines.push(text)
        this.group = new fabric.Group(this.lines, {
            selectable: true,
            evented: true
        })

        this.canvas.remove(this.lines)
        this.canvas.add(this.group)
        this.canvas.renderAll()
    }
}

export default DrawLineFabric