import { TAG_CODES } from "../../tag.config"

const GOM_DATA_METEO_TEMPERATURE = {
    type: 'path',
    tagCode: TAG_CODES.DATA,
    withText: true,
    defaultText: 'Temperature',
    positionText: 'top', // top - right - center - bottom - left
    fieldsText: ["title"],
    endTextWidthStr: "",
    angle: 0,
    colorCodeId: 'default',
    withFillColor: true,
    schema: {
        "type": 'object',
        "properties": {
            "title": {
                "title": "Text",
                "description": "Write a text",
                "type": "string",
            },
        },
        "required": []
    },
    pathData: `M33.25 43.75a5.25 5.25 90 11-7-4.9525V22.75a1.75 1.75 90 013.5 0v16.0475a5.25 5.25 90 013.5 4.9525M19.25 8.75a8.75 8.75 90 0117.5 0v26.425a12.25 12.25 90 11-17.5 0zM28 3.5a5.25 5.25 90 00-5.25 5.25v27.9545l-.5845.525a8.75 8.75 90 1011.6655 0l-.581-.525V8.75A5.25 5.25 90 0028 3.5`,
}

export default GOM_DATA_METEO_TEMPERATURE