import { TAG_CODES } from "../../tag.config"

const GOM_RESOURCE_LOGISTIC = {
    type: 'path',
    tagCode: TAG_CODES.RESOURCE,
    withText: true,
    defaultText: 'Logistic',
    positionText: 'top', // top - right - center - bottom - left
    fieldsText: ["title"],
    endTextWidthStr: "",
    angle: 0,
    colorCodeId: 'default',
    withFillColor: false,
    schema: {
        "type": 'object',
        "properties": {
            "title": {
                "title": "Text",
                "description": "Write a text",
                "type": "string",
            },
        },
        "required": []
    },
    pathData: `M5.6 8.4 106.4 8.4M13.6 16.8Q5.6 16.8 5.6 24.8L5.6 53.6Q5.6 61.6 13.6 61.6L98.4 61.6Q106.4 61.6 106.4 53.6L106.4 24.8Q106.4 16.8 98.4 16.8Z`,
}

export default GOM_RESOURCE_LOGISTIC