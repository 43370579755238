import { TAG_CODES } from "../../tag.config"

const GOM_ACTION_DEFENSIVE_LINE = {
    type: 'path',
    tagCode: TAG_CODES.ACTION,
    withText: true,
    defaultText: 'Defensive line action',
    positionText: 'top', // top - right - center - bottom - left
    fieldsText: ["title"],
    endTextWidthStr: "",
    angle: 0,
    colorCodeId: 'fire',
    withFillColor: true,
    schema: {
        "type": 'object',
        "properties": {
            "title": {
                "title": "Text",
                "description": "Write a text",
                "type": "string",
            },
        },
        "required": []
    },
    pathData: `M 20 70 H 150 
    M 50 69 L 60 69 L 50 46 L 40 69 Z
    M 70 69 L 80 69 L 70 46 L 60 69 Z
    M 90 69 L 100 69 L 90 46 L 80 69 Z
    M 110 69 L 120 69 L 110 46 L 100 69 Z`,
}

export default GOM_ACTION_DEFENSIVE_LINE