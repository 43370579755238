import React, { useEffect, useState } from 'react'
import { useIntl } from "react-intl"
import { Form, InputGroup, Button, ButtonGroup } from 'react-bootstrap'
import { BsTrashFill, BsUnlock, BsLockFill, BsInfoCircle, BsBookmarkFill, BsBookmark, BsDashSquare } from 'react-icons/bs'

import SectorOffcanvas from '../offcanvas/SectorOffcanvas'
import ConfirmDeleteModal from '../modal/ConfirmDeleteModal'

const SectorControl = ({ element, canvasRef, parentNode }) => {
    const intl = useIntl()
    const [isLocked, setIsLocked] = useState(false)
    const [showOffCanvas, setShowOffCanvas] = useState(false)
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [inputValue, setInputValue] = useState('Cargando...')
    const [sectorObjects, setSectorObjects] = useState(null)

    useEffect(() => {
        if (!element) return
        setIsLocked(element.lockMovementX && element.lockMovementY)
        setInputValue(element.data.title ? element.data.title : '')
        setSectorObjects(canvasRef.getSectorObjects(element.data.id))
    }, [element])

    const handleDelete = () => setShowConfirmModal(true)
    const confirmDelete = () => {
        canvasRef.deleteSelected()
        setShowConfirmModal(false)
    }
    const confirmClose = () => setShowConfirmModal(false)

    const handleLockToggle = () => {
        const locked = !isLocked
        setIsLocked(locked)

        element.set({
            lockMovementX: locked,
            lockMovementY: locked,
            lockScalingX: locked,
            lockScalingY: locked,
            lockRotation: locked,
        })

        const dateTime = Date.now()
        canvasRef.setElementData(element, { lockedUpdatedAt: dateTime, locked }, dateTime)

        canvasRef.renderAll()
    }

    const handleUnselect = () => {
        canvasRef.discardActiveObject().renderAll()
    }

    const handleCloseOffcanvas = () => setShowOffCanvas(false)
    const handleShowOffcanvas = () => setShowOffCanvas(true)

    const handleEditTitleClick = () => {
        setIsEditing(true) // Permitir edición al hacer doble clic
    }

    const handleEditTitleChange = (e) => {
        setInputValue(e.target.value) // Actualizar el valor del input        
        canvasRef.setGroupTitleValue({
            group: element,
            type: 'sectorTitle',
            textValue: e.target.value,
        })
    }

    const handleEditTitleBlur = () => {
        setIsEditing(false) // Salir de la edición al perder el foco
        handleOnTitleChange()
    }

    const handleEditTitleKeyDown = (e) => {
        if (e.key === 'Enter') {
            setIsEditing(false) // Salir de la edición al presionar Enter
            handleOnTitleChange()
        }
    }

    const handleOnTitleChange = () => {
        const dateTime = Date.now()
        canvasRef.setElementData(element, {
            titleUpdatedAt: dateTime,
            title: inputValue,
        }, dateTime)
    }

    return (
        <>
            <ButtonGroup>
                <Button
                    variant={"info"}
                    onClick={handleUnselect}
                    title={intl.formatMessage({ id: "controlSectorUnselect", defaultMessage: "Unselect sector" })}
                >
                    <BsDashSquare />
                </Button>

                {isEditing ? (
                    <InputGroup>
                        <InputGroup.Text><BsBookmarkFill /></InputGroup.Text>
                        <Form.Control
                            maxLength={100}
                            type="text"
                            value={inputValue}
                            autoFocus
                            onChange={handleEditTitleChange}
                            onBlur={handleEditTitleBlur}
                            onKeyDown={handleEditTitleKeyDown}
                        />
                    </InputGroup>
                ) : (
                    <Button
                        title={intl.formatMessage({ id: "controlSectorEditTitle", defaultMessage: "Edit title" })}
                        variant={'light'}
                        onClick={handleEditTitleClick} // Iniciar la edición
                    >
                        <BsBookmark />
                    </Button>
                )}

                <Button
                    variant={showOffCanvas ? "secondary" : "light"}
                    onClick={handleShowOffcanvas}
                    title={intl.formatMessage({ id: "controlSectorInfo", defaultMessage: "Sector info" })}
                >
                    <BsInfoCircle />
                </Button>

                <Button
                    variant={isLocked ? "secondary" : "light"}
                    onClick={handleLockToggle}
                    title={isLocked ? intl.formatMessage({ id: "controlSectorUnlock", defaultMessage: "Unlock sector" }) : intl.formatMessage({ id: "controlSectorLock", defaultMessage: "Lock sector" })}
                >
                    {isLocked ? <BsLockFill /> : <BsUnlock />}
                </Button>

                <Button
                    variant="light"
                    onClick={handleDelete}
                    title={intl.formatMessage({ id: "controlSectorDelete", defaultMessage: "Delete sector" })}
                >
                    <BsTrashFill />
                </Button>

            </ButtonGroup>

            {showOffCanvas && (
                <SectorOffcanvas show={showOffCanvas} element={element} onHide={handleCloseOffcanvas} sectorObjects={sectorObjects} parentNode={parentNode} canvasRef={canvasRef} />
            )}

            {showConfirmModal && (
                <ConfirmDeleteModal
                    parentNode={parentNode}
                    show={showConfirmModal}
                    handleClose={confirmClose}
                    handleConfirm={confirmDelete}
                    title={intl.formatMessage({ id: "controlSectorDeleteConfirmTitle", defaultMessage: "Delete sector?" })}
                    text={intl.formatMessage({ id: "controlSectorDeleteConfirmText" }, { sectorName: inputValue })}
                />
            )}
        </>
    )
}

export default SectorControl
