import { TAG_CODES } from "../../tag.config"

const GOM_ACTION_WHEEL = {
    type: 'path',
    tagCode: TAG_CODES.ACTION,
    withText: true,
    defaultText: 'Wheel action',
    positionText: 'top', // top - right - center - bottom - left
    fieldsText: ["title"],
    endTextWidthStr: "",
    angle: 0,
    colorCodeId: 'water',
    withFillColor: false,
    schema: {
        "type": 'object',
        "properties": {
            "title": {
                "title": "Text",
                "description": "Write a text",
                "type": "string",
            },
        },
        "required": []
    },
    pathData: `M 50 60 Q 50 35 75 35 H 170 Q 195 35 195 60 M 105 85 L 119 72 L 119 96 L 105 85 L 105 85 M 195 60 Q 195 85 170 85 H 75 Q 50 85 50 60 M 120 47 L 120 47 L 135 35 L 120 24 L 120 47`,
}

export default GOM_ACTION_WHEEL