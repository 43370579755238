import DEFAULT_CIRCLE from './defaultCircle'
import DEFAULT_RECTANGLE from './defaultRectangle'
import DEFAULT_TRIANGLE from './defaultTriangle'
import DEFAULT_LINE from './defaultLine'
import DEFAULT_ELLIPSE from './defaultEllipse'
import DEFAULT_STAR from './defaultStar'
import DEFAULT_ARROW from './defaultArrow'

const DEFAULT_CHART = {
    DEFAULT_ARROW,
    DEFAULT_CIRCLE,
    DEFAULT_ELLIPSE,
    DEFAULT_LINE,
    DEFAULT_RECTANGLE,
    DEFAULT_STAR,
    DEFAULT_TRIANGLE,
}

export default DEFAULT_CHART

