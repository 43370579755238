import { fabric } from 'fabric'
import { COLOR_CODES } from '../../config/color.config'
import { BORDER_CODES } from '../../config/border.config'
import { TAG_CODES } from '../../config/tag.config'

/**
 * Extiende la clase PencilBrush con propiedades personalizadas.
 */
class CustomPencilBrush extends fabric.PencilBrush {
    constructor(canvas) {
        super(canvas)
        this.drawType = 'freehand' // Tipo de dibujo personalizado
    }
}

/**
 * Clase para gestionar el dibujo a mano alzada usando PencilBrush en un canvas de fabric.js.
 */
class DrawFreehandFabric {
    constructor(canvas) {
        this.canvas = canvas
        this.tagCode = TAG_CODES.DEFAULT

        this.pencilBrush = new CustomPencilBrush(this.canvas)
        this.pencilBrush.color = this.tagCode.defaultColorCode.color // Color del lápiz
        this.pencilBrush.width = 2 // Grosor del trazo
        this.pencilBrush.selectionColor = 'transparent' // Color de la selección
        this.pencilBrush.strokeLineCap = 'round' // Estilo de la línea
        this.pencilBrush.strokeLineJoin = 'round' // Unir las líneas de manera suave
        this.pencilBrush.smoothness = 0.3 // Suaviza el trazo
        this.drawnPaths = []

    }

    /**
     * Configura el lápiz con opciones personalizadas.
     * @param {Object} options - Opciones personalizadas para el lápiz.
     */
    setPencilBrush = (options = {}) => {
        Object.assign(this.pencilBrush, options)
    }

    /**
     * Activa o desactiva el modo de dibujo.
     * @param {boolean} active - Si es verdadero, activa el modo de dibujo.
     */
    setDrawMode = (active = false, tagCode = null) => {
        if (active && tagCode) {
            this.tagCode = tagCode
            this._startDrawing()
        } else {
            this._stopDrawing()
        }
    }

    /**
    * Elimina los dos últimos trazos dibujados. Para finalizar por doble click de ratón
    */
    stopByDoubleClick = () => {
        if (!this.drawnPaths || this.drawnPaths.length === 0) {
            console.warn("No hay trazos para eliminar.")
            return
        }

        // Eliminar los dos últimos elementos del array y del canvas al hacer doble click
        for (let i = 0; i < 2; i++) {
            const lastPath = this.drawnPaths.pop()
            if (lastPath) {
                this.canvas.remove(lastPath)
            }
        }
    }

    /**
     * Inicia el modo de dibujo.
     */
    _startDrawing = () => {
        this.canvas.isDrawingMode = true
        this.canvas.freeDrawingBrush = this.pencilBrush
        this.drawnPaths = []
        this.canvas.on('path:created', this._onPathCreated)
        //this.canvas.on('mouse:dblclick', this._deleteLastTwoPaths)
    }

    /**
     * Detiene el modo de dibujo y agrupa las líneas creadas.
     */
    _stopDrawing = () => {
        this.canvas.isDrawingMode = false
        this.canvas.freeDrawingBrush = null
        this.canvas.off('path:created')
        //this.canvas.off('mouse:dblclick')

        if (this.drawnPaths.length > 0) {
            const id = crypto.randomUUID()
            const dateTime = Date.now()
            const text = this._createText(id, dateTime)
            const group = new fabric.Group([...this.drawnPaths, text], {
                lockScalingX: true,
                lockScalingY: true,
                lockScalingFlip: true,
                lockSkewingX: true,
                lockSkewingY: true,
                lockUniScaling: true,
                selectable: true,
                cornerColor: this.pencilBrush.color,
                data: {
                    id: id,
                    type: 'drawFreeHand',
                    as: this.tagCode.id,
                    title: '',
                    resourceChannel: null,
                    createdAt: dateTime,
                    colorCodeId: this.tagCode.defaultColorCode.id,
                    borderCodeId: this.tagCode.defaultBorderCode.id,
                    fillActive: false,
                    isActive: true,
                }
            })

            this.canvas.add(group)
            this._clearDrawnPaths()
        }
    }

    /**
     * Callback cuando se crea un nuevo trazo.
     * @param {fabric.Event} e - Evento de creación de trazo.
     */
    _onPathCreated = (e) => {
        e.path.set(
            {
                data: {
                    type: 'drawFreeHandPath',
                }
            }
        )
        this.drawnPaths.push(e.path)
    }

    /**
     * Crea un objeto de texto para agregar al grupo.
     * @returns {fabric.Textbox} - El objeto de texto.
     */
    _createText = (sectorId, dateTime) => {
        // Encuentra los valores mínimos de top y left de todos los drawnPaths
        const minLeft = Math.min(...this.drawnPaths.map(path => path.left))
        const minTop = Math.min(...this.drawnPaths.map(path => path.top))

        // Encuentra el máximo de los widths para obtener el ancho total de los paths
        const totalWidth = Math.max(...this.drawnPaths.map(path => path.left + path.width)) - minLeft

        // Establecer un tamaño máximo de texto (ancho máximo)
        const maxTextWidth = totalWidth * 0.8 // Usa el 80% del ancho total disponible

        return new fabric.Textbox('', {
            fontSize: COLOR_CODES.COMMAND.fontSize,
            fontFamily: COLOR_CODES.COMMAND.fontFamily,
            fill: this.pencilBrush.color,
            originX: 'center',
            originY: 'center',
            left: minLeft + totalWidth / 2, // Centrado horizontalmente sobre todos los paths
            top: minTop - 20, // Ajustar posición superior
            width: maxTextWidth, // Ajustar el ancho del cuadro de texto al máximo permitido
            textAlign: 'center', // Alineación central del texto
            editable: true,
            data: {
                id: crypto.randomUUID(),
                sectorId: sectorId,
                type: 'drawFreeHandTitle',
                createdAt: dateTime,
            }
        })
    }

    /**
     * Limpia los trazos dibujados.
     */
    _clearDrawnPaths = () => {
        this.drawnPaths.forEach((path) => this.canvas.remove(path))
        this.drawnPaths = []
        this.canvas.renderAll()
    }
}

export default DrawFreehandFabric
