import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from "react-intl"
import moment from 'moment'
import { Offcanvas, Form, Row, Col } from 'react-bootstrap'
import { COLOR_CODES, getColorById, getContrastColor } from '../../config/color.config'
import { getTagById } from '../../config/tag.config'
import { BsArrowClockwise, BsBroadcastPin, BsCardText, BsPentagon, BsPersonStanding, BsPersonWalking, BsStopwatch } from 'react-icons/bs'

const ElementOffcanvas = ({ show, element, onHide, parentNode, canvasRef }) => {
    const intl = useIntl()
    const [sector, setSector] = useState(null)
    const [description, setDescription] = useState(element.data.description || '')

    // Detectar el idioma del navegador
    const userLanguage = navigator.language || navigator.languages[0]
    moment.locale(userLanguage.startsWith('es') ? 'es' : 'en')
    const dateFormat = userLanguage.startsWith('es') ? 'DD/MM/YYYY HH:mm:ss' : 'MM/DD/YYYY HH:mm:ss'

    const handleEditDescription = (e) => {
        const textValue = e.target.value
        setDescription(textValue)
    }

    const handleEditDescriptionBlur = () => {
        if (description !== element.data.description) {
            const dateTime = Date.now()
            canvasRef.setElementData(element, {
                descriptionUpdatedAt: dateTime,
                description: description,
            }, dateTime)
        }
    }

    useEffect(() => {
        if (!canvasRef || !element) return
        const sectors = canvasRef.getObjectById(element.data.sectorId)
        if (sectors && sectors.length > 0) setSector(sectors[0])
    }, [element])

    return (
        <Offcanvas
            show={show}
            onHide={onHide}
            placement={'start'}
            container={parentNode}
        >
            <Offcanvas.Header
                closeButton
                style={{ backgroundColor: getColorById(element.data.colorCodeId).color, color: getContrastColor(getColorById(element.data.colorCodeId).color) }}
                closeVariant={getContrastColor(getColorById(element.data.colorCodeId).color)}
            >
                <Offcanvas.Title>
                    {getTagById(element.data.as).icon} {element.data.title ? element.data.title : element.data.id}
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>

                {sector?.data?.title && sector?.data?.title !== '' && (
                    <Form.Floating className="mb-2">
                        <Form.Control
                            id="elementSector"
                            type="text"
                            size={'sm'}
                            value={sector.data.title}
                            disabled
                            style={{
                                borderStyle: 'solid',
                                borderWidth: '3px',
                                borderColor: 'transparent',
                                borderRadius: '10px',
                                borderImage: `repeating-linear-gradient(45deg, ${COLOR_CODES.COMMAND.color} 0%, ${COLOR_CODES.COMMAND.color} 6%, transparent 6%, transparent 8%, ${COLOR_CODES.COMMAND.color} 8%, ${COLOR_CODES.COMMAND.color} 10%, transparent 10%, transparent 12%) 1`,  // Espacio y repetición del patrón                            
                            }}
                        />
                        <label htmlFor='elementSector'>
                            <BsPentagon className='me-1' />
                            <FormattedMessage id={'elementDataSector'} defaultMessage={'Sector'} />
                        </label>
                    </Form.Floating>
                )}

                <Row className='p-0 mb-2'>
                    <Col className='m-0'>
                        <Form.Floating>
                            <Form.Control
                                id="elementCreatedAt"
                                type="text"
                                size={'sm'}
                                value={moment(element.data.createdAt).format(dateFormat)}
                                disabled
                            />
                            <label htmlFor='elementCreatedAt'>
                                <BsStopwatch className='me-1' />
                                <FormattedMessage id={'elementDataCreatedAt'} defaultMessage={'Created on'} />
                            </label>
                        </Form.Floating>
                    </Col>
                    <Col className='m-0'>
                        <Form.Floating>
                            <Form.Control
                                id="elementLastUpdatedAt"
                                type="text"
                                size={'sm'}
                                value={moment(element.data.lastUpdatedAt).format(dateFormat)}
                                disabled
                            />
                            <label htmlFor='elementLastUpdatedAt'>
                                <BsArrowClockwise className='me-1' />
                                <FormattedMessage id={'elementDataLastUpdatedAt'} defaultMessage={'Last modified'} />
                            </label>
                        </Form.Floating>
                    </Col>
                </Row>

                <Row className='p-0 mb-2'>
                    <Col className='m-0'>
                        <Form.Floating className="mb-2">
                            <Form.Control
                                id="elementAs"
                                type="text"
                                size={'sm'}
                                value={intl.formatMessage({ id: 'tag' + element.data.as.charAt(0).toUpperCase() + element.data.as.slice(1), defaultMessage: element.data.as })}
                                disabled
                            />
                            <label htmlFor='elementAs'>
                                {getTagById(element.data.as).icon}{' '}
                                <FormattedMessage id={'elementDataType'} defaultMessage={'Type'} />
                            </label>
                        </Form.Floating>
                    </Col>
                    <Col className='m-0'>
                        <Form.Floating>
                            <Form.Control
                                id="sectorResourceChannel"
                                type="text"
                                size={'sm'}
                                value={element.data.resourceChannel}
                                className='text-end'
                                disabled
                            />
                            <label htmlFor='sectorResourceChannel'>
                                <BsBroadcastPin className='me-1' />
                                <FormattedMessage id={'sectorDataResourceChannel'} defaultMessage={'Resources channel'} />
                            </label>
                        </Form.Floating>
                    </Col>
                </Row>

                <Form.Floating className="mb-2">
                    <Form.Control
                        id="elementStatus"
                        type="text"
                        size={'sm'}
                        value={element.data.isActive ?
                            (intl.formatMessage({ id: 'controlElementActive', defaultMessage: 'Active' }) + ' (' + moment(element.data.activeUpdatedAt).fromNow() + ')')
                            :
                            (intl.formatMessage({ id: 'controlElementExpected', defaultMessage: 'Expected' }) + ' (' + moment(element.data.activeUpdatedAt).fromNow() + ')')
                        }
                        disabled
                        style={{
                            borderStyle: element.data.isActive ? "solid" : "dashed",  // asegurando estilo sólido
                            borderWidth: 3,
                            borderColor: getColorById(element.data.colorCodeId).color,
                            borderRadius: 10,
                        }}
                    />
                    <label htmlFor='elementStatus'>
                        {element.data.isActive ? <BsPersonWalking className='me-1' /> : <BsPersonStanding className='me-1' />}
                        <FormattedMessage id={'elementDataStatus'} defaultMessage={'Status'} />
                    </label>
                </Form.Floating>

                <Form.Floating className="mb-2">
                    <Form.Control
                        id="elementDescription"
                        as="textarea"
                        size={'sm'}
                        style={{ height: '100px' }}
                        value={description || ''}
                        onChange={handleEditDescription}
                        onBlur={handleEditDescriptionBlur}
                    />
                    <label htmlFor='elementDescription'>
                        <BsCardText className='me-1' />
                        <FormattedMessage id={'elementDataDescription'} defaultMessage={'Description'} />
                    </label>
                </Form.Floating>

            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default ElementOffcanvas
