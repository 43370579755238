import { fabric } from 'fabric'
import { COLOR_CODES } from '../../config/color.config'

/**
 * Class for managing sector drawing on a fabric.js canvas.
 */
class DrawSectorFabric {
    constructor(canvas) {
        if (!(canvas instanceof fabric.Canvas)) {
            throw new Error('Canvas debe ser una instancia de fabric.Canvas')
        }
        this.canvas = canvas 
        this.points = [] // Array of points
        this.circles = [] // Visual circles for points
        this.polyline = null // Line connecting points
        this.tempLine = null // Temporary line to follow the cursor
    }

    /**
     * Handles the start of sector drawing, triggered on mouse:down.
     * @param {Event} e - The mouse event from the canvas.
     */
    drawSectorStart = (e) => {
        this.canvas.on('mouse:move', this._updateTempLine)
        const pointer = this.canvas.getPointer(e)
        const { x, y } = pointer

        // Add the new point to the array
        this.points.push({ x, y })

        // Create a visual circle for the point
        const circle = this._createCircle(x, y)
        this.canvas.add(circle)
        this.circles.push(circle)

        // Update the polyline connecting the points
        this._updatePolyline()
    }

    /**
     * Handles the end of sector drawing, triggered on mouse:dblclick.
     */
    drawSectorEnd = () => {
        this.canvas.off('mouse:move')
        let sectorGroup = null
        if (this.points.length > 2) {
            sectorGroup = this._createSector()
            //this.canvas.add(sector)
            //this.canvas.sendToBack(sector)
        }

        // Clean up temporary visuals
        this.circles.forEach((circle) => this.canvas.remove(circle))
        if (this.polyline) this.canvas.remove(this.polyline)
        if (this.tempLine) this.canvas.remove(this.tempLine)

        // Reset points and visuals
        this.points = []
        this.circles = []
        this.polyline = null
        this.tempLine = null
        return sectorGroup
    }

    /**
     * Calculates the area of the sector defined by the points.
     * @param {Array} points - Array of points defining the sector.
     * @returns {number} - The calculated area of the sector in pixels
     */
    calculateArea = (points) => {
        return Math.abs(
            points.reduce((area, point, i) => {
                const { x: x1, y: y1 } = point
                const { x: x2, y: y2 } = points[(i + 1) % points.length]
                return area + x1 * y2 - x2 * y1
            }, 0) / 2
        )
    }

    /**
     * Creates a visual circle to represent a point on the canvas.
     * @private
     * @param {number} x - X coordinate of the circle.
     * @param {number} y - Y coordinate of the circle.
     * @returns {fabric.Circle} - The created circle object.
     */
    _createCircle = (x, y) => {
        return new fabric.Circle({
            left: x - 3,
            top: y - 3,
            radius: 6,
            fill: COLOR_CODES.COMMAND.rgba,
            stroke: COLOR_CODES.COMMAND.color,
            strokeWidth: 1,
            originX: 'center',
            originY: 'center',
            selectable: false,
            evented: false,
            objectCaching: false,
        })
    }

    /**
     * Updates the polyline connecting all points.
     * @private
     */
    _updatePolyline = () => {
        if (this.points.length > 1) {
            if (this.polyline) this.canvas.remove(this.polyline)

            this.polyline = new fabric.Polyline(this.points, {
                fill: '',
                stroke: COLOR_CODES.COMMAND.color,
                strokeWidth: 2,
                strokeDashArray: [10, 3, 2, 3],
                selectable: false,
                evented: false,
                objectCaching: false,
            })

            this.canvas.add(this.polyline)
        }
    }

    /**
    * Updates the temporary line following the pointer.
    * @param {fabric.Event} e - The mouse move event.
    */
    _updateTempLine = (e) => {
        if (this.points.length > 0) {
            const pointer = this.canvas.getPointer(e)
            const lastPoint = this.points[this.points.length - 1]

            if (this.tempLine) {
                this.canvas.remove(this.tempLine)
            }

            this.tempLine = new fabric.Line(
                [lastPoint.x, lastPoint.y, pointer.x, pointer.y],
                {
                    stroke: COLOR_CODES.COMMAND.color,
                    strokeWidth: 2,
                    strokeDashArray: [10, 3, 2, 3],
                    selectable: false,
                    evented: false,
                    objectCaching: false,
                }
            )

            this.canvas.add(this.tempLine)
        }
    }

    /**
     * Creates a sector from the points array.
     * @private
     * @returns {fabric.Group} - The created group object.
     */
    _createSector = () => {
        if (this.points.length > 2) {
            const id = crypto.randomUUID()
            const dateTime = Date.now()
            const area = this.calculateArea(this.points)

            const sectorPolygon = new fabric.Polygon(this.points, {
                fill: COLOR_CODES.COMMAND.rgba,
                stroke: COLOR_CODES.COMMAND.color,
                strokeWidth: 2,
                strokeDashArray: [10, 3, 2, 3],
                lockMovementX: true,
                lockMovementY: true,
                lockRotation: true,
                lockScalingX: true,
                lockScalingY: true,
                lockScalingFlip: true,
                lockSkewingX: true,
                lockSkewingY: true,
                lockUniScaling: true,
                evented: true,
                selectable: true,
                hasControls: false,
                moveCursor: false,
                hoverCursor: 'pointer',
                globalCompositeOperation: 'source-over', // source-over  destination-over  lighter  multiply
                data: {
                    id: crypto.randomUUID(),
                    sectorId: id,
                    type: 'sectorPolygon',
                    createdAt: dateTime,
                    areapx: area,
                }
            })

            // Obtener dimensiones del canvas
            const canvasWidth = this.canvas.getWidth
            const canvasHeight = this.canvas.getHeight

            // Calcular las coordenadas iniciales del texto
            let textLeft = sectorPolygon.left
            let textTop = sectorPolygon.top - COLOR_CODES.COMMAND.fontSize

            // Ajustar la posición si está fuera de los límites
            if (textLeft < 20) textLeft = 0
            if (textTop < 20) textTop = sectorPolygon.height + COLOR_CODES.COMMAND.fontSize
            if (textLeft + sectorPolygon.width > canvasWidth) {
                textLeft = canvasWidth - sectorPolygon.width
            }
            if (textTop + sectorPolygon.height > canvasHeight) {
                textTop = canvasHeight - sectorPolygon.height
            }

            const sectorText = new fabric.Textbox(id, {
                left: textLeft,
                top: textTop,
                fontSize: COLOR_CODES.COMMAND.fontSize,
                fill: COLOR_CODES.COMMAND.fontColor, // Color del texto
                fontFamily: COLOR_CODES.COMMAND.fontFamily,
                data: {                    
                    id: crypto.randomUUID(),
                    sectorId: id,
                    type: 'sectorTitle',
                    createdAt: dateTime,
                }
            })

            const sectorGroup = new fabric.Group([sectorPolygon, sectorText], {
                lockMovementX: true,
                lockMovementY: true,
                lockRotation: true,
                lockScalingX: true,
                lockScalingY: true,
                lockScalingFlip: true,
                lockSkewingX: true,
                lockSkewingY: true,
                lockUniScaling: true,
                evented: true,
                selectable: true,
                hasControls: false,
                moveCursor: false,
                hoverCursor: 'pointer',
                globalCompositeOperation: 'source-over', // source-over  destination-over  lighter  multiply
                borderColor: COLOR_CODES.COMMAND.selectedColor,
                data: {
                    id: id,
                    type: 'sectorGroup',
                    createdAt: dateTime,
                    title: null,
                    commandChannel: null,
                    resourceChannel: null,
                    personInCharge: null,
                    description: null,
                    areapx: area,
                    locked: true,
                    lockedUpdatedAt: dateTime,
                }
            })

            return sectorGroup
        }
        return null
    }

    /**
     * Adds a delete control to the polygon for easy removal.
     * @private
     * @param {fabric.Polygon} polygon - The polygon object to add the control to.
     */
    _addDeleteControlToPolygon = (polygon) => {
        const deleteIcon = "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='595.275px' height='595.275px' viewBox='200 215 230 470' xml:space='preserve'%3E%3Ccircle style='fill:%23F44336' cx='299.76' cy='439.067' r='218.516'/%3E%3Cg%3E%3Crect x='267.162' y='307.978' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)' style='fill:white' width='65.545' height='262.18'/%3E%3Crect x='266.988' y='308.153' transform='matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)' style='fill:white' width='65.544' height='262.179'/%3E%3C/g%3E%3C/svg%3E"
        const deleteImg = document.createElement('img')
        deleteImg.src = deleteIcon

        const editIcon = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-pencil' viewBox='0 0 16 16'%3E %3Cpath d='M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325'/%3E%3C/svg%3E"
        const editImg = document.createElement('img')
        editImg.src = editIcon

        // Función que se dispara al hacer clic para eliminar el polígono
        const deleteObject = (e, obj) => {
            const target = obj.target
            if (target) {
                this.canvas.remove(target)  // Elimina el polígono
                this.canvas.renderAll()  // Fuerza un redibujado del canvas
            } else {
                console.warn('No target to delete')
            }
        }

        // Función que se dispara al hacer clic para eliminar el polígono
        const editObject = (e, obj) => {
            const target = obj.target
            if (target) {
                // PONER AQUI FUNCIONES DE EDICIÓN
                this.canvas.renderAll()  // Fuerza un redibujado del canvas
            } else {
                console.warn('No target to edit')
            }
        }

        const renderIcon = (ctx, left, top) => {
            const size = 24
            ctx.save()
            ctx.translate(left, top)
            ctx.drawImage(deleteImg, -size / 2, -size / 2, size, size)
            ctx.restore()
        }

        const renderEditIcon = (ctx, left, top) => {
            const size = 24
            ctx.save()
            ctx.translate(left, top)
            ctx.drawImage(editImg, -size / 2, -size / 2, size, size)
            ctx.restore()
        }

        polygon.controls.deleteControl = new fabric.Control({
            x: 0,
            y: 0,
            //offsetX: 16,
            offsetY: 16,
            cursorStyle: 'pointer',
            mouseUpHandler: deleteObject,
            render: renderIcon,
            cornerSize: 24,
        })

        polygon.controls.editControl = new fabric.Control({
            x: 0.1,
            y: 0,
            //offsetX: 16,
            offsetY: 16,
            cursorStyle: 'pointer',
            mouseUpHandler: editObject,
            render: renderEditIcon,
            cornerSize: 24,
        })
    }
}

export default DrawSectorFabric
