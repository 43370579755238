import React, { useState } from 'react'
import { useIntl } from "react-intl"
import { Button } from 'react-bootstrap'
import { BsPlusSquareDotted, BsStopCircle } from 'react-icons/bs'

const DrawSectorControl = ({ canvasRef }) => {
    const intl = useIntl()

    const [isDrawing, setIsDrawing] = useState(false)

    const onChange = (isDrawing) => {
        canvasRef.setIsDrawing(isDrawing)
        setIsDrawing(isDrawing)
        if (!isDrawing) canvasRef.setLastUpdateDateTime()  // Actualiza la fecha de actualización del canvas al finalizar el dibujo
    }

    const handleDrawPolygon = () => {
        if (canvasRef) {
            if (!isDrawing && !canvasRef.isDrawing()) {
                canvasRef.startDrawSector(onChange)
            } else {
                canvasRef.endDrawSector(onChange)                
            }
        }
    }

    return (
        <Button
            variant={isDrawing ? 'primary' : 'light'}
            onClick={handleDrawPolygon}
            title={intl.formatMessage({ id: "controlsAddSector", defaultMessage: "Add sector" })}
        >
            {isDrawing ? (
                <BsStopCircle size={20} />
            ) : (
                <BsPlusSquareDotted size={20} />
            )}
        </Button>
    )
}

export default DrawSectorControl
