// useGomDB
import { useState, useEffect } from 'react'
import GomDBManager from '../lib/GomDBManager'

const useGomDB = () => {
    const [recordsDates, setRecordsDates] = useState([]) // Solo almacenamos fechas
    const [selectedRecord, setSelectedRecord] = useState(null)
    const [lastRecord, setLastRecord] = useState(null)
    const [updateDate, setUpdateDate] = useState(null)
    const gomDBManager = new GomDBManager('gomfireDb', 'canvas', 1)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const db = await gomDBManager.openDatabase()
                const record = await gomDBManager.getLastRecord()
                setLastRecord(record)
                setUpdateDate(record ? record.id : Date.now())
            } catch (error) {
                console.error(error)
            }
        }

        fetchData()

        return () => {
            // Limpiar recursos si es necesario
        }
    }, [])

    const fetchDates = async () => {
        try {
            await gomDBManager.openDatabase()
            const allDates = await gomDBManager.getAllDates()
            setRecordsDates(allDates)
        } catch (error) {
            console.error(error)
        }
    }

    const fetchRecord = async (id) => {
        try {
            const db = await gomDBManager.openDatabase()
            const record = await gomDBManager.getRecordById(id)
            setSelectedRecord(record)
        } catch (error) {
            console.error(error)
        }
    }

    const addRecord = async (data) => {
        try {
            const db = await gomDBManager.openDatabase()
            await gomDBManager.addRecord(JSON.stringify(data))
            setUpdateDate(Date.now())
            setSelectedRecord(await gomDBManager.getLastRecord())
            setRecordsDates(await gomDBManager.getAllDates())
        } catch (error) {
            console.error(error)
        }
    }

    const clearAllRecords = async () => {
        try {
            const db = await gomDBManager.openDatabase()
            await gomDBManager.clearAllRecords()
            setLastRecord(null)
            setUpdateDate(null)
            setSelectedRecord(null)
            setRecordsDates([])
            console.log('Todos los registros han sido eliminados')
        } catch (error) {
            console.error(error)
        }
    }

    const deleteDatabase = async () => {
        try {
            await gomDBManager.deleteDatabase()
            console.log('Base de datos eliminada correctamente')
        } catch (error) {
            console.error(error)
        }
    }

    const deleteStore = async () => {
        try {
            await gomDBManager.deleteObjectStore()
            console.log(`Store "${gomDBManager.storeName}" eliminado correctamente`)
        } catch (error) {
            console.error(error)
        }
    }

    return {
        recordsDates,
        selectedRecord,
        lastRecord,
        updateDate,
        addRecord,
        fetchRecord,
        fetchDates,
        clearAllRecords,
        deleteDatabase,
        deleteStore
    }
}

export default useGomDB
