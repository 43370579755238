import React, { useState } from 'react'
import { useIntl } from "react-intl"
import { Button } from 'react-bootstrap'
import { BiCopyright } from 'react-icons/bi'

import moment from 'moment'

import CopyModal from '../modal/CopyModal'

const CopyrightControl = ({ label, parentNode }) => {
    const intl = useIntl()
    const [showModal, setShowModal] = useState(false)

    const toggleShowModal = () => {
        setShowModal(!showModal)
    }

    return (
        <>
            <Button
                variant="light"
                size='sm'
                className="d-inline-flex align-items-center"
                title={intl.formatMessage({ id: "copyrightTitle", defaultMessage: "GOM Fire - CIVILIO Information Systems s.l." })}
                onClick={() => toggleShowModal()}
            >
                <BiCopyright />{moment().year()}
            </Button>
            <CopyModal title={label} show={showModal} onClose={() => toggleShowModal()} parentNode={parentNode} />
        </>
    )
}

export default CopyrightControl