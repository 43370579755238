import { fabric } from 'fabric'

// gridControls.js
class GridControls {
    constructor(canvas) {
        if (!(canvas instanceof fabric.Canvas)) {
            throw new Error('Canvas debe ser una instancia de fabric.Canvas')
        }

        this.canvas = canvas
        this.gridGroup = null
        this.grid = { size: 0, color: '#e0e0e0', opacity: 0.8 }
        this.gridStatus = false
    }

    setGrid = ({ size = 0, color = '#e0e0e0', opacity = 0.8 } = {}) => {
        const { width, height } = this.canvas

        this.grid = {
            size,
            color,
            opacity
        }
        // Si ya hay un grid, no hacer nada
        if (this.gridGroup || this.grid.size === 0) {
            // Si el grid ya existe, eliminarlo
            this.remove()
            this.gridStatus = false
            if (size === 0) return
        }
        this.gridStatus = true

        // Crear un grupo de líneas para la malla
        const lines = []
        const createLine = (coords) => new fabric.Line(coords, {
            stroke: color,
            strokeWidth: 1,
            selectable: false,
            evented: false,
            opacity
        })

        // Líneas verticales
        for (let i = 0; i <= width; i += size) {
            lines.push(createLine([i, 0, i, height]))
        }

        // Líneas horizontales
        for (let j = 0; j <= height; j += size) {
            lines.push(createLine([0, j, width, j]))
        }

        const zoom = this.canvas.getZoom()
        // Crear un grupo para la malla y añadirlo al canvas
        const gridGroup = new fabric.Group(lines, {
            selectable: false,
            evented: false,
            top: 0,
            left: 0,
            data: {  // Data con propiedades específicas.
                grid: true,
            },
            scaleX: 1 / zoom,
            scaleY: 1 / zoom,
        })

        this.canvas.add(gridGroup)
        // Guardar la referencia al grupo del grid para poder eliminarlo después
        this.gridGroup = gridGroup
        this.gridGroup.setCoords()
        this.canvas.moveTo(gridGroup, 0)
        this.canvas.requestRenderAll()
    }

    getStatus = () => this.gridStatus

    remove = () => {
        if (this.gridGroup) this.canvas.remove(this.gridGroup)
        this.gridGroup = null
        this.canvas.requestRenderAll()
    }
}

export default GridControls