import { fabric } from 'fabric'

class ElementControls {
    constructor(canvas, dataCallback) {
        if (!(canvas instanceof fabric.Canvas)) {
            throw new Error('Canvas debe ser una instancia de fabric.Canvas')
        }
        this.canvas = canvas
        this.dataCallback = dataCallback
    }

    /**
     * Maneja el movimiento de objetos en el canvas.
     */
    handleObjectMoving(event) {
        const dateTime = Date.now()
        const movingObject = event.transform?.target
        if (!movingObject || movingObject.data.type === 'sectorGroup') return
        this._updateSectors(movingObject, (sector, isPartiallyInside) => {
            sector.set('opacity', isPartiallyInside ? 0.5 : 1)
        })
    }

    /**
     * Maneja la modificación de un objeto después de moverlo o redimensionarlo.
     */
    handleObjectModified(event) {
        const dateTime = Date.now()
        const movingObject = event.transform?.target
        if (!movingObject) return
        if (movingObject.data && movingObject.data.type === 'sectorGroup') {
            this.dataCallback(movingObject, {
                moveUpdatedAt: dateTime,
                movedToLeft: movingObject.left,
                movedToTop: movingObject.top,
                width: movingObject.width,
                height: movingObject.height,
                scaleX: movingObject.scaleX,
                scaleY: movingObject.scaleY,
                angle: movingObject.angle,
            }, dateTime)
            return
        }

        movingObject.set("data", {
            ...movingObject.get('data'),
            sectorUpdatedAt: dateTime,
            sectorId: '',
            resourceChannel: '',
        })
        this._updateSectors(movingObject, (sector, isPartiallyInside) => {
            if (isPartiallyInside) {
                movingObject.set("data", {
                    ...movingObject.get('data'),
                    sectorUpdatedAt: dateTime,
                    sectorId: sector.data.id,
                    resourceChannel: sector.data.resourceChannel,
                })
                sector.set("data", {
                    ...sector.get('data'),
                    lastUpdatedAt: dateTime,
                })
            }
            sector.set('opacity', 1)
        })
        movingObject.setCoords()

        this.dataCallback(movingObject, {
            moveUpdatedAt: dateTime,
            movedToLeft: movingObject.left,
            movedToTop: movingObject.top,
            width: movingObject.width,
            height: movingObject.height,
            scaleX: movingObject.scaleX,
            scaleY: movingObject.scaleY,
            angle: movingObject.angle,
        }, dateTime)
    }

    /**
     * Maneja la selección de un objeto en el canvas.
     */
    handleObjectSelected(event) {
        const dateTime = Date.now()
        const movingObject = event.target
        if (!movingObject || movingObject.data.type === 'sectorGroup') return

        this._updateSectors(movingObject, (sector, isPartiallyInside) => {
            if (isPartiallyInside) {
                this.dataCallback(movingObject, {
                    sectorUpdatedAt: dateTime,
                    sectorId: sector.data.id,
                    resourceChannel: sector.data.resourceChannel,
                }, dateTime)
                sector.set("data", {
                    ...sector.get('data'),
                    lastUpdatedAt: dateTime,
                })
            } else {
                this.dataCallback(movingObject, {
                    sectorUpdatedAt: dateTime,
                    sectorId: '',
                    resourceChannel: '',
                }, dateTime)
            }
            sector.set('opacity', 1)
        })
    }

    /**
     * Actualiza los sectores en relación con un objeto en movimiento.
     * @param {fabric.Object} movingObject - Objeto en movimiento.
     * @param {Function} callback - Función de actualización para los sectores.
     */
    _updateSectors(movingObject, callback) {
        if (!movingObject || movingObject.data.type === 'sectorGroup') return;

        movingObject.setCoords();

        const sectors = this.canvas.getObjects('group').filter(
            (obj) => obj.data?.type === 'sectorGroup'
        );

        // Obtener el bounding box del objeto en coordenadas del canvas
        const objectBoundingBox = movingObject.getBoundingRect(true);

        sectors.forEach((sector) => {
            sector.setCoords();
            const sectorBoundingBox = sector.getBoundingRect(true);

            // Verificar si los bounding boxes se superponen
            const isInside = this._isBoundingBoxIntersecting(objectBoundingBox, sectorBoundingBox);

            // Llamar al callback con el resultado
            callback(sector, isInside);
        });

        this.canvas.renderAll();
    }

    /**
     * Verifica si dos bounding boxes se superponen (colisión básica)
    */
    _isBoundingBoxIntersecting(boxA, boxB) {
        return !(
            boxA.left > boxB.left + boxB.width ||  // A está completamente a la derecha de B
            boxA.left + boxA.width < boxB.left || // A está completamente a la izquierda de B
            boxA.top > boxB.top + boxB.height ||  // A está completamente debajo de B
            boxA.top + boxA.height < boxB.top     // A está completamente arriba de B
        )
    }
}

export default ElementControls
