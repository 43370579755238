import { fabric } from 'fabric'

class BackgroundControls {
    constructor(canvas) {
        if (!(canvas instanceof fabric.Canvas)) {
            throw new Error('Canvas debe ser una instancia de fabric.Canvas')
        }
        this.canvas = canvas
    }

    /**
     * Establece una imagen de fondo en el canvas.
     * @param {string} dataUrl - URL de la imagen.
     * @param {Object} [options={}] - Opciones adicionales.
     * @param {number} [options.opacity=0.5] - Opacidad de la imagen.
     * @param {string} [options.originX='center'] - Punto de origen en X.
     * @param {string} [options.originY='center'] - Punto de origen en Y.
     */
    setBackgroundImage = (dataUrl, options = {}, callBack) => {
        if (!dataUrl) return

        const {
            opacity = 0.5,
            originX = 'center',
            originY = 'center',
        } = options

        fabric.Image.fromURL(dataUrl, (oImg) => {
            oImg.scaleToWidth(this.canvas.width)
            oImg.set({
                originX,
                originY,
                left: this.canvas.width / 2,
                top: this.canvas.height / 2,
                opacity,
            })

            this.canvas.setBackgroundImage(oImg, () => {
                //this.canvas.fire('backgroundImage:added', oImg)
                this.canvas.renderAll()
                callBack()
            })
        })
    }

    /**
     * Elimina la imagen de fondo del canvas.
     */
    removeBackgroundImage = () => {
        this.canvas.setBackgroundImage(null, this.canvas.renderAll.bind(this.canvas))
    }

    /**
     * Establece un color de fondo en el canvas.
     * @param {string} color - Color en formato HEX, RGB o nombre de color.
     */
    setBackgroundColor = (color) => {
        this.canvas.setBackgroundColor(color, this.canvas.renderAll.bind(this.canvas))
    }
}

export default BackgroundControls
