import { fabric } from 'fabric'

import { TAG_CODES } from "../../config/tag.config"
import { getColorById } from '../../config/color.config'

// GOM GomElement properties
class GomElement {
    constructor(canvas, gomType, {
        type = 'circle',
        tagCode = TAG_CODES.DEFAULT,
        withText = true,
        defaultText = 'Circle',
        positionText = 'top', // top - right - center - bottom - left
        fieldsText = ["title"],
        endTextWidthStr = "",
        angle = 0,
        colorCodeId = 'default',
        withFillColor = false,
        schema = {
            "type": 'object',
            "properties": {
                "title": {
                    "title": "Text",
                    "description": "Write a text",
                    "type": "string",
                },
            },
            "required": []
        },
        svgData = null,
        pathData = null,
    }) {
        if (!(canvas instanceof fabric.Canvas)) {
            throw new Error('Canvas debe ser una instancia de fabric.Canvas')
        }

        this.canvas = canvas
        this.gomType = gomType
        this.id = crypto.randomUUID()
        this.createdAt = this.lastUpdatedAt = Date.now()
        this.type = type
        this.tagCode = tagCode
        this.withText = withText
        this.defaultText = defaultText

        const allowedPositions = ['top', 'right', 'center', 'bottom', 'left']
        if (!allowedPositions.includes(positionText)) {
            throw new Error(`Invalid value for positionText. Allowed values are: ${allowedPositions.join(', ')}`)
        }
        this.positionText = positionText
        this.fieldsText = fieldsText
        this.endTextWidthStr = endTextWidthStr
        this.angle = angle
        this.colorCodeId = colorCodeId
        this.withFillColor = withFillColor
        this.schema = schema
        this.svgData = svgData
        this.pathData = pathData

        this.colorCode = null
        this.elementObject = null
        this.otherObjects = []
        this.textObject = null
    }

    /**
    * Get method to retrieve the current state of the GomElement.
    * @returns {Object} - The current state of the GomElement.
    */
    get = () => ({
        id: this.id,
        gomType: this.gomType,
        createdAt: this.createdAt,
        lastUpdatedAt: this.lastUpdatedAt,
        type: this.type,
        tagCode: this.tagCode,
        withText: this.withText,
        defaultText: this.defaultText,
        positionText: this.positionText,
        fieldsText: this.fieldsText,
        endTextWidthStr: this.endTextWidthStr,
        angle: this.angle,
        colorCodeId: this.colorCodeId,
        withFillColor: this.withFillColor,
        schema: this.schema,
        svgData: this.svgData,
        pathData: this.pathData,
        elementObject: this.elementObject,
        otherObjects: this.otherObjects,
        textObject: this.textObject,
    })


    /**
    * Set method to update the state of the GomElement.
    * @param {Object} newState - The new state values to update.
    */
    set = (newState) => {
        if (typeof newState !== 'object' || newState === null) {
            throw new Error('Invalid state: newState must be a non-null object')
        }

        Object.keys(newState).forEach(key => {
            if (this.hasOwnProperty(key)) {
                this[key] = newState[key]
            }
        })

        this.lastUpdatedAt = new Date.now() // Actualiza la última modificación
    }

    draw = () => {
        this._setColorCode()
        switch (this.type) {
            case 'line':
                this.elementObject = this._drawLine()
                break
            case 'arrow':
                const { element, otherObjects } = this._drawArrow()
                this.elementObject = element
                this.otherObjects = otherObjects
                break
            case 'triangle':
                this.elementObject = this._drawTriangle()
                break
            case 'rectangle':
                this.elementObject = this._drawRectangle()
                break
            case 'circle':
                this.elementObject = this._drawCircle()
                break
            case 'ellipse':
                this.elementObject = this._drawEllipse()
                break
            case 'star':
                this.elementObject = this._drawStar()
                break
            case 'sectorFunctional':
                const { triangle1, triangle2 } = this._drawSectorFunctional()
                this.elementObject = triangle1
                this.otherObjects = [triangle2]
                break
            case 'path':
                this.elementObject = this._drawPath()
                break
            case 'svg':
                fabric.loadSVGFromString(this.svgData, (objects, options) => {
                    objects.forEach((object) => {
                        object.fill = this.colorCode.fill
                        object.stroke = this.colorCode.color
                    })
                    this.elementObject = this._drawSVG(objects, options)
                })
                break
            default:
                return
        }
        const objs = []
        objs.push(this.elementObject)
        if (this.withText) {
            this.textObject = this._createText()
            objs.push(this.textObject)
        }
        if (this.otherObjects) objs.push(...this.otherObjects)

        const group = this._drawGroup(objs)

        this.canvas.add(group)
        this.canvas.renderAll()
        this.elementObject = null
        this.otherObjects = []
    }

    _drawGroup = (objs) => {
        return new fabric.Group(objs, {
            lockScalingX: false,
            lockScalingY: false,
            lockScalingFlip: false,
            lockSkewingX: false,
            lockSkewingY: false,
            lockUniScaling: true,
            selectable: true,
            cornerColor: this.colorCode.color,
            data: {
                id: this.id,
                type: 'gomGroup',
                as: this.tagCode.id,
                gomType: this.gomType,
                title: this.defaultText,
                resourceChannel: null,
                createdAt: this.createdAt,
                fillActive: this.withFillColor,
                colorCodeId: this.colorCodeId,
                isActive: this.tagCode.defaultIsActive,
            }
        })
    }

    _drawLine = () => {
        return new fabric.Line([0, 0, 100, 0], {
            gomType: this.type, // Tipo de objeto
            left: this.canvas.width / 2,
            top: this.canvas.height / 2,
            stroke: this.colorCode.color,
            strokeWidth: this.tagCode.defaultBorderCode.strokeWidth, // Ancho del borde (puedes ajustar este valor)
            strokeDashArray: this.tagCode.defaultBorderCode.strokeDashArray,
            strokeColor: this.colorCode.color,
            strokeUniform: true, // Mantiene el grosor del borde constante
            angle: this.angle,
            originX: 'center',  // left, center, right
            originY: 'center',  // top, center, bottom
            data: {
                id: crypto.randomUUID(),
                groupId: this.id,
                type: 'gomLine',
                createdAt: this.createdAt,
            }
        })
    }

    _drawCircle = () => {
        return new fabric.Circle({
            gomType: this.type, // Tipo de objeto
            radius: 100 / 2,
            fill: this.withFillColor ? this.colorCode.fill : 'transparent',
            left: this.canvas.width / 2,  // Centrar horizontalmente
            top: this.canvas.height / 2,  // Centrar verticalmente
            originX: 'center',
            originY: 'center',
            stroke: this.colorCode.color,             // Color del borde
            strokeWidth: this.tagCode.defaultBorderCode.strokeWidth, // Ancho del borde (puedes ajustar este valor)
            strokeDashArray: this.tagCode.defaultBorderCode.strokeDashArray,
            strokeColor: this.colorCode.color,
            strokeUniform: true, // Mantiene el grosor del borde constante
            data: {
                id: crypto.randomUUID(),
                groupId: this.id,
                type: 'gomCircle',
                createdAt: this.createdAt,
            }
        })
    }

    _drawEllipse = () => {
        return new fabric.Ellipse({
            gomType: this.type, // Tipo de objeto
            fill: this.withFillColor ? this.colorCode.fill : 'transparent',
            rx: 50, // Radio en el eje x
            ry: 30, // Radio en el eje y
            fill: this.fill,
            left: this.canvas.width / 2,  // Centrar horizontalmente
            top: this.canvas.height / 2,  // Centrar verticalmente
            originX: 'center',
            originY: 'center',
            stroke: this.colorCode.color,             // Color del borde
            strokeWidth: this.tagCode.defaultBorderCode.strokeWidth, // Ancho del borde (puedes ajustar este valor)
            strokeDashArray: this.tagCode.defaultBorderCode.strokeDashArray,
            strokeColor: this.colorCode.color,
            strokeUniform: true, // Mantiene el grosor del borde constante
            data: {
                id: crypto.randomUUID(),
                groupId: this.id,
                type: 'gomEllipse',
                createdAt: this.createdAt,
            }
        })
    }

    _drawRectangle = () => {
        return new fabric.Rect({
            gomType: this.type, // Tipo de objeto
            left: this.canvas.width / 2, // Posición X del rectángulo
            top: this.canvas.height / 2, // Posición Y del rectángulo
            width: 100, // Ancho del rectángulo
            height: 100, // Altura del rectángulo
            fill: this.withFillColor ? this.colorCode.fill : 'transparent',
            originX: 'center',
            originY: 'center',
            stroke: this.colorCode.color,             // Color del borde
            strokeWidth: this.tagCode.defaultBorderCode.strokeWidth, // Ancho del borde (puedes ajustar este valor)
            strokeDashArray: this.tagCode.defaultBorderCode.strokeDashArray,
            strokeColor: this.colorCode.color,
            strokeUniform: true, // Mantiene el grosor del borde constante
            data: {
                id: crypto.randomUUID(),
                groupId: this.id,
                type: 'gomRectangle',
                createdAt: this.createdAt,
            }
        })
    }

    _drawTriangle = () => {
        return new fabric.Triangle({
            gomType: this.type, // Tipo de objeto
            width: 100,
            height: 100,
            fill: this.withFillColor ? this.colorCode.fill : 'transparent',
            left: this.canvas.width / 2,  // Centrar horizontalmente
            top: this.canvas.height / 2,  // Centrar verticalmente
            angle: this.angle,
            originX: 'center',
            originY: 'center',
            stroke: this.colorCode.color,             // Color del borde
            strokeWidth: this.tagCode.defaultBorderCode.strokeWidth, // Ancho del borde (puedes ajustar este valor)
            strokeDashArray: this.tagCode.defaultBorderCode.strokeDashArray,
            strokeColor: this.colorCode.color,
            strokeUniform: true, // Mantiene el grosor del borde constante
            data: {
                id: crypto.randomUUID(),
                groupId: this.id,
                type: 'gomTriangle',
                createdAt: this.createdAt,
            }
        })
    }

    _drawStar = () => {
        // Configuración del starburst
        const centerX = this.canvas.width / 2
        const centerY = this.canvas.height / 2
        const outerRadius = 50 // Radio externo de la estrella
        const innerRadius = 20 // Radio interno de la estrella
        const numberOfPoints = 5 // Número de puntas de la estrella
        const rotation = (Math.PI * 2) / numberOfPoints // Ángulo de rotación entre cada punto

        // Calcular las coordenadas para la estrella
        const starCoords = []
        let firstVertexCoords = null

        for (let i = 0; i < numberOfPoints; i++) {
            const angleOuter = rotation * i
            const angleInner = rotation * (i + 0.5) // Ajuste para alternar entre puntas externas e internas
            const xOuter = centerX + outerRadius * Math.cos(angleOuter)
            const yOuter = centerY + outerRadius * Math.sin(angleOuter)
            const xInner = centerX + innerRadius * Math.cos(angleInner)
            const yInner = centerY + innerRadius * Math.sin(angleInner)

            // Almacenar las coordenadas del primer vértice orientado en vertical arriba
            if (i === 0) {
                firstVertexCoords = { x: xOuter, y: yOuter }
            }

            // Agregar las coordenadas del punto externo e interno
            starCoords.push({ x: xOuter, y: yOuter }, { x: xInner, y: yInner })
        }

        // Crear el objeto de la estrella usando fabric.Polygon
        return new fabric.Polygon(starCoords, {
            gomType: this.type, // Tipo de objeto
            left: this.canvas.width / 2,
            top: this.canvas.height / 2,
            angle: this.angle,
            originX: 'center',
            originY: 'center',
            fill: this.withFillColor ? this.colorCode.fill : 'transparent',
            stroke: this.colorCode.color,             // Color del borde
            strokeWidth: this.tagCode.defaultBorderCode.strokeWidth, // Ancho del borde (puedes ajustar este valor)
            strokeDashArray: this.tagCode.defaultBorderCode.strokeDashArray,
            strokeColor: this.colorCode.color,
            strokeUniform: true, // Mantiene el grosor del borde constante
            data: {
                id: crypto.randomUUID(),
                groupId: this.id,
                type: 'gomStar',
                createdAt: this.createdAt,
            },
        })
    }

    _drawArrow = () => {
        const lineLength = 100 // Longitud de la línea
        const arrowSize = { width: 12, height: 15 } // Tamaño del triángulo

        // Crear la línea
        const line = new fabric.Line([0, 0, lineLength, 0], {
            gomType: this.type,
            left: this.canvas.width / 2,
            top: this.canvas.height / 2,
            stroke: this.colorCode.color,
            strokeWidth: this.tagCode.defaultBorderCode.strokeWidth,
            strokeDashArray: this.tagCode.defaultBorderCode.strokeDashArray,
            strokeColor: this.colorCode.color,
            strokeUniform: true,
            angle: this.angle,
            originX: "center",
            originY: "center",
            data: {
                id: crypto.randomUUID(),
                groupId: this.id,
                type: "gomArrowLine",
                createdAt: this.createdAt,
            }
        })

        // Posicionar el triángulo en el extremo derecho de la línea
        const triangle = new fabric.Triangle({
            gomType: this.type,
            width: arrowSize.width,
            height: arrowSize.height,
            left: line.left + (lineLength / 2),  // Ubicarlo al final de la línea
            top: line.top, // Mantenerlo centrado en la misma altura de la línea
            fill: this.colorCode.fill,
            stroke: this.colorCode.color,
            strokeWidth: this.tagCode.defaultBorderCode.strokeWidth,
            strokeDashArray: this.tagCode.defaultBorderCode.strokeDashArray,
            strokeColor: this.colorCode.color,
            strokeUniform: true,
            angle: this.angle + 90,  // Girar el triángulo para que apunte hacia adelante
            originX: "center",
            originY: "center",
            data: {
                id: crypto.randomUUID(),
                groupId: this.id,
                type: "gomArrowTriangle",
                createdAt: this.createdAt,
            },
        })

        return { element: line, otherObjects: [triangle] }
    }

    _drawPath = () => {
        return new fabric.Path(this.pathData, {
            gomType: this.type,
            left: this.canvas.width / 2,
            top: this.canvas.height / 2,
            fill: this.withFillColor ? this.colorCode.fill : 'transparent',
            stroke: this.colorCode.color,
            strokeWidth: this.tagCode.defaultBorderCode.strokeWidth,
            strokeDashArray: this.tagCode.defaultBorderCode.strokeDashArray,
            strokeColor: this.colorCode.color,
            strokeUniform: true,
            angle: this.angle,
            originX: 'center',
            originY: 'center',
            data: {
                id: crypto.randomUUID(),
                groupId: this.id,
                type: "gomPath",
                createdAt: this.createdAt,
            },
        })

    }

    _drawSVG = (objects, options) => {
        const svgObject = fabric.util.groupSVGElements(objects, options)
        svgObject.set({
            gomType: this.type,
            fill: this.withFillColor ? this.colorCode.fill : 'transparent',
            left: this.canvas.width / 2,  // Centrar horizontalmente
            top: this.canvas.height / 2,  // Centrar verticalmente
            stroke: this.colorCode.color,
            strokeWidth: this.tagCode.defaultBorderCode.strokeWidth,
            strokeDashArray: this.tagCode.defaultBorderCode.strokeDashArray,
            strokeColor: this.colorCode.color,
            strokeUniform: true,
            angle: this.angle,
            originX: 'center',
            originY: 'center',
            scaleX: 1,  // Puedes modificar esto para cambiar tamaño dinámicamente
            scaleY: 1,
            data: {
                id: crypto.randomUUID(),
                groupId: this.id,
                type: "gomSVG",
                createdAt: this.createdAt,
            },
        })
        return svgObject
    }

    _drawSectorFunctional = () => {
        const triangle1 = new fabric.Triangle({
            gomType: this.type, // Tipo de objeto
            width: 100,
            height: 100,
            fill: this.withFillColor ? this.colorCode.fill : 'transparent',
            left: this.canvas.width / 2,  // Centrar horizontalmente
            top: this.canvas.height / 2,  // Centrar verticalmente
            angle: 0,
            originX: 'center',
            originY: 'center',
            stroke: this.colorCode.color,             // Color del borde
            strokeWidth: this.tagCode.defaultBorderCode.strokeWidth, // Ancho del borde (puedes ajustar este valor)
            strokeDashArray: this.tagCode.defaultBorderCode.strokeDashArray,
            strokeColor: this.colorCode.color,
            strokeUniform: true, // Mantiene el grosor del borde constante
            data: {
                id: crypto.randomUUID(),
                groupId: this.id,
                type: 'gomTriangle',
                createdAt: this.createdAt,
            }
        })

        const triangle2 = new fabric.Triangle({
            gomType: this.type, // Tipo de objeto
            width: 100,
            height: 100,
            fill: TAG_CODES.SECTOR.defaultColorCode.fill,
            left: this.canvas.width / 2,  // Centrar horizontalmente
            top: (this.canvas.height / 2) + triangle1.height,  // Centrar verticalmente
            angle: this.angle + 180,
            originX: 'center',
            originY: 'center',
            stroke: TAG_CODES.SECTOR.defaultColorCode.color,             // Color del borde
            strokeWidth: TAG_CODES.SECTOR.defaultBorderCode.strokeWidth, // Ancho del borde (puedes ajustar este valor)
            strokeDashArray: TAG_CODES.SECTOR.defaultBorderCode.strokeDashArray,
            strokeColor: TAG_CODES.SECTOR.defaultColorCode.color,
            strokeUniform: true, // Mantiene el grosor del borde constante
            data: {
                id: crypto.randomUUID(),
                groupId: this.id,
                type: 'gomTriangle',
                createdAt: this.createdAt,
                canChangeColor: false,
            }
        })

        return { triangle1, triangle2 }
    }

    _createText = () => {
        // Encuentra los valores mínimos de top y left de todos los drawnPaths
        const minLeft = this.elementObject.left
        const minTop = this.elementObject.top

        // Establecer un tamaño máximo de texto (ancho máximo)
        const maxTextWidth = this.elementObject.width * 0.8 // Usa el 80% del ancho total disponible
        const textObject = new fabric.Textbox(this.defaultText, {
            fontSize: this.colorCode.fontSize,
            fontFamily: this.colorCode.fontFamily,
            //fill: this.withFillColor ? getContrastColor(this.colorCode.fill) : this.colorCode.fill,
            fill: this.colorCode.fill,
            originX: 'center',
            originY: 'center',
            left: minLeft, // Centrado horizontalmente sobre todos los paths
            top: minTop, // Ajustar posición superior            
            width: maxTextWidth, // Ajustar el ancho del cuadro de texto al máximo permitido
            textAlign: 'center', // Alineación central del texto
            editable: true,
            backgroundColor: this.withFillColor ? '#FFFFFF80' : '',
            data: {
                id: crypto.randomUUID(),
                groupId: this.id,
                type: 'gomGroupTitle',
                createdAt: this.createdAt,
            },
        })

        return textObject
    }

    _setColorCode = () => this.colorCode = getColorById(this.colorCodeId)
}

export default GomElement