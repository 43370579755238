import React from 'react'
import { FormattedMessage } from "react-intl"
import { Modal, Button } from "react-bootstrap"
import { BsCheckLg, BsXLg } from 'react-icons/bs'

const ConfirmDeleteModal = ({ show, title = 'Confirm title', text = 'Confirm text', handleClose, handleConfirm, parentNode }) => {
    return (
        <Modal
            container={parentNode}
            show={show}
            onHide={handleClose}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {text}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    <BsXLg size={20} /> <FormattedMessage id="confirmNo" defaultMessage="No" />
                </Button>
                <Button variant="primary" onClick={handleConfirm}>
                    <BsCheckLg size={20} /> <FormattedMessage id="confirmYes" defaultMessage="Yes" />
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ConfirmDeleteModal
