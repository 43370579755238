class GomDBManager {
    constructor(dbName, storeName, version) {
        this.dbName = dbName
        this.storeName = storeName
        this.version = version
    }

    openDatabase = (deleteStore = false) => {
        return new Promise((resolve, reject) => {
            const request = indexedDB.open(this.dbName, this.version)

            request.onerror = () => {
                reject(new Error('Error al abrir la base de datos'))
            }

            request.onsuccess = () => {
                this.db = request.result
                resolve(this.db)
            }

            request.onupgradeneeded = (event) => {
                const db = event.target.result

                // Eliminar el almacén de objetos si se indica
                if (deleteStore && db.objectStoreNames.contains(this.storeName)) {
                    db.deleteObjectStore(this.storeName)
                }

                // Crear almacén de objetos si aún no existe
                if (!db.objectStoreNames.contains(this.storeName)) {
                    db.createObjectStore(this.storeName, { keyPath: 'id' })
                }
            }
        })
    }

    getAllDates = () => {
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction([this.storeName], 'readonly')
            const objectStore = transaction.objectStore(this.storeName)
            const dates = []

            const cursorRequest = objectStore.openCursor()

            cursorRequest.onsuccess = (event) => {
                const cursor = event.target.result
                if (cursor) {
                    dates.push(cursor.value.id) // Solo guardamos los IDs (fechas)
                    cursor.continue()
                } else {
                    resolve(dates.sort((a, b) => a - b)) // Ordenamos por fecha ascendente
                }
            }

            cursorRequest.onerror = () => {
                reject(new Error('Error al obtener las fechas'))
            }
        })
    }

    getAllRecords = () => {
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction([this.storeName], 'readonly')
            const objectStore = transaction.objectStore(this.storeName)
            const records = []

            const cursorRequest = objectStore.openCursor()

            cursorRequest.onsuccess = (event) => {
                const cursor = event.target.result
                if (cursor) {
                    records.push(cursor.value) // Agregar el registro al array
                    cursor.continue() // Continuar con el siguiente registro
                } else {
                    resolve(records) // Resolver la promesa cuando se hayan recorrido todos
                }
            }

            cursorRequest.onerror = () => {
                reject(new Error('Error al obtener los registros'))
            }
        })
    }

    getRecordById = (id) => {
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction([this.storeName], 'readonly')
            const objectStore = transaction.objectStore(this.storeName)
            const request = objectStore.get(id)

            request.onsuccess = (event) => {
                resolve(event.target.result || null) // Retorna el registro o null si no existe
            }

            request.onerror = () => {
                reject(new Error(`Error al obtener el registro con id ${id}`))
            }
        })
    }

    getLastRecord = () => {
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction([this.storeName], 'readonly')
            const objectStore = transaction.objectStore(this.storeName)
            const cursorRequest = objectStore.openCursor(null, 'prev')

            cursorRequest.onsuccess = (event) => {
                const cursor = event.target.result
                if (cursor) {
                    resolve(cursor.value)
                } else {
                    resolve(null)
                }
            }

            cursorRequest.onerror = () => {
                reject(new Error('Error al abrir el cursor'))
            }
        })
    }

    addRecord = (data) => {
        return new Promise((resolve, reject) => {
            const newData = { id: Date.now(), value: data }
            const transaction = this.db.transaction([this.storeName], 'readwrite')
            const objectStore = transaction.objectStore(this.storeName)
            const addRequest = objectStore.add(newData)

            addRequest.onsuccess = () => {
                resolve()
            }

            addRequest.onerror = () => {
                reject(new Error('Error al agregar el objeto'))
            }
        })
    }

    clearAllRecords = () => {
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction([this.storeName], 'readwrite')
            const objectStore = transaction.objectStore(this.storeName)
            const clearRequest = objectStore.clear()

            clearRequest.onsuccess = () => {
                resolve()
            }

            clearRequest.onerror = () => {
                reject(new Error('Error al eliminar los registros'))
            }
        })
    }

    deleteObjectStore = () => {
        return new Promise((resolve, reject) => {
            this.version += 1 // Es necesario cambiar la versión para activar onupgradeneeded
            const request = indexedDB.open(this.dbName, this.version)

            request.onupgradeneeded = (event) => {
                const db = event.target.result

                if (db.objectStoreNames.contains(this.storeName)) {
                    db.deleteObjectStore(this.storeName)
                    resolve()
                } else {
                    reject(new Error('El almacén de objetos no existe'))
                }
            }

            request.onerror = () => {
                reject(new Error('Error al eliminar el almacén de objetos'))
            }
        })
    }

    deleteDatabase = () => {
        return new Promise((resolve, reject) => {
            const request = indexedDB.deleteDatabase(this.dbName)

            request.onsuccess = () => {
                resolve()
            }

            request.onerror = () => {
                reject(new Error('Error al eliminar la base de datos'))
            }
        })
    }
}

export default GomDBManager