import { fabric } from 'fabric'
import DrawFreehandFabric from './drawFreehandFabric'

class DrawFreehandControls {
    constructor(canvas) {
        if (!(canvas instanceof fabric.Canvas)) {
            throw new Error('Canvas debe ser una instancia de fabric.Canvas')
        }
        this.canvas = canvas
        this.drawTool = new DrawFreehandFabric(this.canvas)
        this.isDrawingActive = false
    }

    // Método para iniciar la acción de dibujo a mano alzada
    startDrawFreehand = (tagCode, callBack) => {
        if (this.isDrawingActive) {
            console.warn("El dibujo ya está en progreso.")
            return
        }
        this.drawTool.setDrawMode(true, tagCode) // Activa el modo de dibujo

        // Configuración opcional del lápiz
        this.drawTool.setPencilBrush({
            color: tagCode.defaultColorCode.color,         // Color del lápiz
            width: tagCode.defaultBorderCode.strokeWidth,  // Grosor del trazo
            strokeDashArray: tagCode.defaultBorderCode.strokeDashArray,    // Estilo de trazo punteado
            strokeUniform: true,            // Dibujo uniforme
        })

        this.isDrawingActive = true

        // Llamar al callback si está definido
        if (typeof callBack === 'function') {
            callBack(true)
        }

        this.canvas.on('mouse:dblclick', () => {
            this.drawTool.stopByDoubleClick()
            this.endDrawFreehand(callBack)
        })
    }

    // Método para finalizar la acción de dibujo a mano alzada
    endDrawFreehand = (callBack) => {
        if (!this.isDrawingActive || !this.drawTool) {
            console.warn("No hay un dibujo activo para finalizar.")
            return
        }
        this.canvas.off('mouse:dblclick')
        this.drawTool.setDrawMode(false) // Desactivar el modo de dibujo
        this.isDrawingActive = false

        // Llamar al callback si está definido
        if (typeof callBack === 'function') {
            callBack(false)
        }
    }
}

export default DrawFreehandControls