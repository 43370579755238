import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from "react-intl"
import { ButtonGroup, Button, Popover, OverlayTrigger } from 'react-bootstrap'
import { BsPaletteFill } from 'react-icons/bs'

import { COLOR_CODES, getContrastColor } from '../../config/color.config'

const ColorPicker = ({ tagCode, colorCode, onChange, parentNode }) => {
    const intl = useIntl()
    const colorsCode = Object.values(COLOR_CODES)

    const [show, setShow] = useState(false)
    const [selectedColorCode, setSelectedColorCode] = useState(colorCode)

    useEffect(() => {
        setSelectedColorCode(colorCode)
    }, [colorCode])

    const handleOnChange = (color, border) => {
        onChange(color, border)
    }

    const handleColorChange = (colorCode) => {
        setSelectedColorCode(colorCode)
        handleOnChange(colorCode)
        setShow(false)
    }

    const renderColorPalette = () => {
        if (!selectedColorCode) return
        return (
            <ButtonGroup size='sm' aria-label="Colors Code">
                {
                    colorsCode.map((colorCode, index) => (
                        <Button
                            key={'colors-code-' + index}
                            size='sm'
                            style={{ width: 50, backgroundColor: colorCode.fill, color: getContrastColor(colorCode.fill), borderColor: colorCode.fill }}
                            onClick={() => handleColorChange(colorCode)}
                            title={colorCode.id}
                            active={selectedColorCode.color === colorCode.color ? 'active' : ''}
                        >
                            {colorCode.icon}
                        </Button>
                    ))
                }
            </ButtonGroup>
        )
    }

    const colorPickerPopover = (
        <Popover show={show} id="color-picker-popover" className="p-0 m-0" style={{ width: 'auto', maxWidth: 'none', fontSize: '1.25rem' }}>
            <Popover.Header>
                <BsPaletteFill size={20} className='me-2' /> <FormattedMessage id="colorPickerSelectColor" defaultMessage="Select a color" />
            </Popover.Header>
            <Popover.Body className="p-2 m-1 d-flex justify-content-center align-items-center text-center">
                {renderColorPalette()}
            </Popover.Body>
        </Popover>
    )

    return (
        <>
            {tagCode?.colorControl ? (
                <OverlayTrigger
                    container={parentNode}
                    trigger="click"
                    placement="auto"
                    overlay={colorPickerPopover}
                    show={show}
                    onToggle={(nextShow) => setShow(nextShow)}
                >
                    <Button
                        title={intl.formatMessage({ id:"colorPickerSelectColor", defaultMessage:"Select a color" })}
                        style={{ backgroundColor: selectedColorCode.fill, borderColor: selectedColorCode.fill, color: getContrastColor(selectedColorCode.fill) }}
                    >
                        {selectedColorCode ? (
                            <>
                                {selectedColorCode.icon}
                            </>
                        ) : (
                            <BsPaletteFill />
                        )}
                    </Button>
                </OverlayTrigger>
            ) : (
                <Button
                    title={intl.formatMessage({ id: "colorPickerSetColor", defaultMessage: "Set this color" })}
                    style={{ backgroundColor: selectedColorCode.fill, borderColor: selectedColorCode.fill, color: getContrastColor(selectedColorCode.fill) }}
                    onClick={() => handleColorChange(tagCode.defaultColorCode)}
                >
                    {selectedColorCode.icon}
                </Button>

            )}
        </>
    )
}

export default ColorPicker
