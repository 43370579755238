import React, { useEffect, useState } from 'react'
import { useIntl } from "react-intl"
import { Form, InputGroup, Button, ButtonGroup } from 'react-bootstrap'
import { BsTrashFill, BsUnlock, BsLockFill, BsInputCursorText, BsPersonWalking, BsPersonStanding, BsNodePlus, BsDashSquare, BsInfoCircle, BsPaintBucket } from 'react-icons/bs'

import { getColorById } from '../../config/color.config'
import { BORDER_CODES } from '../../config/border.config'
import { getTagById, TAG_CODES } from '../../config/tag.config'

import ColorPicker from './ColorPicker'
import TagPicker from './TagPicker'
import ElementOffcanvas from '../offcanvas/ElementOffcanvas'
import CommandPostOffcanvas from '../offcanvas/CommandPostOffcanvas'
import ConfirmDeleteModal from '../modal/ConfirmDeleteModal'

const GomElementControl = ({ element, canvasRef, parentNode }) => {
    const intl = useIntl()
    const [isLocked, setIsLocked] = useState(false)
    const [isActive, setIsActive] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [inputValue, setInputValue] = useState('Cargando...')
    const [colorCodeSelected, setColorCodeSelected] = useState(null)
    const [tagCodeSelected, setTagCodeSelected] = useState(null)
    const [isFillColorActive, setIsFillColorActive] = useState(null)
    const [isDrawingLine, setIsDrawingLine] = useState(false)
    const [showOffCanvas, setShowOffCanvas] = useState(false)
    const [showConfirmModal, setShowConfirmModal] = useState(false)

    useEffect(() => {
        if (!element) return
        setIsLocked(element.lockMovementX && element.lockMovementY)
        setIsActive(element._objects ? element._objects[0]?.strokeDashArray?.length === 0 : false)
        setInputValue(element.data.title ? element.data.title : '')
        const tagCode = element.data?.as ? getTagById(element.data?.as) : TAG_CODES.DEFAULT
        setTagCodeSelected(tagCode)
        const colorCode = element.data?.colorCodeId ? getColorById(element.data?.colorCodeId) : canvasRef.lastColorCode
        setColorCodeSelected(colorCode)
        setIsFillColorActive(element.data?.fillActive)
    }, [element])

    const handleDelete = () => setShowConfirmModal(true)
    const confirmDelete = () => {
        canvasRef.deleteSelected()
        setShowConfirmModal(false)
    }
    const confirmClose = () => setShowConfirmModal(false)

    const handleLockToggle = () => {
        const locked = !isLocked
        setIsLocked(locked)

        if (locked) {
            element.set({
                lockMovementX: locked,
                lockMovementY: locked,
                lockRotation: locked,
                lockScalingX: locked,
                lockScalingY: locked,
                lockScalingFlip: locked,
                lockSkewingX: locked,
                lockSkewingY: locked,
                lockUniScaling: locked,
                hasControls: !locked,
                moveCursor: !locked,
                hoverCursor: 'pointer',
            })
        } else {
            element.set({
                lockMovementX: locked,
                lockMovementY: locked,
                lockRotation: locked,
                lockScalingX: locked,
                lockScalingY: locked,
                lockScalingFlip: locked,
                lockSkewingX: locked,
                lockSkewingY: locked,
                lockUniScaling: locked,
                hasControls: !locked,
                moveCursor: !locked,
                hoverCursor: 'move',
            })
        }

        const dateTime = Date.now()
        canvasRef.setElementData(element, { lockedUpdatedAt: dateTime, locked }, dateTime)

        canvasRef.renderAll()
    }

    const handleUnselect = () => {
        canvasRef.discardActiveObject().renderAll()
    }

    const handleEditTitleClick = () => {
        setIsEditing(true) // Permitir edición al hacer doble clic
    }

    const handleEditTitleChange = (e) => {
        setInputValue(e.target.value) // Actualizar el valor del input        
        canvasRef.setGroupTitleValue({
            group: element,
            type: 'gomGroupTitle',
            textValue: e.target.value,
        })
    }

    const handleEditTitleBlur = () => {
        setIsEditing(false) // Salir de la edición al perder el foco
        handleOnTitleChange()
    }

    const handleEditTitleKeyDown = (e) => {
        if (e.key === 'Enter') {
            setIsEditing(false) // Salir de la edición al presionar Enter
            handleOnTitleChange()
        }
    }

    const handleOnTitleChange = () => {
        const dateTime = Date.now()
        canvasRef.setElementData(element, {
            titleUpdatedAt: dateTime,
            title: inputValue,
        }, dateTime)
    }

    const handleColorChange = (colorCode) => {
        setColorCodeSelected(colorCode)
        canvasRef.setLastColorCode(colorCode)

        canvasRef.setGroupColor({
            group: element,
            color: colorCode.fill,
            fillActive: isFillColorActive,
        })

        const dateTime = Date.now()
        canvasRef.setElementData(element, {
            colorUpdatedAt: dateTime,
            colorCodeId: colorCode.id,
        }, dateTime)
    }

    const handleFillColorToggle = () => {
        const fillActive = !isFillColorActive
        const dateTime = Date.now()

        setIsFillColorActive(fillActive)
        canvasRef.setGroupColor({
            group: element,
            color: colorCodeSelected.fill,
            fillActive,
        })

        canvasRef.setElementData(element, {
            fillActiveUpdatedAt: dateTime,
            fillActive
        }, dateTime)
    }

    const handleTagChange = (tagCode) => {
        setTagCodeSelected(tagCode)
        canvasRef.setLastTagCode(tagCode)

        const dateTime = Date.now()
        canvasRef.setElementData(element, {
            asUpdatedAt: dateTime,
            as: tagCode.id,
        }, dateTime)
    }

    const handleActiveToggle = () => {
        const strokeCode = isActive ? BORDER_CODES.EXPECTED : BORDER_CODES.ACTIVE
        setIsActive((prev) => !prev)
        canvasRef.setGroupStrokeDash({
            group: element,
            strokeCode: strokeCode,
            isActive: !isActive,
        })
        canvasRef.setLastBorderCode(strokeCode)

        const dateTime = Date.now()
        canvasRef.setElementData(element, {
            activeUpdatedAt: dateTime,
            isActive: !isActive,
        }, dateTime)
    }

    const handleDrawLine = () => {
        if (!canvasRef) return
        isDrawingLine ? canvasRef.endDrawLine(setIsDrawingLine) : canvasRef.startDrawLine(setIsDrawingLine)
    }

    const handleCloseOffcanvas = () => setShowOffCanvas(false)
    const handleShowOffcanvas = () => setShowOffCanvas(true)

    return (
        <>
            <ButtonGroup>
                <Button
                    variant={"info"}
                    onClick={handleUnselect}
                    title={intl.formatMessage({ id: "controlElementUnselect", defaultMessage: "Unselect element" })}
                >
                    <BsDashSquare />
                </Button>

                {isEditing ? (
                    <InputGroup>
                        <InputGroup.Text><BsInputCursorText /></InputGroup.Text>
                        <Form.Control
                            maxLength={100}
                            type="text"
                            value={inputValue}
                            autoFocus
                            onChange={handleEditTitleChange}
                            onBlur={handleEditTitleBlur}
                            onKeyDown={handleEditTitleKeyDown}
                        />
                    </InputGroup>
                ) : (
                    <Button
                        title={intl.formatMessage({ id: "controlElementEditTitle", defaultMessage: "Edit Title" })}
                        variant={'light'}
                        onClick={handleEditTitleClick} // Iniciar la edición
                    >
                        <BsInputCursorText />
                    </Button>
                )}

                <Button
                    variant={showOffCanvas ? "secondary" : "light"}
                    onClick={handleShowOffcanvas}
                    title={intl.formatMessage({ id: "controlElementInfo", defaultMessage: "Element info" })}
                >
                    <BsInfoCircle />
                </Button>

                {1 !== 1 && // Aquí no muestro TagPicker
                    <TagPicker tagCode={tagCodeSelected} onChange={handleTagChange} parentNode={parentNode} />
                }

                {1 !== 1 && tagCodeSelected && tagCodeSelected.actionControl && (
                    <Button variant="light" onClick={handleDrawLine} title="Add action">
                        <BsNodePlus />
                    </Button>
                )}

                {tagCodeSelected && tagCodeSelected.activeControl && (
                    <Button
                        variant={isActive ? "secondary" : "light"}
                        onClick={handleActiveToggle}
                        title={isActive ? intl.formatMessage({ id: "controlElementActive", defaultMessage: "Active" }) : intl.formatMessage({ id: "controlElementExpected", defaultMessage: "Expected" })}
                    >
                        {isActive ? <BsPersonWalking /> : <BsPersonStanding />}
                    </Button>
                )}

                {tagCodeSelected && tagCodeSelected.paintControl && (
                    <Button
                        variant={isFillColorActive ? "secondary" : "light"}
                        onClick={handleFillColorToggle}
                        title={isFillColorActive ? intl.formatMessage({ id: "controlElementFillActive", defaultMessage: "Fill color active" }) : intl.formatMessage({ id: "controlElementFillTransparent", defaultMessage: "Fill transparent" })}
                    >
                        <BsPaintBucket />
                    </Button>
                )}

                {colorCodeSelected && (
                    <ColorPicker tagCode={tagCodeSelected} colorCode={colorCodeSelected} onChange={handleColorChange} parentNode={parentNode} />
                )}

                <Button
                    variant={isLocked ? "secondary" : "light"}
                    onClick={handleLockToggle}
                    title={isLocked ? intl.formatMessage({ id: "controlElementUnlock", defaultMessage: "Unlock element" }) : intl.formatMessage({ id: "controlElementLock", defaultMessage: "Lock element" })}
                >
                    {isLocked ? <BsLockFill /> : <BsUnlock />}
                </Button>

                <Button
                    variant="light"
                    onClick={handleDelete}
                    title={intl.formatMessage({ id: "controlElementDelete", defaultMessage: "Delete element" })}
                >
                    <BsTrashFill />
                </Button>
            </ButtonGroup>

            {showOffCanvas && (
                element.data.gomType === "resourceCommandPost" ? (
                    <CommandPostOffcanvas
                        show={showOffCanvas}
                        element={element}
                        onHide={handleCloseOffcanvas}
                        parentNode={parentNode}
                        canvasRef={canvasRef}
                    />
                ) : (
                    <ElementOffcanvas
                        show={showOffCanvas}
                        element={element}
                        onHide={handleCloseOffcanvas}
                        parentNode={parentNode}
                        canvasRef={canvasRef}
                    />
                )
            )}

            {showConfirmModal && (
                <ConfirmDeleteModal
                    parentNode={parentNode}
                    show={showConfirmModal}
                    handleClose={confirmClose}
                    handleConfirm={confirmDelete}
                    title={intl.formatMessage({ id: "controlElementDeleteConfirmTitle", defaultMessage: "Delete element?" })}
                    text={intl.formatMessage({ id: "controlElementDeleteConfirmText" }, { elementName: inputValue })}
                />
            )}
        </>
    )
}

export default GomElementControl
