import { TAG_CODES } from "../../tag.config"

const GOM_RESOURCE_OTHER = {
    type: 'path',
    tagCode: TAG_CODES.LAND_ASSET,
    withText: true,
    defaultText: 'Other',
    positionText: 'top', // top - right - center - bottom - left
    fieldsText: ["title"],
    endTextWidthStr: "",
    angle: 0,
    colorCodeId: 'default',
    withFillColor: false,
    schema: {
        "type": 'object',
        "properties": {
            "title": {
                "title": "Text",
                "description": "Write a text",
                "type": "string",
            },
        },
        "required": []
    },
    pathData: `M2.30 33.33 L99.96 33.33 M2.30 33.33 L2.30 100 L99.96 100 L99.96 33.33`,
}

export default GOM_RESOURCE_OTHER