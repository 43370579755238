import React, { useEffect, useState, useRef, useCallback } from 'react'

import SectorControl from '../controls/SectorControl'
import FreeHandControl from '../controls/FreeHandControl'
import GomElementControl from '../controls/GomElementControl'
import TextboxControl from '../controls/TextboxControl'

const ElementMenu = ({ containerRef, element, canvasRef }) => {
    const menuRef = useRef(null)
    const [position, setPosition] = useState({ top: 0, left: 0 })
    const [visible, setVisible] = useState(false)
    const [elementType, setElementType] = useState(null)

    const updateMenuPosition = useCallback(() => {
        if (!canvasRef || !element || !menuRef.current) return
        const zoom = canvasRef.getZoom() || 1
        element.setCoords()
        const { left, top } = element.getBoundingRect()
        // Se usa setTimeout para esperar a que el menú se haya renderizado
        setTimeout(() => {
            setPosition({
                left: left + (element.getScaledWidth() / 2) * zoom - menuRef?.current?.offsetWidth / 2,
                top: top + element.getScaledHeight() * zoom + 10,  // Ajuste con un espaciado de 10px
            })
        }, 0)
    }, [canvasRef, element])

    useEffect(() => {
        if (!element) return
        setElementType(element.data.type)
        setVisible(true)
        updateMenuPosition()

        const handleMoving = (e) => {
            setVisible(true)
            updateMenuPosition()
            canvasRef.handleObjectMoving(e)
        }

        const handleModified = (e) => {
            canvasRef.handleObjectModified(e)
        }

        const handleDeselected = () => {
            setVisible(false)
        }

        element.on('moving', handleMoving)
        element.on('modified', handleModified)
        element.on('deselected', handleDeselected)

        return () => {
            element.off('moving', handleMoving)
            element.off('modified', handleModified)
            element.off('deselected', handleDeselected)
        }
    }, [element, updateMenuPosition, canvasRef])

    const elementControls = {
        sectorGroup: SectorControl,
        drawFreeHand: FreeHandControl,
        gomGroup: GomElementControl,
        textbox: TextboxControl,
    }
    const ControlComponent = elementType ? elementControls[elementType] : null

    return (
        <div ref={menuRef} style={{
            position: 'absolute',
            display: visible ? 'block' : 'none',
            top: `${position.top}px`,
            left: `${position.left}px`,
            backgroundColor: '#fff',
            border: '1px solid #ddd',
            borderRadius: '8px',
            padding: '0px',
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        }}>

            {ControlComponent && <ControlComponent parentNode={containerRef} element={element} canvasRef={canvasRef} />}

        </div>
    )
}

export default ElementMenu
