import { fabric } from 'fabric'
import DrawSectorFabric from './drawSectorFabric'

class DrawSectorControls {
    constructor(canvas) {
        if (!(canvas instanceof fabric.Canvas)) {
            throw new Error('Canvas debe ser una instancia de fabric.Canvas')
        }
        this.canvas = canvas
        this.isDrawingActive = false
        this.DrawSectorFabric = new DrawSectorFabric(this.canvas)
    }

    /**
     * Inicia la acción de dibujo del sector.
     * @param {Function} callBack - Callback opcional al iniciar el dibujo.
     */
    startDrawSector(callBack) {
        if (this.isDrawingActive) {
            console.warn('El dibujo ya está en curso.')
            return
        }

        //this.DrawSectorFabric = new DrawSectorFabric(this.canvas)

        this.canvas.on('mouse:down', this.DrawSectorFabric.drawSectorStart.bind(this.DrawSectorFabric))
        this.canvas.on('mouse:dblclick', () => this.endDrawSector(callBack))

        this.canvas.defaultCursor = 'crosshair'
        this.isDrawingActive = true

        if (typeof callBack === 'function') {
            callBack(this.isDrawingActive)
        }
    }

    /**
     * Finaliza la acción de dibujo del sector.
     * @param {Function} callBack - Callback opcional al finalizar el dibujo.
     */
    endDrawSector(callBack) {
        if (!this.isDrawingActive || !this.DrawSectorFabric) {
            console.warn('No hay un dibujo activo para finalizar.')
            return
        }

        const sectorGroup = this.DrawSectorFabric.drawSectorEnd()
        if (sectorGroup) {
            this.canvas.add(sectorGroup)
            this.canvas.moveTo(sectorGroup, 1)

            sectorGroup.on('selected', (e) => {
                console.log("Sector seleccionado ID:", e.target?.data?.id)
            })

            sectorGroup.on('deselected', (e) => {
                console.log("Sector deseleccionado ID:", e.target?.data?.id)
            })
        }

        this.canvas.off('mouse:down')
        this.canvas.off('mouse:dblclick')

        this.canvas.defaultCursor = 'default'
        this.isDrawingActive = false

        if (typeof callBack === 'function') {
            callBack(this.isDrawingActive)
        }

        this.canvas.renderAll()
        //this.DrawSectorFabric = null
    }
}

export default DrawSectorControls
