import { TAG_CODES } from "../../tag.config"

const GOM_ACTION_ANALYSIS = {
    type: 'path',
    tagCode: TAG_CODES.ACTION,
    withText: true,
    defaultText: 'Analysis action',
    positionText: 'top', // top - right - center - bottom - left
    fieldsText: ["title"],
    endTextWidthStr: "",
    angle: 0,
    colorCodeId: 'fire',
    withFillColor: false,
    schema: {
        "type": 'object',
        "properties": {
            "title": {
                "title": "Text",
                "description": "Write a text",
                "type": "string",
            },
        },
        "required": []
    },
    pathData: `M 4 55 L 30 55 L 40 30 L 60 30 L 70 55 L 100 55 M 100 45 L 115 55 L 100 65 Z`,
}

export default GOM_ACTION_ANALYSIS