import { fabric } from 'fabric'

class ScaleControls {
    constructor(canvas) {
        if (!(canvas instanceof fabric.Canvas)) {
            throw new Error('Canvas debe ser una instancia de fabric.Canvas')
        }
        this.canvas = canvas
        this.scale = 1 // Escala inicial por defecto
    }

    /**
     * Obtiene la escala actual aplicada al canvas.
     * @returns {number} - Valor de la escala.
     */
    getScale() {
        return this.scale
    }

    /**
     * Aplica una escala global a todos los objetos del canvas.
     * @param {number} scale - Factor de escala (1 = 100%).
     */
    setScaleAll(scale) {
        if (typeof scale !== 'number' || scale <= 0) {
            console.error('La escala debe ser un número positivo')
            return
        }

        this.scale = scale

        this.canvas.forEachObject((obj) => {
            if (!obj.data?.localScaled && !obj.data?.grid) {
                obj.scaleX = this.scale
                obj.scaleY = this.scale
            }
        })

        this.canvas.discardActiveObject()
        this.canvas.renderAll()
    }
}

export default ScaleControls
