import { TAG_CODES } from "../../tag.config"

const GOM_POINT_WATER_LIMITED = {
    type: 'path',
    tagCode: TAG_CODES.RESOURCE,
    withText: true,
    defaultText: 'Water limited',
    positionText: 'top', // top - right - center - bottom - left
    fieldsText: ["title"],
    endTextWidthStr: "",
    angle: 0,
    colorCodeId: 'water',
    withFillColor: true,
    schema: {
        "type": 'object',
        "properties": {
            "title": {
                "title": "Text",
                "description": "Write a text",
                "type": "string",
            },
        },
        "required": []
    },
    pathData: `M100 80 A40 40 0 1 1 20 80 M20 80 H100 M20 80 A1 1 0 1 1 100 80 A1 1 0 0 0 20 80`,
}

export default GOM_POINT_WATER_LIMITED