import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Button } from 'react-bootstrap'
import { BsArrowsMove } from 'react-icons/bs'
import DragControls from '../../fabric/controls/dragControls'

const DragControl = ({ canvasRef }) => {
    const intl = useIntl()
    const [isDragging, setIsDragging] = useState(false)

    const handleDragging = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setIsDragging((prev) => !prev) // Toggle el estado
    }

    useEffect(() => {
        if (!canvasRef) return
        const dragControls = new DragControls()
        const addListeners = () => {
            canvasRef.addEvent('mouse:down', dragControls.dragStart)
            canvasRef.addEvent('mouse:move', (e) => dragControls.dragMove(e, canvasRef.relativePan))
            canvasRef.addEvent('mouse:up', dragControls.dragStop)
        }

        const removeListeners = () => {
            canvasRef.removeEvent('mouse:down')
            canvasRef.removeEvent('mouse:move')
            canvasRef.removeEvent('mouse:up')
        }

        if (isDragging) addListeners()
        else removeListeners()

        return () => removeListeners() // Limpieza en caso de desmontar
    }, [canvasRef, isDragging])

    return (
        <Button
            variant={isDragging ? 'primary' : 'light'}
            size="sm"
            onClick={handleDragging}
            title={
                isDragging
                    ? intl.formatMessage({ id: 'controlsDisableDragging', defaultMessage: 'Disable dragging' })
                    : intl.formatMessage({ id: 'controlsEnableDragging', defaultMessage: 'Enable dragging' })
            }
        >
            <BsArrowsMove size={20} />
        </Button>
    )
}

export default DragControl
