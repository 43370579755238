import React from "react"
import { Spinner, Container } from "react-bootstrap"

const SpinnerLoading = () => {
    return (
        <Container className="mt-3 text-center">
            <Spinner animation="border" variant="secondary" />
        </Container>
    )
}

export default SpinnerLoading