import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from "react-intl"
import moment from 'moment'
import { Offcanvas, Form, Row, Col } from 'react-bootstrap'
import { COLOR_CODES, getColorById, getContrastColor } from '../../config/color.config'
import { getTagById } from '../../config/tag.config'
import { BsArrowClockwise, BsBroadcastPin, BsCardText, BsPentagon, BsPersonStanding, BsPersonWalking, BsStopwatch, BsPersonBadge, BsPinMap } from 'react-icons/bs'

const CommandPostOffcanvas = ({ show, element, onHide, parentNode, canvasRef }) => {
    const intl = useIntl()
    const [sector, setSector] = useState(null)
    const [sectors, setSectors] = useState(null)

    const [airAssetChannel, setAirAssetChannel] = useState(element.data.airAssetChannel || '')
    const [assemblyPointChannel, setAssemblyPointChannel] = useState(element.data.assemblyPointChannel || '')
    const [commandChannel, setCommandChannel] = useState(element.data.commandChannel || '')
    const [personInCharge, setPersonInCharge] = useState(element.data.personInCharge || '')
    const [location, setLocation] = useState(element.data.location || '')
    const [description, setDescription] = useState(element.data.description || '')

    // Detectar el idioma del navegador
    const userLanguage = navigator.language || navigator.languages[0]
    moment.locale(userLanguage.startsWith('es') ? 'es' : 'en')
    const dateFormat = userLanguage.startsWith('es') ? 'DD/MM/YYYY HH:mm:ss' : 'MM/DD/YYYY HH:mm:ss'

    const handleInputChange = (setState) => (e) => {
        const value = e.target.value
        setState(value)
    }

    const handleInputBlur = (field, stateValue, originalValue) => () => {
        if (stateValue !== originalValue) {
            const dateTime = Date.now()
            canvasRef.setElementData(element, {
                [`${field}UpdatedAt`]: dateTime,
                [field]: stateValue,
            }, dateTime)
            if (field === 'commandChannel') {
                handleEditCommandChannelUpdateElement(stateValue)
            }
        }
    }

    const handleEditAirAssetChannel = handleInputChange(setAirAssetChannel)
    const handleEditAirAssetChannelBlur = handleInputBlur('airAssetChannel', airAssetChannel, element.data.airAssetChannel)
    const handleEditAssemblyPointChannel = handleInputChange(setAssemblyPointChannel)
    const handleEditAssemblyPointChannelBlur = handleInputBlur('assemblyPointChannel', assemblyPointChannel, element.data.assemblyPointChannel)
    const handleEditCommandChannel = handleInputChange(setCommandChannel)
    const handleEditCommandChannelBlur = handleInputBlur('commandChannel', commandChannel, element.data.commandChannel)
    const handleEditCommandChannelUpdateElement = (value) => {
        sectors.map(obj => {
            const dateTime = Date.now()
            canvasRef.setElementData(obj, {
                commandChannelUpdatedAt: dateTime,
                commandChannel: value,
            }, dateTime)
        })
    }
    const handleEditPersonInCharge = handleInputChange(setPersonInCharge)
    const handleEditPersonInChargeBlur = handleInputBlur('personInCharge', personInCharge, element.data.personInCharge)
    const handleEditLocation = handleInputChange(setLocation)
    const handleEditLocationBlur = handleInputBlur('location', location, element.data.location)
    const handleEditDescription = handleInputChange(setDescription)
    const handleEditDescriptionBlur = handleInputBlur('description', description, element.data.description)

    useEffect(() => {
        if (!canvasRef || !element) return
        const sectorsIn = canvasRef.getObjectById(element.data.sectorId)
        if (sectorsIn && sectorsIn.length > 0) setSector(sectorsIn[0])

        const sectorsAll = canvasRef.getSectors()
        if (sectorsAll && sectorsAll.length > 0) setSectors(sectorsAll)

    }, [element])

    return (
        <Offcanvas
            show={show}
            onHide={onHide}
            placement={'start'}
            container={parentNode}
        >
            <Offcanvas.Header
                closeButton
                style={{ backgroundColor: getColorById(element.data.colorCodeId).color, color: getContrastColor(getColorById(element.data.colorCodeId).color) }}
                closeVariant={getContrastColor(getColorById(element.data.colorCodeId).color)}
            >
                <Offcanvas.Title>
                    {getTagById(element.data.as).icon} {element.data.title ? element.data.title : element.data.id}
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>

                {sector?.data?.title && sector?.data?.title !== '' && (
                    <Form.Floating className="mb-2">
                        <Form.Control
                            id="elementSector"
                            type="text"
                            size={'sm'}
                            value={sector.data.title}
                            disabled
                            style={{
                                borderStyle: 'solid',
                                borderWidth: '3px',
                                borderColor: 'transparent',
                                borderRadius: '10px',
                                borderImage: `repeating-linear-gradient(45deg, ${COLOR_CODES.COMMAND.color} 0%, ${COLOR_CODES.COMMAND.color} 6%, transparent 6%, transparent 8%, ${COLOR_CODES.COMMAND.color} 8%, ${COLOR_CODES.COMMAND.color} 10%, transparent 10%, transparent 12%) 1`,  // Espacio y repetición del patrón                            
                            }}
                        />
                        <label htmlFor='elementSector'>
                            <BsPentagon className='me-1' />
                            <FormattedMessage id={'elementDataSector'} defaultMessage={'Sector'} />
                        </label>
                    </Form.Floating>
                )}

                <Row className='p-0 mb-2'>
                    <Col className='m-0'>
                        <Form.Floating>
                            <Form.Control
                                id="elementCreatedAt"
                                type="text"
                                size={'sm'}
                                value={moment(element.data.createdAt).format(dateFormat)}
                                disabled
                            />
                            <label htmlFor='elementCreatedAt'>
                                <BsStopwatch className='me-1' />
                                <FormattedMessage id={'elementDataCreatedAt'} defaultMessage={'Created on'} />
                            </label>
                        </Form.Floating>
                    </Col>
                    <Col className='m-0'>
                        <Form.Floating>
                            <Form.Control
                                id="elementLastUpdatedAt"
                                type="text"
                                size={'sm'}
                                value={moment(element.data.lastUpdatedAt).format(dateFormat)}
                                disabled
                            />
                            <label htmlFor='elementLastUpdatedAt'>
                                <BsArrowClockwise className='me-1' />
                                <FormattedMessage id={'elementDataLastUpdatedAt'} defaultMessage={'Last modified'} />
                            </label>
                        </Form.Floating>
                    </Col>
                </Row>

                <Row className='p-0 mb-2'>
                    <Col className='m-0'>
                        <Form.Floating>
                            <Form.Control
                                id="sectorAirAssetChannel"
                                type="text"
                                size={'sm'}
                                value={airAssetChannel}
                                onChange={handleEditAirAssetChannel}
                                onBlur={handleEditAirAssetChannelBlur}
                                className='text-end'
                            />
                            <label htmlFor='sectorAirAssetChannel'>
                                <BsBroadcastPin className='me-1' />
                                <FormattedMessage id={'sectorDataAirAssetChannel'} defaultMessage={'Air asset channel'} />
                            </label>
                        </Form.Floating>
                    </Col>
                    <Col className='m-0'>
                        <Form.Floating>
                            <Form.Control
                                id="sectorAssemblyPointChannel"
                                type="text"
                                size={'sm'}
                                value={assemblyPointChannel}
                                onChange={handleEditAssemblyPointChannel}
                                onBlur={handleEditAssemblyPointChannelBlur}
                                className='text-end'
                            />
                            <label htmlFor='sectorAssemblyPointChannel'>
                                <BsBroadcastPin className='me-1' />
                                <FormattedMessage id={'sectorDataAssemblyPointChannel'} defaultMessage={'Assembly point'} />
                            </label>
                        </Form.Floating>
                    </Col>
                </Row>

                <Row className='p-0 mb-2'>
                    <Col className='m-0'>
                        <Form.Floating className="mb-2">
                            <Form.Control
                                id="elementAs"
                                type="text"
                                size={'sm'}
                                value={intl.formatMessage({ id: 'tag' + element.data.as.charAt(0).toUpperCase() + element.data.as.slice(1), defaultMessage: element.data.as })}
                                disabled
                            />
                            <label htmlFor='elementAs'>
                                {getTagById(element.data.as).icon}{' '}
                                <FormattedMessage id={'elementDataType'} defaultMessage={'Type'} />
                            </label>
                        </Form.Floating>
                    </Col>
                    <Col className='m-0'>
                        <Form.Floating>
                            <Form.Control
                                id="sectorCommandChannel"
                                type="text"
                                size={'sm'}
                                value={commandChannel}
                                onChange={handleEditCommandChannel}
                                onBlur={handleEditCommandChannelBlur}
                                className='text-end'
                                style={{ borderColor: COLOR_CODES.COMMAND.color }}
                            />
                            <label htmlFor='sectorCommandChannel'>
                                <BsBroadcastPin className='me-1' />
                                <FormattedMessage id={'sectorDataCommandChannel'} defaultMessage={'Command channel'} />
                            </label>
                        </Form.Floating>
                    </Col>
                </Row>

                <Form.Floating className="mb-2">
                    <Form.Control
                        id="elementStatus"
                        type="text"
                        size={'sm'}
                        value={element.data.isActive ?
                            (intl.formatMessage({ id: 'controlElementActive', defaultMessage: 'Active' }) + ' (' + moment(element.data.activeUpdatedAt).fromNow() + ')')
                            :
                            (intl.formatMessage({ id: 'controlElementExpected', defaultMessage: 'Expected' }) + ' (' + moment(element.data.activeUpdatedAt).fromNow() + ')')
                        }
                        disabled
                        style={{
                            borderStyle: element.data.isActive ? "solid" : "dashed",  // asegurando estilo sólido
                            borderWidth: 3,
                            borderColor: getColorById(element.data.colorCodeId).color,
                            borderRadius: 10,
                        }}
                    />
                    <label htmlFor='elementStatus'>
                        {element.data.isActive ? <BsPersonWalking className='me-1' /> : <BsPersonStanding className='me-1' />}
                        <FormattedMessage id={'elementDataStatus'} defaultMessage={'Status'} />
                    </label>
                </Form.Floating>

                <Form.Floating className="mb-2">
                    <Form.Control
                        id="elementPersonInCharge"
                        type="text"
                        size={'sm'}
                        value={personInCharge}
                        onChange={handleEditPersonInCharge}
                        onBlur={handleEditPersonInChargeBlur}
                    />
                    <label htmlFor='elementPersonInCharge'>
                        <BsPersonBadge className='me-1' />
                        <FormattedMessage id={'elementDataPersonInCharge'} defaultMessage={'Person in charge'} />
                    </label>
                </Form.Floating>

                <Form.Floating className="mb-2">
                    <Form.Control
                        id="elementLocation"
                        type="text"
                        size={'sm'}
                        value={location}
                        onChange={handleEditLocation}
                        onBlur={handleEditLocationBlur}
                    />
                    <label htmlFor='elementLocation'>
                        <BsPinMap className='me-1' />
                        <FormattedMessage id={'elementDataLocation'} defaultMessage={'Location'} />
                    </label>
                </Form.Floating>

                <Form.Floating className="mb-2">
                    <Form.Control
                        id="elementDescription"
                        as="textarea"
                        size={'sm'}
                        style={{ height: '100px' }}
                        value={description || ''}
                        onChange={handleEditDescription}
                        onBlur={handleEditDescriptionBlur}
                    />
                    <label htmlFor='elementDescription'>
                        <BsCardText className='me-1' />
                        <FormattedMessage id={'elementDataDescription'} defaultMessage={'Description'} />
                    </label>
                </Form.Floating>

            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default CommandPostOffcanvas
