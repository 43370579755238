import React, { useState, useEffect } from 'react'
import { FormattedMessage, useIntl } from "react-intl"
import { ButtonGroup, Button } from 'react-bootstrap'
import { BsArrowClockwise, BsClockHistory, BsStopwatch } from 'react-icons/bs'
import moment from 'moment'
import 'moment/locale/es'
import 'moment/locale/en-gb'

const LastUpdateControl = ({ canvasRef, lastUpdated, onTimeLine, selectedRecord }) => {
    const intl = useIntl()
    const [dateTime, setDateTime] = useState(null) // Estado para la fecha y hora
    const [dateTimeStart, setDateTimeStart] = useState(null) // Estado para la fecha y hora

    // Detecta idioma del navegador y cambia a ese idioma
    const userLanguage = navigator.language || navigator.languages[0]
    moment.locale(userLanguage.startsWith('es') ? 'es' : 'en')
    const dateFormat = userLanguage.startsWith('es') ? 'DD/MM/YYYY HH:mm:ss Z' : 'MM/DD/YYYY HH:mm:ss Z'

    // Actualizar fecha y hora cada vez que canvasRef se actualiza
    useEffect(() => {
        if (canvasRef && canvasRef.getLastupdateDateTime) {
            const updateDateTime = () => {
                const lastUpdate = canvasRef.getLastupdateDateTime()
                setDateTime(lastUpdate) // Actualiza el estado 
                setDateTimeStart(moment(canvasRef.getDateTimeStart()).fromNow())
            }

            // Llamar a updateDateTime una vez al cargar
            updateDateTime()

            // Establecer un intervalo para mantener la fecha y hora actualizada si lo necesita
            const interval = setInterval(updateDateTime, 1000) // Actualizar cada 1 segundo
            return () => clearInterval(interval) // Limpiar el intervalo cuando el componente se desmonte
        }
    }, [canvasRef, lastUpdated]) // Re-ejecutar el efecto cada vez que canvasRef cambia

    return (
        <ButtonGroup size='sm' aria-label="Help controls">
            {selectedRecord && (
                <Button
                    title={intl.formatMessage({ id: "controlsLastUpdateTimeLine", defaultMessage: "Time line viewer" })}
                    variant="light"
                    size='sm'
                    className="d-inline-flex align-items-center"
                    onClick={onTimeLine}
                >
                    <BsClockHistory />
                </Button>
            )}
            <Button
                title={intl.formatMessage({ id: "lastUpdateDateTime", defaultMessage: "Last update date time" })}
                variant="light"
                size='sm'
                className="d-inline-flex align-items-center"
                disabled
            >
                <BsArrowClockwise />&nbsp;{dateTime ? moment(dateTime).format(dateFormat) : <FormattedMessage id="loading" defaultMessage="Loading..." />}
            </Button>
            <Button
                title={intl.formatMessage({ id: "startDateTime", defaultMessage: "Start date time" })}
                variant="light"
                size='sm'
                className="d-inline-flex align-items-center"
                disabled
            >
                <BsStopwatch />&nbsp;{dateTimeStart ? dateTimeStart : <FormattedMessage id="loading" defaultMessage="Loading..." />}
            </Button>
        </ButtonGroup>
    )
}

export default LastUpdateControl