import { fabric } from 'fabric'

class FileControls {
    constructor(canvas, dateManager) {
        if (!(canvas instanceof fabric.Canvas)) {
            throw new Error('Canvas debe ser una instancia de fabric.Canvas')
        }
        this.canvas = canvas
        this.dateManager = dateManager // Instancia para gestionar fechas DateControls()
    }

    /**
     * Exporta el canvas como imagen (PNG o JPG).
     * @param {Object} options - Opciones de exportación.
     * @param {string} [options.imageFormat='png'] - Formato de la imagen ('png' o 'jpeg').
     * @param {number} [options.imageQuality=1] - Calidad de la imagen (solo para 'jpeg', de 0 a 1).
     * @param {number} [options.multiplier=3] - Factor de resolución (por defecto 3).
     */
    getCanvasImage({ format = 'png', quality = 1, multiplier = 3 } = {}) {
        const dataUrl = this.canvas.toDataURL({
            format: format,
            quality: quality,
            multiplier: multiplier,
        })

        this._downloadFile(dataUrl, `canvas_image_${this.dateManager.getLastUpdateDateTimeFileFormat()}.${format}`)
    }

    /**
     * Exporta el canvas en formato JSON.
     */
    getCanvasJSON() {
        const jsonString = JSON.stringify(this.toJSON())
        const blob = new Blob([jsonString], { type: 'application/json' })

        this._downloadFile(URL.createObjectURL(blob), `canvas_${this.dateManager.getLastUpdateDateTimeFileFormat()}.json`)
    }

    /**
     * Convierte el canvas a formato JSON, incluyendo propiedades personalizadas.
     * @returns {Object} - Objeto JSON del canvas.
     */
    toJSON() {
        const json = this.canvas.toJSON(['locationName', 'mapBounds', 'data', 'gomElement', 'lastUpdatedAt', 'createdAt'])
        json.objects = json.objects.filter(obj => obj.data?.grid !== true)
        return json
    }

    /**
     * Carga un canvas desde un objeto JSON.
     * @param {Object} jsonObject - Datos JSON del canvas.
     */
    setFromJSON = (jsonObject, callBack) => {
        this.canvas.loadFromJSON(jsonObject, () => {
            this.canvas.getObjects().forEach((object) => {
                if (object.data?.type === 'sectorGroup' || object.data?.locked) {
                    object.set({
                        lockMovementX: true,
                        lockMovementY: true,
                        lockRotation: true,
                        lockScalingX: true,
                        lockScalingY: true,
                        lockScalingFlip: true,
                        lockSkewingX: true,
                        lockSkewingY: true,
                        lockUniScaling: true,
                        evented: true,
                        selectable: true,
                        hasControls: false,
                        moveCursor: false,
                        hoverCursor: 'pointer',
                    })
                }
            })

            this.canvas.renderAll() // Refresca el canvas después de aplicar cambios
            callBack()
        })
    }

    /**
     * Descarga un archivo generado (imagen o JSON).
     * @param {string} fileUrl - URL del archivo.
     * @param {string} fileName - Nombre del archivo.
     * @private
     */
    _downloadFile(fileUrl, fileName) {
        const link = document.createElement('a')
        link.href = fileUrl
        link.download = fileName
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        URL.revokeObjectURL(fileUrl)
    }
}

export default FileControls
