import React, { useState, useEffect, useRef } from "react"
import { Form, Container } from "react-bootstrap"
import moment from 'moment'
import 'moment/locale/es'
import 'moment/locale/en-gb'
import { BsClockHistory } from "react-icons/bs"

const TimelineBar = ({ value, dates, onSelect }) => {
    // Obtener el índice de `value` en `dates`
    const getIndex = () => dates.findIndex((date) => date === value)

    const [selectedIndex, setSelectedIndex] = useState(getIndex)

    const rangeRef = useRef(null)

    // Autoenfocar el control deslizante
    useEffect(() => {
        if (rangeRef.current) {
            rangeRef.current.focus()
        }
    }, [])

    // Detectar el idioma del navegador
    const userLanguage = navigator.language || navigator.languages[0]
    moment.locale(userLanguage.startsWith('es') ? 'es' : 'en')
    const dateFormat = userLanguage.startsWith('es') ? 'DD/MM/YYYY HH:mm:ss' : 'MM/DD/YYYY HH:mm:ss'

    // Actualizar `selectedIndex` cuando `value` cambie
    useEffect(() => {
        setSelectedIndex(getIndex())
    }, [value, dates])

    const handleSelect = (event) => {
        const index = parseInt(event.target.value, 10)
        setSelectedIndex(index)
        onSelect(dates[index])
    }

    return (
        <Container className="mt-3 text-center">
            <Form.Label className="rounded shadow p-1">
                <BsClockHistory /> {dates.length > 0 ? moment(dates[selectedIndex]).format(dateFormat) : 'N/A'}
            </Form.Label>
            <Form.Range
                className="rounded shadow p-1"
                ref={rangeRef}
                min={0}
                max={dates.length - 1}
                value={selectedIndex} // Se sincroniza con selectedIndex
                onChange={handleSelect}
                disabled={dates.length === 0} // Deshabilitar si no hay datos
            />
        </Container>
    )
}

export default TimelineBar