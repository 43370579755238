export const BORDER_CODES = {
    ACTIVE: {
        id: 'active',
        strokeWidth: 2,
        strokeDashArray: [],
    },
    EXPECTED: {
        id: 'expected',
        strokeWidth: 2,
        strokeDashArray: [5, 3],
    },
    SECTOR: {
        id: 'sector',
        strokeWidth: 2,
        strokeDashArray: [10, 3, 2, 3],
    },
    NOBORDER: {
        id: 'noNorder',
        strokeWidth: 0,
        strokeDashArray: [],
        strokeColor: 'transparent',
    }
}

export const getBorderById = (id) => Object.values(BORDER_CODES).find(border => border.id === id)

export const ActiveBorderSVG = ({ strokeColor = '#000000' }) => (
    <svg
        width={20}
        height={20}
        viewBox="0 0 20 20"
        style={{
            fill: 'transparent',
            stroke: strokeColor,
            width: 20,
            height: 20,
        }}
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
    >
        <rect height="20" width="20" strokeWidth="3" strokeDasharray="" />
    </svg>
)

export const ExpectedBorderSVG = ({ strokeColor = '#000000' }) => (
    <svg
        width={20}
        height={20}
        viewBox="0 0 20 20"
        style={{
            fill: 'transparent',
            stroke: strokeColor,
            width: 20,
            height: 20,
        }}
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
    >
        <rect height="20" width="20" strokeWidth="3" strokeDasharray="5 3" />
    </svg>
)

export const SectorBorderSVG = ({ strokeColor = '#000000' }) => (
    <svg
        width={20}
        height={20}
        viewBox="0 0 20 20"
        style={{
            fill: 'transparent',
            stroke: strokeColor,
            width: 20,
            height: 20,
        }}
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
    >
        <rect height="20" width="20" strokeWidth="3" strokeDasharray="6 3 2 3" />
    </svg>
)

export const NoBorderSVG = ({ strokeColor = '#000000' }) => (
    <svg
        width={20}
        height={20}
        viewBox="0 0 20 20"
        style={{
            fill: "transparent",
            stroke: strokeColor, // Puedes ajustar el color aquí
            width: 20,
            height: 20,
        }}
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
    >
        <rect height="20" width="20" strokeWidth="3" />

        {/* Línea diagonal de izquierda a derecha */}
        <line x1="0" y1="0" x2="20" y2="20" strokeWidth="2" />

        {/* Línea diagonal de derecha a izquierda */}
        <line x1="0" y1="20" x2="20" y2="0" strokeWidth="2" />
    </svg>
)