import { TAG_CODES } from "../../tag.config"

const GOM_RESOURCE_COLUMN = {
    type: 'path',
    tagCode: TAG_CODES.LAND_ASSET,
    withText: true,
    defaultText: 'Column',
    positionText: 'top', // top - right - center - bottom - left
    fieldsText: ["title"],
    endTextWidthStr: "",
    angle: 0,
    colorCodeId: 'fire',
    withFillColor: false,
    schema: {
        "type": 'object',
        "properties": {
            "title": {
                "title": "Text",
                "description": "Write a text",
                "type": "string",
            },
        },
        "required": []
    },
    pathData: `M 2.35 33.33 L 102.35 33.33 M 32.35 0 L 32.35 33.33 M 53.00 0 L 53.00 33.33 M 74.00 0 L 74.00 33.33 M 2.35 33.33 L 2.35 100 L 102.35 100 L 102.35 33.33`,
}

export default GOM_RESOURCE_COLUMN