import React, { useState } from 'react'

import useGomDB from './gomcanvas/hooks/useGomDB'
import GomCanvas from './gomcanvas/components/canvas/GomCanvas'
import CanvasTimeLineModal from './gomcanvas/components/modal/CanvasTimeLineModal'

const App = () => {
  const { recordsDates, lastRecord, selectedRecord, updateDate, addRecord, fetchRecord, fetchDates, clearAllRecords, deleteDatabase } = useGomDB()

  const [canvasContainer, setCanvasContainer] = useState(null)
  const [lastUpdated, setLastUpdated] = useState(null)
  const [locationName, setLocationName] = useState(null)
  const [timeLineActive, setTimeLineActive] = useState(false)

  const handleOnOpen = (container) => {
    setCanvasContainer(container)
  }

  const handleOnChange = async (gomCanvas) => {
    if (!gomCanvas) {
      console.warn("Canvas no está definido. No se puede manejar.");
      return
    }
    try {
      let jsonData = gomCanvas.toJSON()

      // Verificar que 'objects' existe antes de filtrar
      if (jsonData.objects) {
        jsonData.objects = jsonData.objects.filter(obj => obj.data?.grid !== true)
      }

      await addRecord(jsonData) // Guardar los datos filtrados
      setLastUpdated(gomCanvas.getLastupdateDateTime()) // Actualizar la fecha
      setLocationName(gomCanvas.getLocationName()) // Actualizar el nombre de ubicación
    } catch (error) {
      console.error("Error en handleOnChange:", error)
    }
  }

  const handleOnClear = (canvas) => {
    if (!canvas) {
      console.warn("Canvas no está definido. No se puede limpiar.");
      return
    }

    clearAllRecords?.() // Borra los registros si la función existe
    canvas.deleteAll?.() // Borra todos los objetos del canvas si el método existe
    setLocationName(canvas.getLocationName?.() || "") // Previene errores si `getLocationName` no está disponible
    canvas.renderAll?.() // Refresca el canvas si el método está disponible
  }

  const handleGetRecord = (date) => {
    fetchRecord(date)
  }

  const toggleTimeLine = () => {
    setTimeLineActive(prevState => !prevState)
  }

  return (
    <>
      <GomCanvas
        onOpen={handleOnOpen}
        onChange={handleOnChange}
        onClear={handleOnClear}
        lastUpdated={lastUpdated}
        locationName={locationName}
        lastCanvas={lastRecord?.value}  // Con este valor hace el primer setJSON a la carga inicial
        onTimeLine={toggleTimeLine}
        selectedRecord={selectedRecord}
      />
      {timeLineActive && (
        <CanvasTimeLineModal
          container={canvasContainer}
          locationName={locationName}
          selectedRecord={selectedRecord}
          dates={recordsDates}
          onClose={toggleTimeLine}
          fetchDates={fetchDates}
          fetchRecord={handleGetRecord}
        />
      )}
    </>
  )
}

export default App