import React from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import LogoControl from '../controls/LogoControl'
import LocationControl from '../controls/LocationControl'
import ExportControl from '../controls/ImportExportControl'
import UserControl from '../controls/UserControl'
import SitacControl from '../controls/SitacControl'

const HomeMenu = ({ containerRef, canvasRef, locationName, onClear }) => {

    const handleNewSITAC = () => {
        onClear()
    }

    return (
        <Navbar
            container={containerRef}
            expand="lg"
            className="mb-3 p-1"
            bg='white'
            variant='light'
            style={{
                position: 'absolute',
                top: '10px',
                left: '10px',
                border: '1px solid #ddd', // Borde gris
                borderRadius: '4px',     // Bordes redondeados    
                padding: '0px',
                zIndex: 20,
            }}
        >
            {/* Brand or Logo */}
            <Navbar.Brand>
                <LogoControl parentNode={containerRef} />
            </Navbar.Brand>

            {/* Toggler for hamburger */}
            <Navbar.Toggle aria-controls="menu-home" />

            {/* Collapsible Menu */}
            <Navbar.Collapse id="menu-home" className='bg-light text-dark'>
                <Nav className="me-auto">
                    {/* Menu Items */}
                    <Nav.Item>
                        <LocationControl canvasRef={canvasRef} locationName={locationName} />
                    </Nav.Item>
                    <Nav.Item>
                        <SitacControl parentNode={containerRef} onAdd={handleNewSITAC} locationName={locationName} />
                    </Nav.Item>
                    <Nav.Item>
                        <ExportControl canvasRef={canvasRef} />
                    </Nav.Item>
                    <Nav.Item>
                        <UserControl parentNode={containerRef} />
                    </Nav.Item>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default HomeMenu
