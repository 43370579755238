import React, { useState } from 'react'
import { useIntl } from "react-intl"
import { Button } from 'react-bootstrap'
import { BsPencil, BsPencilFill } from 'react-icons/bs'

const DrawFreeHandControl = ({ canvasRef }) => {
    const intl = useIntl()

    const [isDrawing, setIsDrawing] = useState(false)

    const onChange = (isDrawing) => {
        canvasRef.setIsDrawing(isDrawing)
        setIsDrawing(isDrawing)
        if (!isDrawing) canvasRef.setLastUpdateDateTime()  // Actualiza la fecha de actualización del canvas al finalizar el dibujo
    }

    const DrawFreeHandControl = () => {
        if (canvasRef) {
            if (!isDrawing && !canvasRef.isDrawing()) {
                canvasRef.startDrawFreehand(onChange)
            } else {
                canvasRef.endDrawFreehand(onChange)
            }
        }
    }

    return (
        <Button
            variant={isDrawing ? 'primary' : 'light'}
            onClick={DrawFreeHandControl}
            title={intl.formatMessage({ id: "controlsDrawFreeHand", defaultMessage: "Draw freehand" })}
        >
            {isDrawing ? (
                <BsPencilFill size={20} />
            ) : (
                <BsPencil size={20} />
            )}
        </Button>
    )
}

export default DrawFreeHandControl
