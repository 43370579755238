import React, { useEffect, useState, useRef } from 'react'
import { useIntl } from "react-intl"
import { fabric } from 'fabric'
import { Button } from 'react-bootstrap'
import TimelineBar from '../bar/TimeLineBar'
import SpinnerLoading from '../spinner/SpinnerLoading'
import { BsArrowLeftSquare } from 'react-icons/bs'

const GomCanvasViewer = ({
    record,
    locationName,
    onClose,
    timeLineDates,
    fetchDates,
    fetchRecord,
}) => {
    const intl = useIntl()

    const containerRef = useRef(null)
    const canvasRef = useRef(null)
    const canvasInstance = useRef(null)

    const [canvasSize, setCanvasSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    })

    const handleClose = () => {
        onClose()
    }

    // Actualizar tamaño del canvas cuando se cambia el tamaño de la ventana
    useEffect(() => {
        const handleResize = () => {
            const newWidth = window.innerWidth
            const newHeight = window.innerHeight
            setCanvasSize({
                width: newWidth,
                height: newHeight
            })

            // Redimensionar el canvas en fabric.js
            if (canvasInstance.current) {
                canvasInstance.current.setWidth(newWidth)
                canvasInstance.current.setHeight(newHeight)
                canvasInstance.current.renderAll()
            }
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    // Inicialización del canvas solo una vez
    useEffect(() => {
        if (canvasRef.current && !canvasInstance.current) {
            canvasInstance.current = new fabric.Canvas(canvasRef.current, {
                width: canvasSize.width,
                height: canvasSize.height,
            })
        }
    }, [canvasSize])

    // Actualizar el canvas con el nuevo record cuando cambie
    useEffect(() => {
        if (record && canvasInstance.current) {
            canvasInstance.current.loadFromJSON(record.value, () => {
                canvasInstance.current.renderAll()
            }, (o, object) => {
                object.selectable = false
                object.evented = false
                object.hasControls = false
                object.lockMovementX = true
                object.lockMovementY = true
                object.lockScalingX = true
                object.lockScalingY = true
                object.lockRotation = true
            })
        }
    }, [record])

    const handleFechtTimeLineDateCanvas = async (date) => {
        await fetchRecord(date)
    }

    // Solo obtener las fechas de la línea de tiempo una vez
    useEffect(() => {
        if (timeLineDates && timeLineDates.length === 0) {
            fetchDates()
        }
    }, [fetchDates, timeLineDates])

    return (
        <div
            ref={containerRef}
            style={{
                position: 'relative',
                width: `${canvasSize.width}px`,
                height: `${canvasSize.height}px`,
                border: '0px',
            }}
        >
            <canvas
                ref={canvasRef}
                style={{ display: 'block', margin: 0, padding: 0 }}
            />

            <div style={{ position: 'absolute', top: 10, left: 10 }} className='rounded shadow bg-warning p-2 d-flex align-items-center'>
                <Button
                    variant='secondary'
                    size='lg'
                    onClick={handleClose}
                    title={intl.formatMessage({ id: "canvasViewerBack", defaultMessage: "Back" })}
                    className="d-flex align-items-center justify-content-center"
                >
                    <BsArrowLeftSquare size={20} /> 
                </Button>
                <img src="/logo_gom_fire_trans_sm.png" height="24" className='ms-3 me-3' /> {locationName}
            </div>

            <div style={{ position: 'absolute', top: 10, right: 10 }} className="p-0 m-0 rounded shadow">
                {record?.id && timeLineDates && timeLineDates.length > 0 ? (
                    <TimelineBar value={record.id} dates={timeLineDates} onSelect={handleFechtTimeLineDateCanvas} />
                ) : (
                    <SpinnerLoading />
                )}
            </div>

        </div>
    )
}

export default GomCanvasViewer
