import GOM_ACTION_LOCATED from './gomActionLocated'
import GOM_ACTION_ANALYSIS from './gomActionAnalysis'
import GOM_ACTION_PLANNING from './gomActionPlanning'
import GOM_ACTION_WHEEL from './gomActionWheel'
import GOM_ACTION_SIMULTANEOUS from './gomActionSimultaneous'
import GOM_ACTION_DEFENSIVE_LINE from './gomActionDefensiveLine'
import GOM_ACTION_DEFENSIVE_PERIMETER from './gomActionDefensivePerimeter'
import GOM_ACTION_AERIAL_OFFENSIVE from './gomActionAerialOffensive'

import GOM_RESOURCE_COLUMN from './gomResourceColumn'
import GOM_RESOURCE_BRIGADE from './gomResourceBrigade'
import GOM_RESOURCE_GROUP from './gomResourceGroup'
import GOM_RESOURCE_OTHER from './gomResourceOther'
import GOM_RESOURCE_COMMAND_POST from './gomResourceCommandPost'
import GOM_RESOURCE_TRANSIT_POINT from './gomResourceTransitPoint'
import GOM_RESOURCE_LOGISTIC from './gomResourceLogistic'
import GOM_RESOURCE_AIR from './gomResourceAircraft'


import GOM_INCIDENT_ZONE from './gomIncidentZone'
import GOM_INCIDENT_FOCUS from './gomIncidentFocus'
import GOM_INCIDENT_CENTER from './gomIncidentCenter'

import GOM_POINT_SENSITIVE_TARGET from './gomPointSensitiveTarget'
import GOM_POINT_DANGER_SOURCE from './gomPointDangerSource'
import GOM_POINT_WATER_AERIAL from './gomPointWaterAerial'
import GOM_POINT_WATER_UNLIMITED from './gomPointWaterUnlimited'
import GOM_POINT_WATER_LIMITED from './gomPointWaterLimited'

import GOM_TRAFFIC_ACCESS from './gomTrafficAccess'
import GOM_TRAFFIC_BYPASS from './gomTrafficBypass'
import GOM_TRAFFIC_DIRECTION from './gomTrafficDirection'
import GOM_TRAFFIC_EVOLUTION from './gomTrafficEvolution'
import GOM_TRAFFIC_INDICATION from './gomTrafficIndication'
import GOM_TRAFFIC_NO_ENTRY from './gomTrafficNoEntry'

import GOM_DATA_METEO_TEMPERATURE from './gomMeteoTemperature'
import GOM_DATA_METEO_WIND from './gomMeteoWind'
import GOM_DATA_GEO_COMPASS from './gomGeoCompass'
import GOM_DATA_GEO_SLOPE_GRADIENT from './gomGeoSlopeGradient'
import GOM_DATA_GEO_ELEVATION from './gomGeoElevation'

import GOM_SECTOR_FUNCTIONAL from './gomSectorFunctional'

const GOM_CHART = {
  action: {
    analysis: GOM_ACTION_ANALYSIS,
    aerialOffensive: GOM_ACTION_AERIAL_OFFENSIVE,
    defensiveLine: GOM_ACTION_DEFENSIVE_LINE,
    defensivePerimeter: GOM_ACTION_DEFENSIVE_PERIMETER,
    located: GOM_ACTION_LOCATED,
    planning: GOM_ACTION_PLANNING,
    simultaneous: GOM_ACTION_SIMULTANEOUS,
    wheel: GOM_ACTION_WHEEL,
  },
  data: {
    geo: {
      compass: GOM_DATA_GEO_COMPASS,
      elevation: GOM_DATA_GEO_ELEVATION,
      slopeGradient: GOM_DATA_GEO_SLOPE_GRADIENT,
    },
    meteo: {
      temperature: GOM_DATA_METEO_TEMPERATURE,
      wind: GOM_DATA_METEO_WIND,
    },
  },
  incident: {
    center: GOM_INCIDENT_CENTER,
    focus: GOM_INCIDENT_FOCUS,
    zone: GOM_INCIDENT_ZONE,
  },
  point: {
    dangerSource: GOM_POINT_DANGER_SOURCE,
    sensitiveTarget: GOM_POINT_SENSITIVE_TARGET,
    waterAerial: GOM_POINT_WATER_AERIAL,
    waterLimited: GOM_POINT_WATER_LIMITED,
    waterUnlimited: GOM_POINT_WATER_UNLIMITED,
  },
  resource: {
    air: GOM_RESOURCE_AIR,
    brigade: GOM_RESOURCE_BRIGADE,
    column: GOM_RESOURCE_COLUMN,
    commandPost: GOM_RESOURCE_COMMAND_POST,
    group: GOM_RESOURCE_GROUP,
    logistic: GOM_RESOURCE_LOGISTIC,
    other: GOM_RESOURCE_OTHER,
    transitPoint: GOM_RESOURCE_TRANSIT_POINT,
  },
  sector: {
    functional: GOM_SECTOR_FUNCTIONAL,
  },
  traffic: {
    access: GOM_TRAFFIC_ACCESS,
    bypass: GOM_TRAFFIC_BYPASS,
    direction: GOM_TRAFFIC_DIRECTION,
    evolution: GOM_TRAFFIC_EVOLUTION,
    indication: GOM_TRAFFIC_INDICATION,
    noEntry: GOM_TRAFFIC_NO_ENTRY,
  },
}

export default GOM_CHART