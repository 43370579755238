import React, { useEffect, useState } from 'react'
import { useIntl } from "react-intl"
import { Button, ButtonGroup } from 'react-bootstrap'
import { BsTrashFill, BsUnlock, BsLockFill, BsDashSquare } from 'react-icons/bs'

import { getColorById } from '../../config/color.config'
import { getTagById, TAG_CODES } from '../../config/tag.config'

import ColorPicker from './ColorPicker'

const TextboxControl = ({ element, canvasRef, parentNode }) => {
    const intl = useIntl()
    const [isLocked, setIsLocked] = useState(false)
    const [colorCodeSelected, setColorCodeSelected] = useState(null)
    const [tagCodeSelected, setTagCodeSelected] = useState(null)

    useEffect(() => {
        if (!element) return
        setIsLocked(element.lockMovementX && element.lockMovementY)
        const tagCode = element.data?.as ? getTagById(element.data?.as) : TAG_CODES.DEFAULT
        setTagCodeSelected(tagCode)
        const colorCode = element.data?.colorCodeId ? getColorById(element.data?.colorCodeId) : canvasRef.lastColorCode
        setColorCodeSelected(colorCode)
    }, [element])

    const handleDelete = () => canvasRef.deleteSelected()

    const handleLockToggle = () => {
        const locked = !isLocked
        setIsLocked(locked)

        element.set({
            lockMovementX: locked,
            lockMovementY: locked,
            lockScalingX: locked,
            lockScalingY: locked,
            lockRotation: locked,
        })

        const dateTime = Date.now()
        canvasRef.setElementData(element, { lockedUpdatedAt: dateTime, locked }, dateTime)

        canvasRef.renderAll()
    }


    const handleUnselect = () => {
        canvasRef.discardActiveObject().renderAll()
    }

    const handleColorChange = (colorCode) => {
        setColorCodeSelected(colorCode)
        canvasRef.setLastColorCode(colorCode)
        element.set({
            fill: colorCode.fill,
        })

        const dateTime = Date.now()
        canvasRef.setElementData(element, {
            colorUpdatedAt: dateTime,
            colorCodeId: colorCode.id,
        }, dateTime)

        canvasRef.renderAll()
    }

    return (
        <ButtonGroup>
            <Button variant={"info"} onClick={handleUnselect} title="Unselect element">
                <BsDashSquare />
            </Button>

            {colorCodeSelected && (
                <ColorPicker tagCode={tagCodeSelected} colorCode={colorCodeSelected} onChange={handleColorChange} parentNode={parentNode} />
            )}

            <Button variant={isLocked ? "secondary" : "light"} onClick={handleLockToggle} title="Lock element">
                {isLocked ? <BsLockFill /> : <BsUnlock />}
            </Button>

            <Button variant="light" onClick={handleDelete} title="Delete element">
                <BsTrashFill />
            </Button>
        </ButtonGroup>
    )
}

export default TextboxControl
