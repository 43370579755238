import { TAG_CODES } from "../../tag.config"

const GOM_RESOURCE_BRIGADE = {
    type: 'path',
    tagCode: TAG_CODES.LAND_ASSET,
    withText: true,
    defaultText: 'Brigade',
    positionText: 'top', // top - right - center - bottom - left
    fieldsText: ["title"],
    endTextWidthStr: "",
    angle: 0,
    colorCodeId: 'fire',
    withFillColor: false,
    schema: {
        "type": 'object',
        "properties": {
            "title": {
                "title": "Text",
                "description": "Write a text",
                "type": "string",
            },
        },
        "required": []
    },
    pathData: `M 2.30 33.33 L 99.96 33.33 M 48.83 0 L 48.83 33.33 M 2.30 33.33 L 2.30 100 L 99.96 100 L 99.96 33.33`,
}

export default GOM_RESOURCE_BRIGADE