import React from 'react'
import ControlsMenu from './ControlsMenu'
import LastUpdateControl from '../controls/LastUpdateControl'

const InfoMenu = ({ containerRef, canvasRef, lastUpdated, onTimeLine, selectedRecord }) => {
    return (
        <ControlsMenu
            buttons={[
                <LastUpdateControl
                    canvasRef={canvasRef}
                    lastUpdated={lastUpdated}
                    onTimeLine={onTimeLine}
                    selectedRecord={selectedRecord}
                />,
            ]}
            position={{
                top: '10px',
                right: '10px'
            }}
        />
    )
}

export default InfoMenu