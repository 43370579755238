import React from 'react'
import { FormattedMessage, useIntl } from "react-intl"
import { Modal, Image } from 'react-bootstrap'

import moment from 'moment'
import { BiCopyright } from 'react-icons/bi'

const CopyModal = ({ title = '', show, onClose, parentNode }) => {
    const intl = useIntl()

    const handleOnClose = () => {
        onClose()
    }

    return (
        <Modal
            show={show}
            size="lg"
            centered
            onHide={handleOnClose}
            //backdrop="static"
            //keyboard={false}
            container={parentNode} // Contexto según fullscreen
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <BiCopyright /> {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: '#F9CF35' }}>
                <p className='text-center'><Image src='/logo_gom_fire_trans.png' width={200} /></p>
                <p className='text-center'><FormattedMessage id="copyrightSubtitle" defaultMessage="GOM Fire - Operations Management and Command for Fire Services" /></p>
                <p style={{ textAlign: 'center', fontStyle: 'italic' }}><FormattedMessage id="copyrightVersionNumber" defaultMessage="Version: 2.0Beta" /></p>
                <p className='text-center'><FormattedMessage id="copyrightDevelopcolaboration" defaultMessage="Desarrollado con la colaboración de:" /></p>
                <p className='text-center'><Image src='/logo_aptb.png' width={200} /></p>
            </Modal.Body>
            <Modal.Footer>
                <BiCopyright />{moment().year()} <FormattedMessage id="copyrightTitle" defaultMessage="GOM Fire - CIVILIO Information Systems s.l." />
            </Modal.Footer>
        </Modal>
    )
}

export default CopyModal