import React from 'react'
import { FormattedMessage, useIntl } from "react-intl"
import { NavDropdown } from 'react-bootstrap'
import { BsPersonExclamation, BsDoorOpen, BsPersonPlus } from 'react-icons/bs'

const UserControl = ({ parentNode }) => {
    const intl = useIntl()

    return (
        <NavDropdown
            menuVariant='light'
            aria-label={intl.formatMessage({ id: "userControls", defaultMessage: "User controls" })}
            title={
                <>
                    <span className="d-none d-lg-inline-flex align-items-center">
                        <BsPersonExclamation size={20} title={intl.formatMessage({ id: "userSessionManagement", defaultMessage: "User session management" })} />
                    </span>
                    <span className="d-block d-lg-none d-inline-flex align-items-center">
                        <BsPersonExclamation size={20} title={intl.formatMessage({ id: "userSessionManagement", defaultMessage: "User session management" })} />&nbsp;<FormattedMessage id="user" defaultMessage="User" />
                    </span>
                </>
            }
        >
            <NavDropdown.Item
                className="d-flex align-items-center"
                title={intl.formatMessage({ id: "exportCanvasImageToPng", defaultMessage: "Export canvas to image in PNG format" })}
                disabled
            >
                <BsDoorOpen />&nbsp;<FormattedMessage id="userLogin" defaultMessage="Login" />
            </NavDropdown.Item>
            <NavDropdown.Item
                className="d-flex align-items-center"
                title={intl.formatMessage({ id: "exportCanvasAsciiToJson", defaultMessage: "Export canvas to ascii file in JSON format" })}
                disabled
            >
                <BsPersonPlus />&nbsp;<FormattedMessage id="userCreateAccount" defaultMessage="Create an account" />
            </NavDropdown.Item>
        </NavDropdown>
    )
}

export default UserControl
