import { TAG_CODES } from "../../tag.config"

const GOM_DATA_GEO_ELEVATION = {
    type: 'path',
    tagCode: TAG_CODES.DATA,
    withText: true,
    defaultText: 'Slope',
    positionText: 'top', // top - right - center - bottom - left
    fieldsText: ["title"],
    endTextWidthStr: "",
    angle: 0,
    colorCodeId: 'default',
    withFillColor: true,
    schema: {
        "type": 'object',
        "properties": {
            "title": {
                "title": "Text",
                "description": "Write a text",
                "type": "string",
            },
        },
        "required": []
    },
    pathData: `M3 3 16 3 16 15 3 15Z`,
}

export default GOM_DATA_GEO_ELEVATION