import { TAG_CODES } from "../../tag.config"

const GOM_TRAFFIC_ACCESS = {
    type: 'path',
    tagCode: TAG_CODES.ROAD,
    withText: true,
    defaultText: 'Access',
    positionText: 'top', // top - right - center - bottom - left
    fieldsText: ["title"],
    endTextWidthStr: "",
    angle: 0,
    colorCodeId: 'default',
    withFillColor: true,
    schema: {
        "type": 'object',
        "properties": {
            "title": {
                "title": "Text",
                "description": "Write a text",
                "type": "string",
            },
        },
        "required": []
    },
    pathData: `M173 52H280M230 60A5 5 0 10240 60 5 5 0 10230 60ZM250 60A5 5 0 10260 60 5 5 0 10250 60ZM270 60A5 5 0 10280 60 5 5 0 10270 60Z`,
}

export default GOM_TRAFFIC_ACCESS