import { TAG_CODES } from "../../tag.config"

const GOM_RESOURCE_COMMAND_POST = {
    type: 'path',
    tagCode: TAG_CODES.COMMAND,
    withText: true,
    defaultText: 'Command post',
    positionText: 'top', // top - right - center - bottom - left
    fieldsText: ["title"],
    endTextWidthStr: "",
    angle: 0,
    colorCodeId: 'command',
    withFillColor: false,
    schema: {
        "type": 'object',
        "properties": {
            "title": {
                "title": "Text",
                "description": "Write a text",
                "type": "string",
            },
        },
        "required": []
    },
    pathData: `M2.3 5 99.96 5 99.96 60 2.3 60 2.3 5M2.3 60 2.3 110`,
}

export default GOM_RESOURCE_COMMAND_POST