import { BsAirplane, BsArrowUpRight, BsAsterisk, BsCompass, BsFlag, BsGear, BsPentagon, BsSignpost2, BsStarFill, BsTruck, BsWater } from 'react-icons/bs'
import { COLOR_CODES } from './color.config'
import { BORDER_CODES } from './border.config'

export const TAG_CODES = {
    ACTION: {   // Acciones
        id: 'action',
        icon: <BsArrowUpRight />,
        activeControl: true,
        actionControl: false,
        colorControl: true,
        paintControl: false,
        defaultColorCode: COLOR_CODES.FIRE,
        defaultBorderCode: BORDER_CODES.EXPECTED,
        defaultIsActive: false,
    },
    INCIDENT: { // Siniestros
        id: 'incident',
        icon: <BsStarFill />,
        activeControl: true,
        actionControl: false,
        colorControl: true,
        paintControl: true,
        defaultColorCode: COLOR_CODES.FIRE,
        defaultBorderCode: BORDER_CODES.ACTIVE,
        defaultIsActive: true,
    },
    LAND_ASSET: {  // Medios terrestres
        id: 'landAsset',
        icon: <BsTruck />,
        activeControl: true,
        actionControl: true,
        colorControl: true,
        paintControl: false,
        defaultColorCode: COLOR_CODES.FIRE,
        defaultBorderCode: BORDER_CODES.EXPECTED,
        defaultIsActive: false,
    },
    AIR_ASSET: {  // Medios aéreos
        id: 'airAsset',
        icon: <BsAirplane />,
        activeControl: true,
        actionControl: true,
        colorControl: true,
        paintControl: false,
        defaultColorCode: COLOR_CODES.FIRE,
        defaultBorderCode: BORDER_CODES.EXPECTED,
        defaultIsActive: false,
    },
    WATER_ASSET: {  // Medios acuáticos
        id: 'waterAsset',
        icon: <BsWater />,
        activeControl: true,
        actionControl: true,
        colorControl: true,
        paintControl: false,
        defaultColorCode: COLOR_CODES.FIRE,
        defaultBorderCode: BORDER_CODES.EXPECTED,
        defaultIsActive: false,
    },
    RESOURCE: { // Recursos - infraestructuras y logística
        id: 'resource',
        icon: <BsGear />,
        activeControl: true,
        actionControl: false,
        colorControl: true,
        paintControl: true,
        defaultColorCode: COLOR_CODES.DEFAULT,
        defaultBorderCode: BORDER_CODES.ACTIVE,
        defaultIsActive: true,
    },
    ROAD: { // Caminos y carreteras
        id: 'road',
        icon: <BsSignpost2 />,
        activeControl: true,
        actionControl: false,
        colorControl: false,
        paintControl: false,
        defaultColorCode: COLOR_CODES.DEFAULT,
        defaultBorderCode: BORDER_CODES.ACTIVE,
        defaultIsActive: true,
    },
    DATA: { // Datos topográficos, astronómicos, meteorológicos
        id: 'data',
        icon: <BsCompass />,
        activeControl: true,
        actionControl: false,
        colorControl: false,
        paintControl: true,
        defaultColorCode: COLOR_CODES.DEFAULT,
        defaultBorderCode: BORDER_CODES.ACTIVE,
        defaultIsActive: true,
    },
    COMMAND: { // Puesto de mando 
        id: 'command',
        icon: <BsFlag />,
        activeControl: true,
        actionControl: false,
        colorControl: false,
        paintControl: false,
        defaultColorCode: COLOR_CODES.COMMAND,
        defaultBorderCode: BORDER_CODES.EXPECTED,
        defaultIsActive: false,
    },
    SECTOR: { // Sectorización 
        id: 'sector',
        icon: <BsPentagon />,
        activeControl: false,
        actionControl: false,
        colorControl: true,
        paintControl: false,
        defaultColorCode: COLOR_CODES.COMMAND,
        defaultBorderCode: BORDER_CODES.SECTOR,
        defaultIsActive: null,
    },
    DEFAULT: {
        id: 'default',
        icon: <BsAsterisk />,
        activeControl: true,
        actionControl: true,
        colorControl: true,
        paintControl: true,
        defaultColorCode: COLOR_CODES.DEFAULT,
        defaultBorderCode: BORDER_CODES.ACTIVE,
        defaultIsActive: true,
    },
}

export const getTagById = (id) => Object.values(TAG_CODES).find(tag => tag.id === id)

export const TAG_CODES_SORTED = Object.keys(TAG_CODES)
    .sort() // Ordena las claves alfabéticamente
    .reduce((acc, key) => {
        acc[key] = TAG_CODES[key] // Asigna el objeto original ordenado al nuevo objeto
        return acc
    }, {})
