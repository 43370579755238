import { TAG_CODES } from "../../tag.config"

const GOM_INCIDENT_CENTER = {
    type: 'path',
    tagCode: TAG_CODES.INCIDENT,
    withText: true,
    defaultText: 'Center',
    positionText: 'top', // top - right - center - bottom - left
    fieldsText: ["title"],
    endTextWidthStr: "",
    angle: 0,
    colorCodeId: 'fire',
    withFillColor: true,
    schema: {
        "type": 'object',
        "properties": {
            "title": {
                "title": "Text",
                "description": "Write a text",
                "type": "string",
            },
        },
        "required": []
    },
    pathData: `M 58.24 82.24 l 0 -2.88 l -24.576 0 l -24.64 0 l -0.96 -1.408 c -0.832 -1.216 -1.024 -3.84 -1.024 -15.68 l 0 -14.272 l -3.52 0 l -3.52 0 l 0 -2.88 l 0 -2.88 l 3.456 0 l 3.52 0 l 0.192 -13.312 c 0.128 -10.176 0.384 -13.632 1.088 -14.4 c 0.768 -0.96 3.392 -1.088 25.408 -1.088 l 24.576 0 l 0 -6.72 l 0 -6.72 l 2.88 0 l 2.88 0 l 0 6.72 l 0 6.72 l 23.616 0 c 21.12 0 23.68 0.128 24.448 1.088 c 0.704 0.768 0.96 4.224 1.088 14.4 l 0.192 13.312 l 8 0 l 7.936 0 l 0 2.88 l 0 2.88 l -8 0 l -8 0 l 0 14.272 c 0 11.84 -0.192 14.464 -1.024 15.68 l -0.96 1.408 l -23.68 0 l -23.616 0 l 0 2.88 l 0 2.88 l -2.88 0 l -2.88 0 l 0 -2.88 z m 0 -22.08 l 0 -12.16 l -22.08 0 l -22.08 0 l 0 12.16 l 0 12.16 l 22.08 0 l 22.08 0 l 0 -12.16 z m 48 0 l 0 -12.16 l -21.12 0 l -21.12 0 l 0 12.16 l 0 12.16 l 21.12 0 l 21.12 0 l 0 -12.16 z m -48 -28.8 l 0 -10.88 l -22.08 0 l -22.08 0 l 0 10.88 l 0 10.88 l 22.08 0 l 22.08 0 l 0 -10.88 z m 48 0 l 0 -10.88 l -21.12 0 l -21.12 0 l 0 10.88 l 0 10.88 l 21.12 0 l 21.12 0 l 0 -10.88 z`,
}

export default GOM_INCIDENT_CENTER