import { TAG_CODES } from "../../tag.config"

const GOM_RESOURCE_AIR = {
    type: 'path',
    tagCode: TAG_CODES.AIR_ASSET,
    withText: true,
    defaultText: 'Air asset',
    positionText: 'top', // top - right - center - bottom - left
    fieldsText: ["title"],
    endTextWidthStr: "",
    angle: 0,
    colorCodeId: 'water',
    withFillColor: true,
    schema: {
        "type": 'object',
        "properties": {
            "title": {
                "title": "Text",
                "description": "Write a text",
                "type": "string",
            },
        },
        "required": []
    },
    pathData: `M2.4 3 H117.6 V55.8 H2.4 Z M6 12 L6 48 L60 30 Z M114 12 L60 30 L114 48 Z`,   
}

export default GOM_RESOURCE_AIR