import { TAG_CODES } from "../../tag.config"

const GOM_RESOURCE_TRANSIT_POINT = {
    type: 'path',
    tagCode: TAG_CODES.COMMAND,
    withText: true,
    defaultText: 'Transit point',
    positionText: 'top', // top - right - center - bottom - left
    fieldsText: ["title"],
    endTextWidthStr: "",
    angle: 0,
    colorCodeId: 'command',
    withFillColor: false,
    schema: {
        "type": 'object',
        "properties": {
            "title": {
                "title": "Text",
                "description": "Write a text",
                "type": "string",
            },
        },
        "required": []
    },
    pathData: `M2 27.5 50 27.5M101 27.5 140 27.5M140 22.5 140 32.5 147 27.5 140 22.5M50 27.5A25 25 90 11101 27.5 25 25 90 1150 27.5`,
}

export default GOM_RESOURCE_TRANSIT_POINT