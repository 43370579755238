import { fabric } from 'fabric'
import DrawLineFabric from './drawLineFabric'

class DrawLineControls {
    constructor(canvas) {
        if (!(canvas instanceof fabric.Canvas)) {
            throw new Error('Canvas debe ser una instancia de fabric.Canvas')
        }        
        this.canvas = canvas
        this.isDrawingActive = false
        this.DrawLineFabric = new DrawLineFabric(this.canvas)
    }

    // Inicia la acción de dibujo de línea
    startDrawLine = (callBack) => {
        if (this.isDrawingActive) {
            console.warn('Drawing is already in progress.')
            return
        }

        // Inicializa el dibujo de la línea
        this.addEventListeners(callBack)

        // Configura el cursor y el estado de dibujo
        this.setCursor('crosshair')
        this.setIsDrawing(true)
    }

    // Finaliza la acción de dibujo de línea
    endDrawLine = (callBack) => {
        if (!this.isDrawingActive) {
            console.warn('No active drawing to end.')
            return
        }

        // Finaliza el dibujo y limpia los eventos
        const lineGroup = this.DrawLineFabric.drawLineEnd()
        if (lineGroup) {
            // Lógica para agregar la línea al canvas si es necesario
            // this.canvas.add(lineGroup)
            // this.canvas.sendToBack(lineGroup)
        }

        this.cleanupDrawing()

        // Llama al callback si se pasa uno
        if (typeof callBack === 'function') {
            callBack(this.isDrawingActive)
        }
    }

    // Configura los eventos necesarios
    addEventListeners = (callBack) => {
        this.canvas.on('mouse:down', this.DrawLineFabric.drawLineStart)
        this.canvas.on('mouse:dblclick', () => this.endDrawLine(callBack))
    }

    // Limpia el estado de dibujo y los eventos asociados
    cleanupDrawing = () => {
        this.canvas.off('mouse:down')
        this.canvas.off('mouse:dblclick')
        this.setCursor('default')
        this.setIsDrawing(false)
        this.renderCanvas()
        //this.DrawLineFabric = null
    }

    // Renderiza el canvas
    renderCanvas = () => {
        this.canvas.renderAll()
    }

    // Cambia el cursor
    setCursor = (cursor = 'default') => {
        this.canvas.defaultCursor = cursor
    }

    // Establece el estado de "está dibujando"
    setIsDrawing = (isActive) => {
        this.isDrawingActive = isActive
        this.canvas.selection = isActive
    }
}

export default DrawLineControls
