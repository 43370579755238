import { TAG_CODES } from "../../tag.config"

const DEFAULT_RECTANGLE = {
    type: 'rectangle',
    tagCode: TAG_CODES.DEFAULT,
    withText: true,
    defaultText: 'Rectangle',
    positionText: 'top', // top - right - center - bottom - left
    fieldsText: ["title"],
    endTextWidthStr: "",
    angle: 0,
    colorCodeId: 'default',
    withFillColor: false,
    schema: {
        "type": 'object',
        "properties": {
            "title": {
                "title": "Text",
                "description": "Write a text",
                "type": "string",
            },
        },
        "required": []
    },
}

export default DEFAULT_RECTANGLE