import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup, Popover, OverlayTrigger } from 'react-bootstrap'
import { BsTags } from 'react-icons/bs'
import { TAG_CODES_SORTED } from '../../config/tag.config'

const TagPicker = ({ tagCode, onChange, parentNode }) => {
    const tagsCode = Object.values(TAG_CODES_SORTED)

    const [show, setShow] = useState(false)
    const [selectedTagCode, setSelectedTagCode] = useState(null)

    useEffect(() => {
        setSelectedTagCode(tagCode)
    }, [tagCode])

    const handleOnChange = (tag) => {
        setSelectedTagCode(tag)
        onChange(tag)
        setShow(false)
    }

    const renderTagCodeOptions = () => (
        <ButtonGroup vertical className='p-0 m-0'>
            {tagsCode.map((tag, index) => (
                <Button
                    key={`tag-${index}`}
                    variant={selectedTagCode === tag ? 'primary' : 'light'}
                    onClick={() => handleOnChange(tag)}
                    className="w-100 text-start"
                >
                    {tag.icon} {tag.id}
                </Button>
            ))}
        </ButtonGroup>
    )

    const tagCodePickerPopover = (
        <Popover id="tag-picker-popover" className="p-0 m-0">
            <Popover.Header><BsTags /> Select a Tag</Popover.Header>
            <Popover.Body className="p-2 m-1">
                {renderTagCodeOptions()}
            </Popover.Body>
        </Popover>
    )

    return (
        <OverlayTrigger
            container={parentNode}
            trigger="click"
            placement="auto"
            overlay={tagCodePickerPopover}
            show={show}
            onToggle={(nextShow) => setShow(nextShow)}
        >
            <Button variant="primary" title={'Select an element type'}>
                {selectedTagCode ? (
                    <>
                        {selectedTagCode.icon}
                    </>
                ) : (
                    <BsTags />
                )}
            </Button>
        </OverlayTrigger>
    )
}

export default TagPicker
