import { fabric } from 'fabric'
import moment from 'moment'

class DateControls {
    constructor(canvas) {
        if (!(canvas instanceof fabric.Canvas)) {
            throw new Error('Canvas debe ser una instancia de fabric.Canvas')
        }
        this.canvas = canvas
        this.dateTimeStart = this.canvas.createdAt // Fecha de inicio
        this.dateTime = Date.now() // Fecha de la última actualización
    }

    /**
    * Actualiza la fecha y hora de la última modificación.
    */
    setLastUpdateDateTime = () => {
        this.dateTime = Date.now()
        this.canvas.set('lastUpdatedAt', this.dateTime)
    }

    /**
     * Establece la fecha y hora de inicio.
     * @param {Date} date - Fecha de inicio
     */
    setDateTimeStart = (date) => {
        this.dateTimeStart = date
        this.canvas.set('createdAt', this.dateTimeStart)
    }

    /**
    * Devuelve la fecha y hora de inicio.
    * 
    */
    getDateTimeStart = () => this.canvas.createdAt

    /**
     * Obtiene la fecha y hora de la última actualización
     */
    getLastUpdateDateTime = () => this.dateTime

    /**
     * Obtiene la fecha y hora de la última actualización en formato para nombres de archivo.
     * @returns {string} - Fecha y hora en formato "YYYYMMDDHHMMSS"
     */
    getLastUpdateDateTimeFileFormat = () => {
        return moment(this.dateTime).format('YYYYMMDDHHmmss')
    }
}

export default DateControls
