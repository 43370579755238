// dragControls.js
class DragControls {
    constructor() {
        this.isDragging = false
        this.lastPosX = 0
        this.lastPosY = 0
    }

    // Inicia el arrastre
    dragStart = (event) => {
        this.isDragging = true
        if (event.e.type === 'touchstart') {
            this.lastPosX = event.e.touches[0].clientX
            this.lastPosY = event.e.touches[0].clientY
        } else {
            this.lastPosX = event.e.clientX
            this.lastPosY = event.e.clientY
        }
    }

    // Mueve el objeto o canvas durante el arrastre
    dragMove = (event, relativePan) => {
        if (this.isDragging) {
            let deltaX, deltaY

            // Ajusta para eventos táctiles y calcula la diferencia de posición
            if (event.e.type === 'touchmove') {
                deltaX = event.e.touches[0].clientX - this.lastPosX
                deltaY = event.e.touches[0].clientY - this.lastPosY
            } else {
                deltaX = event.e.clientX - this.lastPosX
                deltaY = event.e.clientY - this.lastPosY
            }

            // Llama a la función relativePan que modifica la vista del canvas
            relativePan(deltaX, deltaY)

            // Actualiza la posición para el siguiente movimiento
            if (event.e.type === 'touchmove') {
                this.lastPosX = event.e.touches[0].clientX
                this.lastPosY = event.e.touches[0].clientY
            } else {
                this.lastPosX = event.e.clientX
                this.lastPosY = event.e.clientY
            }
        }
    }

    // Detiene el arrastre
    dragStop = () => {
        this.isDragging = false
    }
}

export default DragControls