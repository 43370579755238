// Import necessary dependencies
import React, { useEffect, useRef, useState } from 'react'
import { useIntl } from "react-intl"
import GomFabric from '../../fabric/gomFabric'
import HomeMenu from '../menu/HomeMenu'
import ActionsMenu from '../menu/ActionsMenu'
import InfoMenu from '../menu/InfoMenu'
import ToolsMenu from '../menu/ToolsMenu'
import ElementMenu from '../menu/ElementMenu'

import MapOverCanvas from '../map/MapOverCanvas'

const GomCanvas = ({
  width = '100%',
  height = '100%',
  onOpen,
  onChange,
  onClear,
  lastUpdated,
  locationName,
  lastCanvas,
  onTimeLine,
  selectedRecord,
}) => {
  const intl = useIntl()
  const [mapIndex, setMapIndex] = useState(null)
  const [elementSelected, setElementSelected] = useState(null)

  // Ref to hold the canvas DOM element
  const containerRef = useRef(null)
  const canvasRef = useRef(null)
  const gomCanvasRef = useRef(null)

  // Handle MapOverCanvas show controls
  const handleMapIndexChange = (index) => {
    setMapIndex(index)
  }

  // Handle Element selected
  const handleElementSelected = (element) => {
    setElementSelected(element)
  }

  useEffect(() => {
    // Initialize the GomFabric.js canvas
    const gomCanvas = new GomFabric(canvasRef, {
      locationName: locationName ? locationName : '',
      backgroundColor: '#f0f0f0',
      width: window.innerWidth,
      height: window.innerHeight,
      grid: {
        size: 25,
        color: '#e0e0e0',
        opacity: 0.8
      },
    })
    gomCanvasRef.current = gomCanvas

    // Resize canvas on window resize
    const handleResize = () => {
      if (gomCanvasRef.current) {
        gomCanvasRef.current?.resize({ width: window.innerWidth, height: window.innerHeight })
      }
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (!gomCanvasRef.current) return
    // Activamos un evento personalizado de modificación del canvas
    gomCanvasRef.current.addEvent('gomcanvas:modified', handleOnChange)

    // Establecemos la fecha de última actualización después de cada renderizado si el canvas ha cambiado
    gomCanvasRef.current.addEvent('after:render', async () => {
      if (await gomCanvasRef.current.isChanged()) {
        //gomCanvasRef.current.setLastUpdateDateTime()
        //setLocationName(gomCanvasRef.current.getLocationName()+'OOO')
      }
    })
    // Element event selection created
    gomCanvasRef.current.addEvent('selection:created', (event) => {
      var selectedObjects = event.selected

      if (selectedObjects.length > 1) {
        gomCanvasRef.current.discardActiveObject()
        // Luego, vuelve a activar el primer objeto seleccionado
        gomCanvasRef.current.setActiveObject(selectedObjects[0])
        //gomCanvasRef.current.renderAll()
      }
      handleElementSelected(selectedObjects[0])
    })

    // Element event selection update
    gomCanvasRef.current.addEvent('selection:updated', (event) => {
      var selectedObjects = event.selected

      if (selectedObjects.length > 1) {
        gomCanvasRef.current.discardActiveObject()
        // Luego, vuelve a activar el primer objeto seleccionado
        gomCanvasRef.current.setActiveObject(selectedObjects[0])
        //gomCanvasRef.current.renderAll()
      }
      handleElementSelected(selectedObjects[0])
    })

    // Element event selection cleared
    gomCanvasRef.current.addEvent('selection:cleared', () => {
      setElementSelected(null)
    })

    // Clean-up function: eliminar la instancia de Canvas al desmontar
    return () => {
      gomCanvasRef.current.dispose()
      gomCanvasRef.current.removeEvent('gomcanvas:modified')
      gomCanvasRef.current.removeEvent('after:render')
      gomCanvasRef.current.removeEvent('selection:created')
      gomCanvasRef.current.removeEvent('selection:updated')
      gomCanvasRef.current.removeEvent('selection:cleared')
    }
  }, [])

  // Cuando se cambia un objeto
  const handleOnChange = (event) => {
    onChange && onChange(gomCanvasRef.current, event)
  }

  const handleOnClear = (event) => {
    onClear && onClear(gomCanvasRef.current, event)
  }

  useEffect(() => {
    if (!gomCanvasRef.current) return
    if (lastCanvas) {
      gomCanvasRef.current.setFromJSON(lastCanvas)
    }
    onOpen(containerRef.current)
  }, [lastCanvas])

  return (
    <div
      ref={containerRef}
      style={{
        position: 'relative',
        width: `${width}px`,
        height: `${height}px`,
        border: '0px',
      }}
    >

      <canvas
        ref={canvasRef}
        style={{ display: 'block', margin: 0, padding: 0 }}
      />

      {/* Botones arriba izquierda LOGO/PROJECT/USER */}
      <HomeMenu containerRef={containerRef.current} canvasRef={gomCanvasRef.current} locationName={locationName} onClear={handleOnClear} />

      {/* Botones izquierda/medio CANVAS ACTIONS */}
      <ActionsMenu containerRef={containerRef.current} canvasRef={gomCanvasRef.current} onChangeMapIndex={handleMapIndexChange} onClear={handleOnClear} />

      {/* Botones arriba derecha Project INFO */}
      <InfoMenu
        containerRef={containerRef.current}
        canvasRef={gomCanvasRef.current}
        lastUpdated={lastUpdated}
        onTimeLine={onTimeLine}
        selectedRecord={selectedRecord}
      />

      {/* Botones abajo derecha CANVAS GRID/ZOOM/HELP/DATETIME */}
      <ToolsMenu containerRef={containerRef.current} canvasRef={gomCanvasRef.current} />

      {/* Menu del elemento seleccionado */}
      {elementSelected &&
        <ElementMenu
          containerRef={containerRef.current}
          element={elementSelected}
          canvasRef={gomCanvasRef.current}
        />
      }

      {/* Mapa para tomar capturas fondo canvas */}
      {mapIndex &&
        <MapOverCanvas canvasRef={gomCanvasRef.current} zIndex={mapIndex} onChangeMapIndex={handleMapIndexChange} />
      }
    </div>
  )
}

export default GomCanvas
