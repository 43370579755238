import { fabric } from 'fabric'
import { COLOR_CODES } from '../config/color.config'
import { BORDER_CODES } from '../config/border.config'
import { TAG_CODES } from '../config/tag.config'

import ZoomControls from './controls/zoomControls'
import GridControls from './controls/gridControls'
import DateControls from './controls/dateControls'
import FileControls from './controls/fileControls'
import BackgroundControls from './controls/backgroundControls'
import ScaleControls from './controls/scaleControls'
import DrawSectorControls from './controls/drawSectorControls'
import DrawLineControls from './controls/drawLineControls'
import DrawFreehandControls from './controls/drawFreehandControls'
import ElementControls from './controls/elementControls'

import GomElement from './shapes/GomElement'

class GomFabric {
    constructor(canvasRef,
        {
            locationName = 'Location or event name',
            backgroundColor = '#f0f0f0',
            width = 800,
            height = 600,
            zoom = 1,
            grid = {
                size: 0,
                color: '#e0e0e0',
                opacity: '0.8'
            },
        }) {
        if (!canvasRef || !canvasRef.current) {
            console.error('Invalid canvas reference!')
            return
        }

        this.canvas = new fabric.Canvas(canvasRef.current, {
            backgroundColor,
            width,
            height,
            locationName: locationName,
            createdAt: Date.now(),
            mapBounds: null,
        })

        // Color de fondo inicial
        this.backgroundColor = backgroundColor

        // Zoom inicialización
        this.zoom = zoom

        // Canvas Locked
        this.isLocked = false

        // Asegurarse de inicializar this.grid
        this.grid = {
            size: grid.size,
            color: grid.color,
            opacity: grid.opacity,
        }

        // Is drawwing 
        this.isDrawingActive = false
        this.lastPosX = null
        this.lastPosY = null

        // Is dragging
        this.isDragging = false

        /*
        *   Controls
        */
        this.dateManager = new DateControls(this.canvas)
        this.gridManager = new GridControls(this.canvas)
        this.gridManager.setGrid(this.grid)
        this.zoomManager = new ZoomControls(this.canvas, this.gridManager)
        this.scaleManager = new ScaleControls(this.canvas)
        this.backgroundManager = new BackgroundControls(this.canvas)
        this.fileManager = new FileControls(this.canvas, this.dateManager)
        this.drawSectorManager = new DrawSectorControls(this.canvas)
        this.drawLineManager = new DrawLineControls(this.canvas)
        this.drawFreehandManager = new DrawFreehandControls(this.canvas)
        this.objectManager = new ElementControls(this.canvas, this.setElementData)

        // Último estado del canvas para ver cambios
        this.canvasLastState = JSON.stringify(this.canvas)

        // último código de color y border
        this.lastTagCode = TAG_CODES.DEFAULT
        this.lastColorCode = COLOR_CODES.DEFAULT
        this.lastBorderCode = BORDER_CODES.EXPECTED
        this.lastFillActive = false
    }

    // Move canvas
    relativePan = (deltaX, deltaY) => this.canvas.relativePan(new fabric.Point(deltaX, deltaY))

    // Add events on
    addEvent = (eventName, eventFunction) => this.canvas.on(eventName, eventFunction)
    // Remove events off
    removeEvent = (eventName) => this.canvas.off(eventName)

    // Add element to canvas
    add = (element) => {
        this.canvas.add(element)
        this.requestRenderAll()
    }

    addTextbox = (text = 'Your text here', gomType) => {
        const textbox = new fabric.Textbox(text, {
            gomType: 'textbox',
            width: 100,
            fontSize: TAG_CODES.DEFAULT.defaultColorCode.fontSize,
            fontFamily: TAG_CODES.DEFAULT.defaultColorCode.fontFamily,
            left: this.canvas.width / 2,  // Centrar horizontalmente
            top: this.canvas.height / 2,  // Centrar verticalmente
            fill: TAG_CODES.DEFAULT.defaultColorCode.fill,
            originX: 'center',
            originY: 'center',
            editable: true,
            data: {
                id: crypto.randomUUID(),
                type: 'textbox',
                as: 'default',
                elementType: gomType,
                createdAt: Date.now(),
                lastUpdatedAt: Date.now(),
                fillActiveUpdatedAt: Date.now(),
                fillActive: true,
            }
        })
        this.canvas.add(textbox)
        this.canvas.requestRenderAll()
        this.setLastUpdateDateTime()
    }

    addElement = (elementType, element) => {
        const gomObj = new GomElement(this.canvas, elementType, element)
        gomObj.draw()
        this.requestRenderAll()
        this.setLastUpdateDateTime()
    }

    /*
    *   Canvas size controls
    */
    resize = ({ width = 800, height = 600 }) => {
        this.setWidth(width)
        this.setHeight(height)
        this.setGrid(this.grid)
        this.renderAll()
    }
    setWidth = (width) => this.canvas.setWidth(width)
    getWidth = () => this.canvas.getWidth()
    setHeight = (height) => this.canvas.setHeight(height)
    getHeight = () => this.canvas.getHeight()


    /*
    *   Canvas render
    */
    renderAll = () => this.canvas.renderAll()
    requestRenderAll = () => this.canvas.requestRenderAll()

    /*
    *   Canvas html element and element parentNode
    */
    getElement = () => this.canvas.getElement()
    getElementParentNode = () => this.canvas.getElement().parentNode

    /*
    *   Canvas dispose and element remove
    */
    dispose = () => this.canvas.dispose()
    remove = (element) => this.canvas.remove(element)

    setGrid = ({ size = 0, color = '#e0e0e0', opacity = '0.8' }) => this.gridManager.setGrid({ size, color, opacity })

    sendToBack = (element) => {
        this.canvas.sendToBack(element)
        if (this.gridManager.gridGroup) {
            this.canvas.moveTo(this.gridManager.gridGroup, 0)
        }
        this.renderAll()
    }
    sendObjectBack = (targetObject) => {
        const objects = this.canvas.getObjects()

        // Filtrar los objetos de tipo "grid" y "sector"
        const gridObjects = objects.filter(obj => obj.type === "grid")
        const sectorObjects = objects.filter(obj => obj.type === "sector")

        // Determinar la posición mínima permitida (justo delante del último sector)
        let minIndex = 0
        if (sectorObjects.length > 0) {
            minIndex = Math.max(...sectorObjects.map(obj => this.canvas.getObjects().indexOf(obj))) + 1
        } else if (gridObjects.length > 0) {
            minIndex = Math.max(...gridObjects.map(obj => this.canvas.getObjects().indexOf(obj))) + 1
        }

        // Mover el objeto a la posición adecuada
        this.canvas.moveTo(targetObject, minIndex)
        this.canvas.renderAll()
    }

    moveTo = (element, index) => {
        this.canvas.moveTo(element, index)
    }

    /*
    *   Zoom 
    */
    getZoom = () => this.zoomManager.getZoom()
    setZoom = (zoom) => this.zoomManager.setZoom(zoom)
    zoomToPoint = (point, zoom) => this.zoomManager.zoomToPoint(point, zoom)
    setZoomWheelActive = (callback = null) => this.zoomManager.setZoomWheelActive(callback)
    /******************************** */

    /*
    *   Canvas date time controls
    */
    setDateTimeStart = (date) => this.dateManager.setDateTimeStart(date)
    getDateTimeStart = () => this.dateManager.getDateTimeStart()
    setLastUpdateDateTime = () => {
        this.dateManager.setLastUpdateDateTime()
        this.canvas.fire('gomcanvas:modified', { action: "canvas:modified" })
    }
    getLastupdateDateTime = () => this.dateManager.getLastUpdateDateTime()
    getLastupdateDateTimeFileFormat = () => this.dateManager.getLastUpdateDateTimeFileFormat()
    /******************************** */

    /*
    *   Element set data property
    */
    setElementData = (element, newData, dateTime = Date.now()) => {
        const data = element.get("data")
        element.set({
            data: {
                ...data,
                ...newData,
                lastUpdatedAt: dateTime,
            }
        })
        this.setLastUpdateDateTime()
    }

    /*
    *   Canvas file controls
    */
    // Get canvas in PNG or JPG image format
    getCanvasImage = ({ format = 'png', quality = 1, multiplier = 3 }) => this.fileManager.getCanvasImage({
        format,
        quality,
        multiplier,
    })
    // Get canvas in JSON format
    getCanvasJSON = () => {
        const gridStatus = this.gridManager.getStatus()
        this.setGrid({ size: 0 })
        this.fileManager.getCanvasJSON()
        if (gridStatus) {
            this.setGrid(this.grid)
        }
    }
    setFromJSON = (jsonObject) => {
        const gridStatus = this.gridManager.getStatus()

        this.fileManager.setFromJSON(jsonObject, () => {
            // Este callback se ejecuta cuando se ha cargado completamente el JSON
            if (gridStatus) {
                this.moveTo(this.gridManager.gridGroup, 0)
            }
            this.setLastUpdateDateTime()
        })
    }

    toJSON = () => this.fileManager.toJSON()
    /******************************** */

    // Set gom canvas name
    setLocationName = (locationName) => {
        this.locationName = locationName
        this.canvas.set('locationName', this.locationName)
    }
    getLocationName = () => this.canvas.get('locationName')

     // Set gom canvas map bounds
    setMapBounds = (mapBounds) => {
        this.mapBounds = mapBounds
        this.canvas.set('mapBounds', this.mapBounds)
    }
    getMapBounds = () => this.canvas.get('mapBounds')

    // Set group text value
    setGroupTitleValue = ({ group, type, textValue }) => {
        const groupTextObj = group.getObjects().find(obj => obj.data?.type === type)
        if (groupTextObj) {
            groupTextObj.set("text", textValue)
            this.renderAll()
        }
    }

    // Set group stroke color and dash
    setGroupStrokeDash = ({ group, strokeCode, isActive }) => {
        group.getObjects().forEach((obj) => {
            if (obj.set && strokeCode && obj.type !== 'text' && obj.type !== 'textbox') {
                obj.set({
                    'strokeWidth': strokeCode.strokeWidth,
                    'strokeDashArray': strokeCode.strokeDashArray,
                })
            }
        })
        this.renderAll()
    }

    // Set group color value
    setGroupColor = ({ group, fillActive = false, color = null }) => {
        this.lastFillActive = fillActive
        group.getObjects().forEach((obj) => {
            if (obj.set && color && (typeof obj.data?.canChangeColor === "undefined" || obj.data?.canChangeColor !== false)) {
                if (obj.type === 'text' || obj.type === 'textbox') {
                    if (fillActive && group.data?.type !== 'drawFreeHand') {
                        //obj.set('fill', getContrastColor(color))
                        obj.set('fill', color)
                    } else {
                        obj.set('fill', color)
                    }
                } else {
                    obj.set('stroke', color)
                    if (fillActive) {
                        obj.set('fill', color)
                    } else {
                        obj.set('fill', 'transparent')
                    }
                }
            }
        })

        this.renderAll()
    }

    // Set lastTagCode
    setLastTagCode = (tagCode) => this.lastTagCode = tagCode
    // Set lastColorCode
    setLastColorCode = (colorCode) => this.lastColorCode = colorCode
    // Set lastBorderColor
    setLastBorderCode = (borderCode) => this.lastBorderCode = borderCode

    // Delete all objects
    deleteAll = () => {
        this.canvas.clear()
        this.backgroundManager.setBackgroundColor(this.backgroundColor)
        this.dateManager.setDateTimeStart(Date.now())
        this.dateManager.setLastUpdateDateTime()
        this.setLocationName('')
        const gridStatus = this.gridManager.getStatus()
        if (gridStatus) this.gridManager.setGrid(this.grid)
    }

    // Delete selected object sending to trash
    deleteSelected = () => {
        const object = this.getActiveObject()
        if (object) {
            object.set({ visible: false })  // No lo elimina del canvas, lo que hace es no mostrarlo
            const dateTime = Date.now()
            this.setElementData(object, {
                deletedAt: dateTime,
                deleted: true,
            }, dateTime)
            this.discardActiveObject()
            this.renderAll()
        }
    }

    /*
    *   Canvas Background controls
    */
    // Set background image
    setBackgroundImage = (dataUrl) => {
        this.backgroundManager.setBackgroundImage(dataUrl, {
            opacity: 0.5,
            originX: 'center',
            originY: 'center',
        }, () => {
            this.setLastUpdateDateTime()
        })
    }
    // Set background color
    setBackgroundColor = (color) => this.backgroundManager.setBackgroundColor(color)
    // Remove background image
    removeBackgroundImage = () => this.backgroundManager.removeBackgroundImage()
    /******************************** */

    getIsLocked = () => { return this.isLocked }

    toggleCanvasLock = (isLocked) => {
        this.isLocked = isLocked // Actualizar el estado interno, si necesario
        this.canvas.selection = !isLocked // True si desbloqueado, false si bloqueado
        this.canvas.forEachObject((obj) => {
            if (!obj.data?.grid) {  // Si no es el GRID bloqueamos o desbloqueamos
                obj.selectable = !isLocked
                obj.evented = !isLocked
            }
        })
        this.discardActiveObject()
        this.renderAll()
    }

    /*
    *   Get or set objects
    */
    getObjects = () => this.canvas.getObjects()
    getSectors = () => {
        const objects = this.getObjects()
        return objects.filter(item => item.data?.type === 'sectorGroup')
    }
    getSectorObjects = (sectorId) => {
        const objects = this.getObjects()
        return objects.filter(item => item.data?.sectorId === sectorId)
    }
    getObjectById = (objId) => {
        const objects = this.getObjects()
        return objects.filter(item => item.data?.id === objId)
    }
    getActiveObject = () => this.canvas.getActiveObject()
    getActiveObjects = () => this.canvas.getActiveObjects()
    setActiveObject = (obj) => this.canvas.setActiveObject(obj)
    discardActiveObject = () => this.canvas.discardActiveObject()

    /*
    *   Canvas scale controls
    */
    getScale = () => this.scaleManager.getScale()
    setScaleAll = (scale) => this.scaleManager.setScaleAll(scale)
    /******************************** */

    setCursor = (cursor = 'default') => this.canvas.defaultCursor = cursor

    getPointer = (e) => this.canvas.getPointer(e.e)

    isDrawing = () => {
        return this.isDrawingActive
    }
    setIsDrawing = (isDrawing) => {
        this.isDrawingActive = isDrawing
        this.canvas.selection = isDrawing
    }

    /*
    * Métodos para iniciar y finalizar la acción de dibujo  
    */
    // Sector
    startDrawSector = (onChange) => this.drawSectorManager.startDrawSector(onChange)
    endDrawSector = (onChange) => this.drawSectorManager.endDrawSector(onChange)
    // Líneas
    startDrawLine = (callBack) => this.drawLineManager.startDrawLine(callBack)
    endDrawLine = (callBack) => this.drawLineManager.endDrawLine(callBack)
    // Freehand
    startDrawFreehand = (callBack) => this.drawFreehandManager.startDrawFreehand(this.lastTagCode, callBack)
    endDrawFreehand = (callBack) => this.drawFreehandManager.endDrawFreehand(callBack)
    /******************************** */

    /*
    * Métodos para manejar los elementos
    */
    handleObjectMoving = (event) => this.objectManager.handleObjectMoving(event)
    handleObjectModified = (event) => this.objectManager.handleObjectModified(event)
    handleObjectSelected = (event) => this.objectManager.handleObjectSelected(event)
    /******************************** */

    // Devuelve si el canvas ha cambiado
    isChanged = async () => {
        const newState = JSON.stringify(this.canvas)
        if (this.canvasLastState !== newState) {
            this.canvasLastState = newState
            return true
        }
        return false
    }
}

export default GomFabric