import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import GomCanvasViewer from '../canvas/GomCanvasViewer'

function CanvasTimeLineModal({ container, locationName, selectedRecord, onClose, dates, fetchDates, fetchRecord }) {
    const [show, setShow] = useState(true)

    const handleClose = () => {
        setShow(false)
        onClose()
    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            fullscreen={true}
            className='p-0 m-0'
            container={container}
        >

            <Modal.Body className='p-0 m-0'>
                <GomCanvasViewer
                    locationName={locationName}
                    record={selectedRecord}
                    onClose={onClose}
                    timeLineDates={dates}
                    fetchDates={fetchDates}
                    fetchRecord={fetchRecord}
                />
            </Modal.Body>
        </Modal>
    )
}

export default CanvasTimeLineModal