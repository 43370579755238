import React, { useState, useEffect } from 'react'
import { useIntl } from "react-intl"
import { Button } from 'react-bootstrap'
import moment from 'moment'

const DateTimeControl = () => {
    const intl = useIntl()

    // Detecta idioma del navegador y cambia a ese idioma
    const userLanguage = navigator.language || navigator.languages[0]
    moment.locale(userLanguage.startsWith('es') ? 'es' : 'en')
    const dateFormat = userLanguage.startsWith('es') ? 'DD/MM/YYYY HH:mm:ss' : 'MM/DD/YYYY HH:mm:ss'

    const [dateTime, setDateTime] = useState(Date.now()) // Estado para la fecha y hora

    // Actualizamos la fecha y hora cada segundo
    useEffect(() => {
        const intervalId = setInterval(() => {
            setDateTime(Date.now())
        }, 1000)

        // Limpiar el intervalo cuando el componente se desmonte
        return () => clearInterval(intervalId)
    }, []) // Se ejecuta solo una vez, cuando el componente se monta

    return (
        <Button
            title={intl.formatMessage({ id: "controlsDateTime", defaultMessage: "Date time" })}
            variant="light"
            size='sm'
            className="d-inline-flex align-items-center"
            disabled
        >
            {moment(dateTime).format(dateFormat)}
        </Button>
    )
}

export default DateTimeControl